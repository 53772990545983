import React from "react";
import moment from "moment";

const VistaSemana = ({ events, currentDate, onAddEvent }) => {
  const startOfWeek = moment(currentDate).startOf("week");
  const days = Array.from({ length: 7 }, (_, i) =>
    startOfWeek.clone().add(i, "days")
  );
  const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  // Función para obtener colores gradientes
  const getGradientBackground = (resource) => {
    switch (resource) {
      case "cotizacion":
        return "bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600";
      case "evento":
        return "bg-gradient-to-r from-green-400 via-green-500 to-green-600";
      case "festivo":
        return "bg-gradient-to-r from-red-400 via-red-500 to-red-600";
      default:
        return "bg-gray-200";
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Encabezado ajustado */}
      <div className="flex items-center justify-between p-4 bg-white shadow-md">
        <h2 className="text-xl font-bold text-gray-800 ml-12">
          Semana del {startOfWeek.format("DD MMM")} al{" "}
          {startOfWeek.clone().add(6, "days").format("DD MMM YYYY")}
        </h2>
      </div>

      {/* Contenido semanal */}
      <div className="grid grid-cols-8 flex-1 border-t">
        {/* Columna de horas */}
        <div className="border-r bg-gray-100">
          {hours.map((hour) => (
            <div
              key={hour}
              className="h-16 flex items-center justify-center text-gray-500 text-sm border-b"
            >
              {hour}
            </div>
          ))}
        </div>

        {/* Días de la semana */}
        {days.map((day) => {
          const dayEvents = events.filter((event) =>
            moment(event.start).isSame(day, "day")
          );

          return (
            <div key={day} className="flex flex-col border-r">
              {/* Encabezado del día */}
              <div className="h-16 flex items-center justify-center bg-gray-100 text-gray-700 font-bold border-b">
                <span className="text-sm">{day.format("ddd")}</span>
                <span className="text-lg font-semibold ml-2">
                  {day.format("DD")}
                </span>
              </div>

              {/* Celdas de horas y eventos */}
              <div className="flex-1 relative">
                {hours.map((hour, hourIndex) => (
                  <div
                    key={`${day.format("DD-MM")}-${hour}`}
                    className="h-16 border-b relative flex items-start justify-center"
                  >
                    {dayEvents
                      .filter((event) =>
                        moment(event.start).hour() === hourIndex
                      )
                      .map((event) => (
                        <div
                          key={event.id}
                          className={`absolute rounded-lg shadow-md text-xs text-white ${
                            getGradientBackground(event.resource)
                          }`}
                          style={{
                            top: "50%", // Centra verticalmente dentro del contenedor
                            transform: "translateY(-50%)", // Ajusta para un centrado perfecto
                            padding: "8px 16px", // Aumenta el padding interno
                            fontSize: "14px", // Ajusta el tamaño de la fuente
                            minWidth: "100px", // Aumenta el tamaño mínimo
                            height: "60px", // Define un tamaño mayor para los eventos
                          }}
                        >
                          <p className="font-bold">{event.title}</p>
                          <p>{moment(event.start).format("h:mm A")}</p>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VistaSemana;
