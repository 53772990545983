import React, { useState, useEffect, useCallback } from "react";
import ProductTable from "./ProductTable";
import Sidebar from "./Sidebar";
import Nav from "./Nav";
import AddProductModal from "./AddProductModal";
import ProductButton from "./ProductButton"; // Asegúrate de importar ProductButton
import Spinner from "./Spinner";
import { db } from "../firebaseConfig";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

const ArticulosVenta = () => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const { searchTerm, proximasAVencer, proximosEventos } = useNavContext();

  const filterProducts = useCallback(
    (searchTerm) => {
      const filtered = products.filter(
        (product) =>
          product.numeroDeParteCliente
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.descripcion?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    },
    [products]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, "productos"));
        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener los productos:", error);
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    filterProducts("");
  }, [products, filterProducts]);

  const handleAddProduct = async (newProduct) => {
    try {
      const { id, ...productData } = newProduct; // Omitir 'id'
      const docRef = await addDoc(collection(db, "productos"), productData);
      setProducts((prevProducts) => [
        ...prevProducts,
        { id: docRef.id, ...productData },
      ]);
      setIsModalOpen(false);
      setEditingProduct(null);
    } catch (error) {
      console.error("Error al agregar el producto:", error);
      Swal.fire(
        "¡Error!",
        `Ha ocurrido un error al agregar el producto: ${error.message}`,
        "error"
      );
    }
  };

  const handleEditProduct = async (updatedProduct) => {
    try {
      const { id, ...data } = updatedProduct; // Desestructura el id
      await updateDoc(doc(db, "productos", id), data); // Usa el id para actualizar
      const updatedProducts = products.map((product) =>
        product.id === id ? updatedProduct : product
      );
      setProducts(updatedProducts);
      setIsModalOpen(false);
      setEditingProduct(null);
    } catch (error) {
      console.error("Error al editar el producto:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al actualizar el producto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const handleDeleteProduct = async (productId) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás deshacer esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "productos", productId));
          const updatedProducts = products.filter(
            (product) => product.id !== productId
          );
          setProducts(updatedProducts);
          Swal.fire("¡Eliminado!", "El producto ha sido eliminado.", "success");
        } catch (error) {
          console.error("Error al eliminar el producto:", error);
          Swal.fire(
            "¡Error!",
            `Ha ocurrido un error al intentar eliminar el producto: ${error.message}`,
            "error"
          );
        }
      }
    });
  };

  const handleAdjustPrices = async (increase, percentage) => {
    const adjustFactor = parseFloat(
      (1 + (increase ? percentage : -percentage) / 100).toFixed(2)
    );
    const updatePromises = products.map((product) => {
      const newCost = parseFloat((product.costo * adjustFactor).toFixed(2));
      const productRef = doc(db, "productos", product.id);
      return updateDoc(productRef, { costo: newCost });
    });

    try {
      await Promise.all(updatePromises);
      Swal.fire({
        title: "¡Éxito!",
        text: "Precios actualizados correctamente en Firebase.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al actualizar los precios.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Nav
        handleSearch={searchTerm}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar artículos de venta"
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <Link href="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </Link>
          </div>
          <div className="view-buttons-container">
            <button className="view-button grid-view">
              <i className="bi bi-grid"></i>
            </button>
            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>
          <div className="button-container">
            {showNuevoButton && (
              <button
                className="new-task-button"
                onClick={() => {
                  setIsModalOpen(true);
                  setEditingProduct(null);
                }}
              >
                + Agregar productos
              </button>
            )}

            <ProductButton
              showNuevoButton={showNuevoButton}
              onClick={handleAdjustPrices}
              setShowNuevoButton={setShowNuevoButton}
            />
          </div>
        </div>
      </div>

      <Sidebar />
      {isLoading ? (
        <Spinner />
      ) : filteredProducts.length === 0 ? (
        <p>No hay productos</p>
      ) : (
        <>
          <ProductTable
            products={filteredProducts}
            onEditProduct={(productId) => {
              const productToEdit = products.find(
                (product) => product.id === productId
              );
              setEditingProduct(productToEdit);
              setIsModalOpen(true);
            }}
            onDeleteProduct={handleDeleteProduct}
          />

          <AddProductModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={editingProduct ? handleEditProduct : handleAddProduct}
            editingProduct={editingProduct}
          />
        </>
      )}
    </>
  );
};

export default ArticulosVenta;