import React, { useState } from "react";
import moment from "moment";

const getGradientBackground = (type) => {
  if (type === "cotizacion") {
    return "linear-gradient(to right, #56ccf2, #2f80ed)";
  }
  if (type === "evento") {
    return "linear-gradient(to right, #6fcf97, #27ae60)";
  }
  if (type === "festivo") {
    return "linear-gradient(to right, #eb5757, #ff0000)";
  }
  return "transparent";
};

const VistaAño = ({ events, currentDate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");

  const months = Array.from({ length: 12 }, (_, i) =>
    currentDate.clone().startOf("year").month(i)
  );

  const daysOfWeek = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];

  const handlePointClick = (day, dayEvents) => {
    setSelectedDay(day.format("DD MMMM YYYY"));
    setSelectedDayEvents(dayEvents);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedDayEvents([]);
    setSelectedDay("");
  };

  return (
    <div className="p-6">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          {currentDate.format("YYYY")}
        </h2>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {months.map((month) => {
          const daysInMonth = Array.from(
            { length: month.daysInMonth() },
            (_, day) => day + 1
          );

          const monthEvents = events.filter((event) =>
            moment(event.start).isSame(month, "month")
          );

          return (
            <div
              key={month.format("MM")}
              className="border rounded-lg bg-white shadow-md"
            >
              <div className="p-4 text-center border-b">
                <h3 className="text-lg font-semibold text-gray-800">
                  {month.format("MMMM")}
                </h3>
              </div>

              <div className="grid grid-cols-7 text-center text-xs font-medium text-gray-500 p-2">
                {daysOfWeek.map((day, index) => (
                  <div key={index}>{day}</div>
                ))}
              </div>

              <div className="grid grid-cols-7 text-center text-sm p-2 gap-1">
                {Array.from(
                  { length: (month.startOf("month").day() + 6) % 7 }
                ).map((_, i) => (
                  <div key={`empty-${i}`} />
                ))}

                {daysInMonth.map((day) => {
                  const dayDate = month.clone().date(day);
                  const isToday = moment().isSame(dayDate, "day");

                  const dayEvents = monthEvents.filter((event) =>
                    moment(event.start).isSame(dayDate, "day")
                  );

                  const cotizaciones = dayEvents.filter(
                    (event) => event.resource === "cotizacion"
                  );
                  const eventos = dayEvents.filter(
                    (event) => event.resource === "evento"
                  );
                  const festivos = dayEvents.filter(
                    (event) => event.resource === "festivo"
                  );

                  return (
                    <div
                      key={day}
                      className={`p-1 rounded-lg relative cursor-pointer ${
                        isToday
                          ? "bg-blue-500 text-white font-bold"
                          : "hover:bg-gray-100 text-gray-700"
                      }`}
                    >
                      {day}
                      <div className="flex justify-center mt-1 gap-1">
                        {cotizaciones.length > 0 && (
                          <span
                            className="w-2 h-2 rounded-full bg-blue-500"
                            onClick={() =>
                              handlePointClick(dayDate, cotizaciones)
                            }
                          ></span>
                        )}
                        {eventos.length > 0 && (
                          <span
                            className="w-2 h-2 rounded-full bg-green-500"
                            onClick={() => handlePointClick(dayDate, eventos)}
                          ></span>
                        )}
                        {festivos.length > 0 && (
                          <span
                            className="w-2 h-2 rounded-full bg-red-500"
                            onClick={() => handlePointClick(dayDate, festivos)}
                          ></span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-bold mb-4 text-center text-gray-900">
              {selectedDay}
            </h3>
            <ul className="space-y-2">
              {selectedDayEvents.map((event, index) => (
                <li
                  key={index}
                  className="block rounded px-4 py-2 text-white text-sm font-medium"
                  style={{
                    background: getGradientBackground(event.resource),
                  }}
                >
                  <i
                    className={`bi ${
                      event.resource === "cotizacion"
                        ? "bi-file-earmark-text"
                        : event.resource === "evento"
                        ? "bi-calendar-event"
                        : "bi-star"
                    } mr-2`}
                  ></i>
                  {event.title}
                </li>
              ))}
            </ul>
            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 w-full"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VistaAño;
