import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  doc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import PedidoForm from "./PedidoForm";
import TablaPedidos from "./TablaPedidos";
import PreviaPedidos from "./PreviaPedidos";

function NuevoPedido({ mostrarBotonNuevo }) {
  const [ordenes, setOrdenes] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [numeroOrden, setNumeroOrden] = useState(null);

  useEffect(() => {
    obtenerClientes();
    obtenerUltimoNumeroOrden();

    // Escuchar los cambios en tiempo real de la colección "ordenes"
    const firestore = getFirestore();
    const ordenesRef = collection(firestore, "pedidos");
    const unsubscribe = onSnapshot(ordenesRef, (snapshot) => {
      const updatedOrdenes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrdenes(updatedOrdenes);
    });

    return () => unsubscribe();
  }, []);

  const activarFormulario = () => {
    setMostrarFormulario(true);
  };

  const obtenerUltimoNumeroOrden = async () => {
    try {
      const ordenesRef = collection(db, "pedidos");
      const q = query(ordenesRef, orderBy("numeroOrden", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      let ultimoNumero = 1;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const ultimaOrden = doc.data();
          ultimoNumero = ultimaOrden.numeroOrden + 1;
        });
      }
      setNumeroOrden(ultimoNumero);
    } catch (error) {
      console.error("Error al obtener el último número de orden:", error);
    }
  };

  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, "clientes"));
      const listaClientes = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(listaClientes);
    } catch (error) {
      console.error("Error al obtener los proveedores:", error);
    }
  };

  const guardarOrden = async (ordenData, modoEdicion, ordenId) => {
    try {
      const ordenesRef = collection(db, "pedidos");

      if (modoEdicion && ordenId) {
        // Actualizar orden existente
        const docRef = doc(db, "pedidos", ordenId);
        await updateDoc(docRef, ordenData);
      } else {
        // Crear nueva orden
        await addDoc(ordenesRef, ordenData);
      }

      // No es necesario llamar a obtenerOrdenes() ya que onSnapshot actualizará automáticamente la lista
    } catch (error) {
      console.error("Error al guardar la orden:", error);
      throw error;
    }
  };

  const verPrevia = (orden) => {
    setOrdenSeleccionada(orden);
    setMostrarPrevia(true);
  };

  return (
    <div>
      {!mostrarFormulario && mostrarBotonNuevo && (
        <div>
          <button className="action-button2" onClick={activarFormulario}>
            + Nuevo
          </button>
        </div>
      )}

      {mostrarFormulario ? (
        <PedidoForm
          guardarOrden={guardarOrden}
          clientes={clientes}
          ordenes={ordenes}
          setOrdenes={setOrdenes}
          orden={ordenSeleccionada}
        />
      ) : mostrarPrevia ? (
        <PreviaPedidos
          orden={ordenSeleccionada}
          cerrarPrevia={() => setMostrarPrevia(false)}
          clientes={clientes}
          guardarOrden={guardarOrden}
          numeroOrden={ordenSeleccionada?.numeroOrden}
        />
      ) : (
        <TablaPedidos
          ordenes={ordenes}
          verPrevia={verPrevia}
          setOrdenes={setOrdenes}
          clientes={clientes}
          setClientes={setClientes}
          guardarOrden={guardarOrden}
          numeroOrden={numeroOrden}
        />
      )}
    </div>
  );
}

export default NuevoPedido;