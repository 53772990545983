import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getFirestore,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import Spinner from "./Spinner";
import { useNavContext } from "../context/NavContext";
import Swal from "sweetalert2";
import Nav from "./Nav";
import Paginador from "./Paginador";
import ProductDetailsModal from "./ProductDetailsModal";
import EditProductModal from "./EditProductModal";

const ProductosRelacionados = () => {
  const { categoriaNombre } = useParams(); // Usaremos el nombre de la categoría en lugar del ID
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { searchTerm, proximasAVencer, proximosEventos } = useNavContext();
  const [selectedProductos, setSelectedProductos] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Valor inicial
  const [currentPage, setCurrentPage] = useState(1);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditProduct = (producto) => {
    setProductoSeleccionado(producto);
    setIsEditModalOpen(true);
  };

  const handleMostrarProducto = (producto) => {
    setProductoSeleccionado(producto);
    setIsDetailsModalOpen(true);
  };

  useEffect(() => {
    const fetchProductos = async () => {
      const firestore = getFirestore();
      const productosRef = collection(firestore, "productos");

      // Filtrar los productos por la categoría relacionada
      const q = query(productosRef, where("categoria", "==", categoriaNombre));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedProductos = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            nombre: data.nombre || data.product?.nombre || "Sin nombre",
            numeroDeParteCliente:
              data.numeroDeParteCliente ||
              data.product?.numeroDeParteCliente ||
              "N/A",
            costo: data.costo || data.product?.costo || "N/A",
            ...data,
          };
        });
        setProductos(fetchedProductos);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchProductos();
  }, [categoriaNombre]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Seleccionar todos los productos visibles en la página actual
      const allProductosIds = filteredProductos.map((producto) => producto.id);
      setSelectedProductos(allProductosIds);
    } else {
      // Deseleccionar todos los productos
      setSelectedProductos([]);
    }
  };

  const handleSelectProducto = (productoId) => {
    setSelectedProductos((prevSelected) => {
      if (prevSelected.includes(productoId)) {
        return prevSelected.filter((id) => id !== productoId); // Deseleccionar
      } else {
        return [...prevSelected, productoId]; // Seleccionar
      }
    });
  };

  const filteredProductos = React.useMemo(() => {
    if (!productos || productos.length === 0) return [];
    const searchLower = searchTerm.toLowerCase();
    return productos.filter((producto) => {
      const searchableFields = [
        producto.nombre?.toLowerCase() || "",
        producto.descripcion?.toLowerCase() || "",
      ];
      return searchableFields.some((field) => field.includes(searchLower));
    });
  }, [productos, searchTerm]);

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará los productos seleccionados de la categoría.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const productosEliminados = [];
        const productosRef = collection(firestore, "productos");

        for (const productoId of selectedProductos) {
          // Obtener el documento del producto
          const productoDocRef = doc(productosRef, productoId);
          const productoDocSnap = await getDoc(productoDocRef);

          if (productoDocSnap.exists()) {
            // Actualizar la categoría del producto a null (o eliminar la categoría actual)
            await updateDoc(productoDocRef, { categoria: null });
            productosEliminados.push(productoId);
          }
        }

        // Filtrar los productos restantes
        const productosRestantes = productos.filter(
          (producto) => !productosEliminados.includes(producto.id)
        );
        setProductos(productosRestantes);
        setSelectedProductos([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "Productos eliminados!",
          text: "Los productos seleccionados han sido eliminados correctamente de la categoría.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar productos:", error);
      Swal.fire({
        title: "Error",
        text: "Hubo un problema al eliminar los productos. Intenta nuevamente.",
        icon: "error",
        confirmButtonColor: "#d33",
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        handleSearch={searchTerm}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar..."
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <button href="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </button>
          </div>
          <div className="view-buttons-container">
            <button className="view-button grid-view">
              <i className="bi bi-grid"></i>
            </button>
            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>

          <div style={{ marginLeft: "5px" }}>
            {selectedProductos.length > 0 && (
              <div className="delete-button-container">
                <button
                  type="button"
                  className="delete-button"
                  onClick={handleDeleteSelected}
                >
                  Eliminar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="cotizaciones-table">
        <div className="tabla-cotizaciones-wrapper">
          <div className="tabla-cotizaciones-content">
            {loading ? (
              // Mostrar spinner mientras los datos se cargan
              <Spinner />
            ) : productos.length === 0 ? (
              // Mostrar mensaje si no hay productos
              <p
                style={{ textAlign: "center", margin: "20px 0", color: "#888" }}
              >
                No hay productos relacionados aún.
              </p>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        className="checkbox-select-all check"
                      />
                    </th>
                    <th>Nombre</th>
                    <th>Número de Parte</th>
                    <th>Costo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {productos.map((producto) => (
                    <tr key={producto.id}>
                      <td className="check">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={selectedProductos.includes(producto.id)}
                          onChange={() => handleSelectProducto(producto.id)}
                        />
                      </td>
                      <td>{producto.nombre}</td>
                      <td>{producto.numeroDeParteCliente}</td>
                      <td>
                        {typeof producto.costo === "number"
                          ? `${producto.costo.toFixed(2)}`
                          : typeof producto.costo === "string" &&
                            !isNaN(Number(producto.costo))
                          ? `${Number(producto.costo).toFixed(2)}`
                          : "N/A"}
                      </td>

                      <td className="acciones-container">
                        <div className="acciones-icons">
                          <div
                            className="icon-wrapper"
                            onClick={() => handleMostrarProducto(producto)}
                          >
                            <i className="bi bi-eye"></i>
                            <span className="tooltipCategorias">
                              Mostrar Producto
                            </span>
                          </div>
                          <div
                            className="icon-wrapper"
                            onClick={() => handleEditProduct(producto)}
                          >
                            <i className="bi bi-pencil"></i>
                            <span className="tooltipCategorias">Editar</span>
                          </div>
                          <div className="icon-wrapper">
                            <i className="bi bi-shield-slash"></i>
                            <span className="tooltipCategorias">
                              Desactivar
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {productos.length > 0 && (
            <div className="tabla-cotizaciones-footer">
              <div className="paginador-container">
                <Paginador
                  totalItems={productos.length}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <ProductDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        categoria={productoSeleccionado}
      />

      <EditProductModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        product={productoSeleccionado}
      />
    </>
  );
};

export default ProductosRelacionados;