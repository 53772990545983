import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  doc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import OrdenForm from "../components/OrdenForm";
import TablaOrdenes from "../components/TablaOrdenes";
import PreviaOrden from "../components/PreviaOrden";

function NuevaOrden({ mostrarBotonNuevo }) {
  const [ordenes, setOrdenes] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);
  const [proveedores, setProveedores] = useState([]);
  const [numeroOrden, setNumeroOrden] = useState(null);

  useEffect(() => {
    obtenerProvedores();
    obtenerUltimoNumeroOrden();

    // Escuchar los cambios en tiempo real de la colección "ordenes"
    const firestore = getFirestore();
    const ordenesRef = collection(firestore, "ordenes");
    const unsubscribe = onSnapshot(ordenesRef, (snapshot) => {
      const updatedOrdenes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrdenes(updatedOrdenes);
    });

    return () => unsubscribe();
  }, []);

  const activarFormulario = () => {
    setMostrarFormulario(true);
  };

  const obtenerUltimoNumeroOrden = async () => {
    try {
      const ordenesRef = collection(db, "ordenes");
      const q = query(ordenesRef, orderBy("numeroOrden", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      let ultimoNumero = 1;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const ultimaOrden = doc.data();
          ultimoNumero = ultimaOrden.numeroOrden + 1;
        });
      }
      setNumeroOrden(ultimoNumero);
    } catch (error) {
      console.error("Error al obtener el último número de orden:", error);
    }
  };

  const obtenerProvedores = async () => {
    try {
      const proveedoresSnapshot = await getDocs(collection(db, "proveedores"));
      const listaProveedores = proveedoresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProveedores(listaProveedores);
    } catch (error) {
      console.error("Error al obtener los proveedores:", error);
    }
  };

  const guardarOrden = async (ordenData, modoEdicion, ordenId) => {
    try {
      const ordenesRef = collection(db, "ordenes");

      if (modoEdicion && ordenId) {
        // Actualizar orden existente
        const docRef = doc(db, "ordenes", ordenId);
        await updateDoc(docRef, ordenData);
      } else {
        // Crear nueva orden
        await addDoc(ordenesRef, ordenData);
      }

      // No es necesario llamar a obtenerOrdenes() ya que onSnapshot actualizará automáticamente la lista
    } catch (error) {
      console.error("Error al guardar la orden:", error);
      throw error;
    }
  };

  const verPrevia = (orden) => {
    setOrdenSeleccionada(orden);
    setMostrarPrevia(true);
  };

  return (
    <div>
      {!mostrarFormulario &&
        mostrarBotonNuevo && (
          <div>
            <button className="action-button2" onClick={activarFormulario}>
              + Nuevo
            </button>
          </div>
        )}

      {mostrarFormulario ? (
        <OrdenForm
          guardarOrden={guardarOrden}
          proveedores={proveedores}
          ordenes={ordenes}
          setOrdenes={setOrdenes}
          numeroOrden={numeroOrden}
          orden={ordenSeleccionada}
        />
      ) : mostrarPrevia ? (
        <PreviaOrden
          orden={ordenSeleccionada}
          cerrarPrevia={() => setMostrarPrevia(false)}
          proveedores={proveedores}
          guardarOrden={guardarOrden}
          numeroOrden={ordenSeleccionada?.numeroOrden}
        />
      ) : (
        <TablaOrdenes
          ordenes={ordenes}
          verPrevia={verPrevia}
          setOrdenes={setOrdenes}
          proveedores={proveedores}
          guardarOrden={guardarOrden}
          numeroOrden={numeroOrden}
        />
      )}
    </div>
  );
}

export default NuevaOrden;