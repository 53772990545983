import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  getFirestore,
  doc,
  updateDoc,
  collection,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useAuth } from "./AuthContext";
import EditarPedidoForm from "./EditarPedidoForm";
import ContentLoader from "react-content-loader";

Modal.setAppElement("#root");
moment.locale("es");

const PedidosActivos = () => {
  const navigate = useNavigate();
  const [ordenesDeCompra, setOrdenesDeCompra] = useState([]);
  const [nuevoComentario, setNuevoComentario] = useState("");
  const [comentarios, setComentarios] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    orden: null,
  });
  const { user, avatarUrl, firstName, lastName } = useAuth();
  const [modalEditarOrdenIsOpen, setModalEditarOrdenIsOpen] = useState(false);
  const [ordenAEditar, setOrdenAEditar] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const clientesSnapshot = await getDocs(collection(db, "clientes"));
        const listaClientes = clientesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientes(listaClientes);
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const productosSnapshot = await getDocs(collection(db, "productos"));
        const listaProductos = productosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(listaProductos);
      } catch (error) {
        console.error("Error al obtener productos:", error);
      }
    };
    fetchProductos();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-orden-container")) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleMenuToggle = (ordenId) => {
    setActiveMenu((prev) => (prev === ordenId ? null : ordenId));
  };

  const handleEdit = (orden) => {
    setOrdenAEditar(orden);
    setModalEditarOrdenIsOpen(true);
  };

  const handleNuevoProyecto = () => {
    navigate("/pedidos", { state: { openModal: true } });
  };

  useEffect(() => {
    if (user && firstName && lastName) {
      setUsuario({
        id: user.uid,
        nombre: firstName,
        apellido: lastName,
        avatarUrl: avatarUrl,
      });
    } else {
      setUsuario(null);
    }
  }, [user, firstName, lastName, avatarUrl]);

  // Carga de órdenes en tiempo real
  useEffect(() => {
    const firestore = getFirestore();
    const ordenesRef = collection(firestore, "pedidos");

    const unsubscribe = onSnapshot(ordenesRef, (snapshot) => {
      const ordenes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrdenesDeCompra(ordenes);

      // Una vez que tenemos datos, quitamos la bandera de loading
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const closeModal = () => {
    setModalData({
      isOpen: false,
      orden: null,
    });
  };

  const agregarComentario = async () => {
    if (nuevoComentario.trim() && modalData.orden && usuario) {
      try {
        const nuevoComentarioObj = {
          texto: nuevoComentario,
          fecha: moment().format("ddd DD/MM/YY hh:mm a"),
          tipo: "sent",
          usuario: {
            id: usuario.id,
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            avatarUrl: usuario.avatarUrl || "/img/avatar2.jpg",
          },
        };

        const firestore = getFirestore();
        const ordenRef = doc(firestore, "pedidos", modalData.orden.id);
        const nuevosComentarios = [...comentarios, nuevoComentarioObj];

        await updateDoc(ordenRef, { comentarios: nuevosComentarios });

        // Actualizar el estado local
        setComentarios(nuevosComentarios);
        setOrdenesDeCompra((prev) =>
          prev.map((o) =>
            o.id === modalData.orden.id
              ? { ...o, comentarios: nuevosComentarios }
              : o
          )
        );
        setNuevoComentario("");
      } catch (error) {
        console.error("Error al agregar comentario:", error);
        Swal.fire({
          title: "Error",
          text: "No se pudo agregar el comentario. Intenta nuevamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else if (!usuario) {
      Swal.fire({
        title: "Usuario no encontrado",
        text: "No se ha podido identificar al usuario para comentar.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const calculateDaysLeft = (fechaFinRango) => {
    const today = moment().startOf("day");
    const endDate = moment(fechaFinRango);
    const difference = endDate.diff(today, "days");

    if (difference > 0) {
      return `Quedan ${difference} días`;
    } else if (difference === 0) {
      return "Vence hoy";
    } else {
      return "Vencido";
    }
  };

  const openModal = (orden) => {
    setComentarios(orden.comentarios || []);
    setModalData({
      isOpen: true,
      orden,
    });
  };

  return (
    <div style={{ paddingLeft: "70px" }}>
      <div className="proyectos-section">
        <h3 className="proyectos-title">Pedidos Activos</h3>
        <div className="proyectos-header">
          <div className="header-actions-proyectos">
            <button className="action-button-proyectos">
              <i className="bi bi-filter"></i> Filter
            </button>
            <button className="action-button-proyectos">
              <i className="bi bi-arrow-down-up"></i> Sort
            </button>
            <button className="action-button-proyectos">
              <i className="bi bi-eye-slash"></i> Hide
            </button>
            <button className="action-button-proyectos more-options">
              <i className="bi bi-three-dots"></i>
            </button>
          </div>
          <button onClick={handleNuevoProyecto} className="new-proyecto-button">
            + Nuevo Proyecto
          </button>
        </div>

        <div className="proyecto-list-container">
          <div className="proyecto-list">
            {/** 1) Si estamos cargando, mostramos 3 "ítems" de Skeleton en la misma estructura */}
            {isLoading && (
              <>
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="proyecto-item">
                    <div className="proyecto-details">
                      <SkeletonProyectoDetails />
                    </div>
                    <div className="proyecto-metrics">
                      <SkeletonProyectoMetrics />
                    </div>
                    <div className="avatar-group">
                      <SkeletonAvatarGroup />
                    </div>
                  </div>
                ))}
              </>
            )}

            {/** 2) Si ya no cargamos y no hay datos, mensaje vacío */}
            {!isLoading && ordenesDeCompra.length === 0 && (
              <p>No hay órdenes de compra activas.</p>
            )}

            {/** 3) Si hay órdenes, las renderizamos normalmente */}
            {!isLoading &&
              ordenesDeCompra.map((orden) => {
                const uniqueCommenters = (orden.comentarios || []).filter(
                  (comentario, idx, array) => {
                    const userId = comentario.usuario?.id;
                    if (!userId) return false;
                    return (
                      array.findIndex((c) => c.usuario?.id === userId) === idx
                    );
                  }
                );

                return (
                  <div key={orden.id} className="proyecto-item">
                    {/* Bloque de detalles */}
                    <div className="proyecto-details">
                      <h5 className="proyecto-title">{orden.referencia}</h5>
                      <p className="proyecto-cliente">{orden.nombreCliente}</p>
                      <p className="proyecto-subtitle">
                        {orden.numeroOrdenDeCompra}
                      </p>
                    </div>

                    {/* Bloque de métricas */}
                    <div className="proyecto-metrics">
                      <span
                        className={`status ${
                          orden.estado === "Pendiente"
                            ? "pendiente2"
                            : orden.estado === "En Proceso"
                            ? "en-proceso2"
                            : orden.estado === "Facturado"
                            ? "facturado2"
                            : orden.estado === "Pagada"
                            ? "pagada2"
                            : "default"
                        }`}
                      >
                        {orden.estado || "Sin estado"}
                      </span>
                      <span className="deadline-proyectos">
                        {calculateDaysLeft(orden.fechaFinRango)}
                      </span>
                      <span
                        className="metric-proyectos"
                        onClick={() => openModal(orden)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-chat"></i>{" "}
                        {orden.comentarios?.length || 0}
                      </span>

                      {/* Modal de comentarios */}
                      {modalData.isOpen && modalData.orden.id === orden.id && (
                        <div className="chat-modal-overlay">
                          <div className="chat-modal">
                            <div className="chat-modal-header">
                              <h3>{`Comentarios para "${modalData.orden.referencia}"`}</h3>
                              <button
                                className="close-button"
                                onClick={closeModal}
                              >
                                <i className="bi bi-x-lg"></i>
                              </button>
                            </div>
                            <div className="chat-modal-body">
                              {comentarios.length > 0 ? (
                                comentarios.map((comentario, idx) => {
                                  const esComentarioMio =
                                    comentario.usuario?.id === usuario?.id;
                                  return (
                                    <div
                                      key={idx}
                                      className={`chat-message ${
                                        esComentarioMio ? "sent" : "received"
                                      }`}
                                    >
                                      {comentario.usuario &&
                                        comentario.usuario.nombre && (
                                          <p className="nombre-usuario-comentario">
                                            {comentario.usuario.nombre}{" "}
                                            {comentario.usuario.apellido}
                                          </p>
                                        )}
                                      <p>{comentario.texto}</p>
                                      <span className="message-time">
                                        {comentario.fecha}
                                      </span>
                                    </div>
                                  );
                                })
                              ) : (
                                <p className="sin-comentarios">
                                  No hay comentarios todavía.
                                </p>
                              )}
                            </div>
                            <div className="chat-modal-footer">
                              <input
                                type="text"
                                placeholder="Escribe un comentario..."
                                value={nuevoComentario}
                                onChange={(e) =>
                                  setNuevoComentario(e.target.value)
                                }
                                className="comentario-input"
                              />
                              <button
                                onClick={agregarComentario}
                                className="agregar-comentario"
                              >
                                Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Bloque de avatares */}
                    <div className="avatar-group">
                      {uniqueCommenters.length === 0 ? (
                        <img
                          src="/img/avatar2.jpg"
                          alt="Avatar por defecto"
                          className="avatarOrden"
                        />
                      ) : (
                        uniqueCommenters.map((comentario, idx) => {
                          const avatar =
                            comentario.usuario?.avatarUrl || "/img/avatar2.jpg";
                          return (
                            <img
                              key={idx}
                              src={avatar}
                              alt={`Avatar de ${
                                comentario.usuario?.nombre || "Usuario"
                              }`}
                              className="avatarOrden"
                            />
                          );
                        })
                      )}
                    </div>

                    {/* Dropdown (3 puntos) */}
                    <div className="dropdown-orden-container">
                      <i
                        className="bi bi-three-dots proyecto-options"
                        onClick={() => handleMenuToggle(orden.id)}
                        style={{ cursor: "pointer" }}
                      ></i>
                      {activeMenu === orden.id && (
                        <div className="dropdown-orden-menu">
                          <button
                            onClick={() => handleEdit(orden)}
                            className="dropdown-orden-item"
                          >
                            <i
                              className="bi bi-pencil"
                              style={{ marginRight: "10px" }}
                            ></i>
                            Editar
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {modalEditarOrdenIsOpen && ordenAEditar && (
        <EditarPedidoForm
          orden={ordenAEditar}
          clientes={clientes}
          productos={productos}
          setOrdenes={setOrdenesDeCompra}
          ordenes={ordenesDeCompra}
          onClose={() => setModalEditarOrdenIsOpen(false)}
        />
      )}
    </div>
  );
};

/* SUBCOMPONENTES PARA LOS SKELETONS */

// 1) Bloque "proyecto-details"
const SkeletonProyectoDetails = (props) => (
  <ContentLoader
    speed={2}
    width={450}
    height={70}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Simula Título */}
    <rect x="10" y="10" rx="4" ry="4" width="60%" height="15" />
    {/* Subtítulo (cliente) */}
    <rect x="10" y="30" rx="4" ry="4" width="40%" height="12" />
    {/* Número de OC */}
    <rect x="10" y="50" rx="4" ry="4" width="30%" height="10" />
  </ContentLoader>
);

// 2) Bloque "proyecto-metrics"
const SkeletonProyectoMetrics = (props) => (
  <ContentLoader
    speed={2}
    width={450}
    height={50}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    {/* Estado (botón) */}
    <rect x="20" y="10" rx="4" ry="10" width="100" height="30" />

    {/* Quedan X días */}
    <rect x="200" y="15" rx="4" ry="4" width="100" height="12" />

    {/* Icono de chat */}
    <rect x="350" y="15" rx="4" ry="4" width="15" height="15" />
  </ContentLoader>
);

// 3) Bloque "avatar-group"
const SkeletonAvatarGroup = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={70}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="20" cy="30" r="15" />
  </ContentLoader>
);

export default PedidosActivos;