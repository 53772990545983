import React, { useState, useEffect } from "react";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
} from "firebase/firestore";
import Nav from "./Nav";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  label: {
    display: "block",
    width: "160px", // Fija el ancho de los labels
    textAlign: "right", // Alinea los labels a la derecha
    whiteSpace: "nowrap",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  select: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 101,
  },
};

const stepContainerStyle = {
  display: "flex",
  justifyContent: "left", // Para mantener los pasos más juntos
  marginLeft: "100px",
  gap: "50px", // Ajusta la separación entre los pasos
  marginBottom: "20px",
  cursor: "pointer",
};

const stepStyle = (isActive) => ({
  padding: "10px 15px", // Ajustamos un poco el padding
  borderBottom: isActive ? "4px solid #007bff" : "4px solid transparent",
  transition: "border-bottom 0.3s ease",
  fontWeight: isActive ? "bold" : "normal",
});

const ListaTodos = () => {
  const [clientes, setClientes] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [formData, setFormData] = useState({
    tipo: "Cliente", // Cambiado a 'tipo' en lugar de 'empresa'
    empresa: "",
    rfc: "",
    regimenFiscal: "",
    moneda: "",
    telefono: "",
    correo: "",
    imagenURL: null,
    domicilio: "",
    numeroExt: "",
    numeroInt: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [editingClientId, setEditingClientId] = useState(null);
  const [editingProveedorId, setEditingProveedorId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("tipo");
  const { searchTerm, proximasAVencer, proximosEventos } = useNavContext();
  const [showNuevoButton] = useState(true);

  const handleSort = (column) => {
    // Si ya estamos ordenando por la misma columna, sólo cambia asc <-> desc
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Si es otra columna, la seteamos y por defecto empezamos por "asc"
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const resetFormData = () => {
    setFormData({
      tipo: "Cliente",
      empresa: "",
      rfc: "",
      regimenFiscal: "",
      moneda: "",
      telefono: "",
      correo: "",
      imagenURL: null,
      domicilio: "",
      numeroExt: "",
      numeroInt: "",
      colonia: "",
      codigoPostal: "",
      ciudad: "",
      estado: "",
    });
  };

  const handleOpenModal = () => {
    resetFormData(); // Limpia los campos del formulario
    setEditingClientId(null); // Restablece el estado de edición del cliente
    setEditingProveedorId(null); // Restablece el estado de edición del proveedor
    setModalIsOpen(true); // Abre el modal
  };

  useEffect(() => {
    obtenerClientes();
    obtenerProveedores();
  }, []);

  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, "clientes"));
      const listaClientes = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        tipo: "Cliente",
        ...doc.data(),
      }));

      // Filtra clientes únicos por 'empresa' o cualquier otro identificador
      const clientesUnicos = listaClientes.filter(
        (cliente, index, self) =>
          index === self.findIndex((c) => c.empresa === cliente.empresa)
      );

      setClientes(clientesUnicos);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const obtenerProveedores = async () => {
    const proveedoresSnapshot = await getDocs(collection(db, "proveedores"));
    const listaProveedores = proveedoresSnapshot.docs.map((doc) => ({
      id: doc.id,
      tipo: "Proveedor",
      ...doc.data(),
    }));
    setProveedores(listaProveedores);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // Si el nombre del campo es "imagen" y hay archivos seleccionados
    if (name === "imagen" && files.length > 0) {
      // Procesar la imagen seleccionada
      const image = files[0]; // Obtener el primer archivo seleccionado
      // Aquí puedes realizar operaciones adicionales, como subir la imagen a Firebase Storage, etc.
      // Por ahora, solo actualizaremos el estado con la URL de la imagen seleccionada
      setFormData((prevFormData) => ({
        ...prevFormData,
        imagenURL: URL.createObjectURL(image), // Crear una URL local para previsualizar la imagen
      }));
    } else {
      // Si no es un campo de imagen o no hay archivos seleccionados, actualizar como antes
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const filteredContacts = React.useMemo(() => {
    const allContacts = [...clientes, ...proveedores];
    if (!allContacts || allContacts.length === 0) return [];

    const searchLower = searchQuery.toLowerCase();
    return allContacts.filter((contacto) => {
      const searchableFields = [
        contacto.tipo?.toString().toLowerCase() || "",
        contacto.empresa?.toString().toLowerCase() || "",
        contacto.rfc?.toString().toLowerCase() || "",
        contacto.telefono?.toString().toLowerCase() || "",
        contacto.correo?.toString().toLowerCase() || "",
      ];
      return searchableFields.some((field) => field.includes(searchLower));
    });
  }, [clientes, proveedores, searchQuery]);

  const sortedContacts = React.useMemo(() => {
    // Copiamos para no mutar filteredContacts original
    const dataCopy = [...filteredContacts];

    // Ordenamos dataCopy según sortBy y sortOrder
    return dataCopy.sort((a, b) => {
      // Convertimos a minúsculas si es string, o usamos tal cual si es number
      const fieldA =
        typeof a[sortBy] === "string"
          ? a[sortBy].toLowerCase()
          : a[sortBy] || "";
      const fieldB =
        typeof b[sortBy] === "string"
          ? b[sortBy].toLowerCase()
          : b[sortBy] || "";

      if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
      if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
      return 0; // Si son iguales
    });
  }, [filteredContacts, sortBy, sortOrder]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar campos obligatorios para el paso actual
    if (step === 1 || step === 2) {
      const requiredFields = ["empresa"]; // Agrega más campos si son obligatorios
      for (let field of requiredFields) {
        if (!formData[field] || formData[field].trim() === "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `El campo "${
              field === "empresa" ? "Nombre de la Empresa" : field
            }" es obligatorio!`,
          });
          return;
        }
      }
    }

    try {
      const data = {
        ...formData,
        telefono: formData.telefono || "",
        correo: formData.correo || "",
        rfc: formData.rfc || "",
        regimenFiscal: formData.regimenFiscal || "",
      };

      if (editingClientId) {
        // Actualizar un cliente existente
        await updateDoc(doc(db, "clientes", editingClientId), data);
        Swal.fire({
          icon: "success",
          title: "¡Cliente actualizado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else if (editingProveedorId) {
        // Actualizar un proveedor existente
        await updateDoc(doc(db, "proveedores", editingProveedorId), data);
        Swal.fire({
          icon: "success",
          title: "¡Proveedor actualizado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        // Agregar documento con el tipo seleccionado
        await addDoc(
          collection(
            db,
            formData.tipo === "Cliente" ? "clientes" : "proveedores"
          ),
          data
        );
        Swal.fire({
          icon: "success",
          title: `¡${formData.tipo} registrado con éxito!`,
          showConfirmButton: false,
          timer: 1000,
        });
      }

      // Limpiar el formulario y cerrar el modal
      resetFormData();
      setEditingClientId(null);
      setEditingProveedorId(null);
      setStep(1);
      setModalIsOpen(false);

      // Actualizar las listas de clientes y proveedores
      await obtenerClientes();
      await obtenerProveedores();
    } catch (error) {
      console.error("Error al guardar:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al guardar el registro. Intenta nuevamente.",
      });
    }
  };

  const handleEditClient = (id) => {
    const clientToEdit = clientes.find((cliente) => cliente.id === id);
    setFormData(clientToEdit);
    setEditingClientId(id);
    setEditingProveedorId(null);
    setStep(1);
    setModalIsOpen(true);
  };

  const handleEditProveedor = (id) => {
    const proveedorToEdit = proveedores.find(
      (proveedor) => proveedor.id === id
    );
    setFormData(proveedorToEdit);
    setEditingProveedorId(id);
    setEditingClientId(null);
    setStep(1);
    setModalIsOpen(true);
  };

  const handleDeleteClient = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "clientes", id));
          Swal.fire({
            icon: "success",
            title: "¡Cliente eliminado con éxito!",
            showConfirmButton: false,
            timer: 1000,
          });
          obtenerClientes();
        } catch (error) {
          console.error("Error al eliminar cliente:", error);
        }
      }
    });
  };

  const handleDeleteProveedor = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "proveedores", id));
          Swal.fire({
            icon: "success",
            title: "¡Proveedor eliminado con éxito!",
            showConfirmButton: false,
            timer: 1000,
          });
          obtenerProveedores();
        } catch (error) {
          console.error("Error al eliminar proveedor:", error);
        }
      }
    });
  };

  return (
    <>
      <Nav
        handleSearch={searchTerm}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar contactos"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <Link href="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </Link>
          </div>
          <div className="view-buttons-container">
            <button className="view-button grid-view">
              <i className="bi bi-grid"></i>
            </button>
            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>
          <div>
            {showNuevoButton && (
              <button className="new-task-button" onClick={handleOpenModal}>
                + Nuevo
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="cotizaciones-table">
        {isLoading ? ( // Mostrar mensaje de carga si isLoading es true
          <Spinner />
        ) : (
          <>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              style={customStyles}
            >
              <div>
                <button
                  className="cerrar-button"
                  onClick={() => setModalIsOpen(false)}
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>

              <Nav
                handleSearch={searchTerm}
                proximasAVencer={proximasAVencer}
                proximosEventos={proximosEventos}
              />

              <Sidebar />

              {step === 1 && (
                <>
                  <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                    {editingClientId
                      ? "Actualizar Cliente"
                      : editingProveedorId
                      ? "Actualizar Proveedor"
                      : "Agregar"}
                  </h2>
                  <div style={stepContainerStyle}>
                    <div
                      style={stepStyle(step === 1)}
                      onClick={() => setStep(1)}
                    >
                      Info
                    </div>
                    <div
                      style={stepStyle(step === 2)}
                      onClick={() => setStep(2)}
                    >
                      Dirección
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="client-form">
                    <div className="form-row">
                      <label style={customStyles.label}>Tipo:</label>
                      <select
                        style={customStyles.select}
                        name="tipo"
                        value={formData.tipo}
                        onChange={handleChange}
                      >
                        <option value="Cliente">Cliente</option>
                        <option value="Proveedor">Proveedor</option>
                      </select>
                      <label className="required" style={customStyles.label}>
                        Nombre de la Empresa*
                      </label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="empresa"
                        placeholder="Nombre de la Empresa"
                        value={formData.empresa}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>RFC</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="rfc"
                        placeholder="RFC"
                        value={formData.rfc}
                        onChange={handleChange}
                      />
                      <label style={customStyles.label}>Régimen Fiscal</label>
                      <select
                        style={customStyles.select}
                        id="regimen"
                        name="regimenFiscal"
                        value={formData.regimenFiscal}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Seleccione un régimen fiscal
                        </option>
                        <option value="601">
                          601 - General de Ley Personas Morales
                        </option>
                        <option value="612">
                          612 - Personas Físicas con Actividades Empresariales y
                          Profesionales
                        </option>
                        <option value="616">
                          616 - Sin obligaciones fiscales
                        </option>
                        <option value="626">
                          626 - Régimen Simplificado de Confianza (RESICO)
                        </option>
                      </select>
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>Moneda</label>
                      <select
                        style={customStyles.select}
                        id="moneda"
                        name="moneda"
                        value={formData.moneda}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Seleccione una moneda
                        </option>
                        <option value="MXN">MXN</option>
                        <option value="USD">USD</option>
                      </select>
                      <label style={customStyles.label}>Teléfono</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="telefono"
                        placeholder="Teléfono"
                        value={formData.telefono}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>Email</label>
                      <input
                        style={customStyles.input}
                        type="email"
                        name="correo"
                        placeholder="Correo Electrónico"
                        value={formData.correo}
                        onChange={handleChange}
                      />
                      <label style={customStyles.label}>Imagen</label>
                      <input
                        style={{
                          width: "100%",
                          display: "block",
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          boxSizing: "border-box",
                        }}
                        type="file"
                        name="imagen"
                        onChange={handleChange}
                      />
                    </div>
                  </form>
                </>
              )}
              {step === 2 && (
                <>
                  <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                    Agrega los últimos datos
                  </h2>
                  <div style={stepContainerStyle}>
                    <div
                      style={stepStyle(step === 1)}
                      onClick={() => setStep(1)}
                    >
                      Info
                    </div>
                    <div
                      style={stepStyle(step === 2)}
                      onClick={() => setStep(2)}
                    >
                      Dirección
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="client-form">
                    <div className="form-row">
                      <label style={customStyles.label}>Domicilio</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="domicilio"
                        placeholder="Domicilio"
                        value={formData.domicilio}
                        onChange={handleChange}
                      />
                      <label style={customStyles.label}>No. Exterior</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="numeroExt"
                        placeholder="No. Ext"
                        value={formData.numeroExt}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>No. Interior</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="numeroInt"
                        placeholder="No. Int"
                        value={formData.numeroInt}
                        onChange={handleChange}
                      />
                      <label style={customStyles.label}>Colonia</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="colonia"
                        placeholder="Colonia"
                        value={formData.colonia}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>Código Postal</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="codigoPostal"
                        placeholder="C.P."
                        value={formData.codigoPostal}
                        onChange={handleChange}
                      />
                      <label style={customStyles.label}>Ciudad</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="ciudad"
                        placeholder="Ciudad"
                        value={formData.ciudad}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-row">
                      <label style={customStyles.label}>Estado</label>
                      <input
                        style={customStyles.input}
                        type="text"
                        name="estado"
                        placeholder="Estado"
                        value={formData.estado}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-toolbar">
                      <div className="leftBtn">
                        <button
                          id="guardar-enviar"
                          className="btn btn-primary"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {editingClientId
                            ? "Actualizar Cliente"
                            : editingProveedorId
                            ? "Actualizar Proveedor"
                            : "Agregar"}
                        </button>
                        <button
                          id="cancelar"
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => setModalIsOpen(false)}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </Modal>
            {filteredContacts.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th onClick={() => handleSort("tipo")}>
                      Tipo {sortBy === "tipo" ? sortOrder === "asc" : null}
                    </th>
                    <th onClick={() => handleSort("empresa")}>
                      Nombre {sortBy === "empresa" ? sortOrder === "asc" : null}
                    </th>
                    <th onClick={() => handleSort("rfc")}>
                      RFC {sortBy === "rfc" ? sortOrder === "asc" : null}
                    </th>
                    <th onClick={() => handleSort("telefono")}>
                      Teléfono{" "}
                      {sortBy === "telefono" ? sortOrder === "asc" : null}
                    </th>
                    <th onClick={() => handleSort("correo")}>
                      Correo {sortBy === "correo" ? sortOrder === "asc" : null}
                    </th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedContacts.map((item) => (
                    <tr key={item.id}>
                      <td style={{ width: "10%" }}>{item.tipo}</td>
                      <td style={{ width: "25%" }}>{item.empresa}</td>
                      <td>{item.rfc}</td>
                      <td>{item.telefono}</td>
                      <td>{item.correo}</td>
                      <td style={{ width: "15%" }}>
                        {/* Botones Editar/Eliminar */}
                        <button
                          className="btnEditar"
                          onClick={() =>
                            item.tipo === "Cliente"
                              ? handleEditClient(item.id)
                              : handleEditProveedor(item.id)
                          }
                        >
                          Editar
                        </button>
                        <button
                          className="btnEliminar"
                          onClick={() =>
                            item.tipo === "Cliente"
                              ? handleDeleteClient(item.id)
                              : handleDeleteProveedor(item.id)
                          }
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="no-resultados">
                <div
                  className="mensaje-sin-resultados"
                  style={{ marginTop: "30px" }}
                >
                  <p style={{ fontSize: "14px" }}>
                    No existe ningún contacto que coincida con esta búsqueda.
                  </p>
                </div>
                <div className="imagen-sin-resultados">
                  <img
                    src="/img/noSearch.svg"
                    alt="Sin resultados"
                    className="sin-resultados-imagen"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListaTodos;