import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "30%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    fontFamily: "Montserrat, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 101,
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};

export default function ModalCreateEvent({ isOpen, onClose, onSave, initialData }) {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [hoverCancel, setHoverCancel] = useState(false);
  const [hoverSave, setHoverSave] = useState(false);

  useEffect(() => {
    if (isOpen && initialData) {
      setTitle(initialData.title || "");
      setDate(initialData.to || "");
    } else if (isOpen) {
      // Si no hay datos iniciales, resetear campos
      setTitle("");
      setDate("");
    }
  }, [isOpen, initialData]);

  const handleSubmit = () => {
    if (!title.trim() || !date.trim()) {
      Swal.fire({
        title: "Error",
        text: "Título y Fecha son obligatorios.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    onSave({ title, to: date });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={initialData ? "Editar Evento" : "Agregar Evento"}
    >
      <button onClick={onClose} style={customStyles.closeButton}>
        <i className="bi bi-x-lg"></i>
      </button>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        {initialData ? "Editar Evento" : "Agregar Evento"}
      </h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Título:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
            required
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>Fecha del evento:</label>
          <input
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
            }}
            required
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            onMouseEnter={() => setHoverSave(true)}
            onMouseLeave={() => setHoverSave(false)}
            style={{
              padding: "10px 20px",
              margin: "0 5px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: hoverSave ? "#0056b3" : "#007bff",
              color: "white",
              fontSize: "14px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          >
            {initialData ? "Guardar Cambios" : "Agregar"}
          </button>
          <button
            type="button"
            onClick={onClose}
            onMouseEnter={() => setHoverCancel(true)}
            onMouseLeave={() => setHoverCancel(false)}
            style={{
              padding: "10px 20px",
              margin: "0 5px",
              borderRadius: "5px",
              border: "none",
              backgroundColor: hoverCancel ? "#999" : "#808080",
              color: "white",
              fontSize: "14px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
          >
            Cancelar
          </button>
        </div>
      </form>
    </Modal>
  );
}
