import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px", // Ancho máximo incrementado
    width: "100%",
    height: "400px", // Altura ajustada
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif",
    padding: "30px", // Espaciado interno
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 102,
  },
};

const CategoriaModal = ({ isOpen, onClose, onSubmit, categoria }) => {
  const [newCategoria, setNewCategoria] = useState({
    id: null, // Incluimos el ID
    nombre: "",
    descripcion: "",
    imagen: null,
    imagenUrl: "",
  });

  useEffect(() => {
    if (categoria) {
      setNewCategoria({
        id: categoria.id || null, // Asignamos el ID si existe
        nombre: categoria.nombre || "",
        descripcion: categoria.descripcion || "",
        imagen: null,
        imagenUrl: categoria.imagenUrl || "",
      });
    } else {
      setNewCategoria({
        id: null,
        nombre: "",
        descripcion: "",
        imagen: null,
        imagenUrl: "",
      });
    }
  }, [categoria]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategoria((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewCategoria((prev) => ({ ...prev, imagen: file }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verifica si el campo "nombre" está vacío
    if (!newCategoria.nombre.trim()) {
      Swal.fire({
        title: "Campo obligatorio",
        text: "El campo 'Nombre' es obligatorio. Por favor, complétalo antes de guardar.",
        icon: "warning",
        confirmButtonText: "Entendido",
        confirmButtonColor: "#3085d6",
      });
      return; // Detén la ejecución si el nombre no está presente
    }

    // Envía los datos si el campo "nombre" no está vacío
    onSubmit(newCategoria);
    setNewCategoria({
      id: null,
      nombre: "",
      descripcion: "",
      imagen: null,
      imagenUrl: "",
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={categoria ? "Editar Categoría" : "Crear Nueva Categoría"} // Título dinámico
      style={customStyles}
    >
      <button
        className="modal-close"
        onClick={onClose}
        aria-label="Close Modal"
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <form onSubmit={handleSubmit} style={{ display: "flex", gap: "30px" }}>
        <div style={{ flex: 2 }}>
          <h2>{categoria ? "Editar Categoría" : "Crear Nueva Categoría"}</h2>{" "}
          {/* Título dinámico */}
          <div style={{ marginTop: "40px", marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "10px",
                fontWeight: "bold",
                fontSize: "18px", // Tamaño de fuente más grande
              }}
            >
              Nombre <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="nombre"
              value={newCategoria.nombre}
              onChange={handleInputChange}
              required
              style={{
                width: "100%",
                padding: "12px", // Espaciado interno mayor
                borderRadius: "8px",
                border: "1px solid #ccc",
                boxSizing: "border-box",
                fontSize: "16px",
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "10px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Descripción
            </label>
            <textarea
              name="descripcion"
              value={newCategoria.descripcion}
              onChange={handleInputChange}
              style={{
                width: "100%",
                height: "120px", // Altura del textarea aumentada
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                boxSizing: "border-box",
                resize: "none",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <label
            htmlFor="file-upload"
            style={{
              marginTop: "70px",
              display: "block",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              padding: "50px",
              cursor: "pointer",
              color: "#999",
              fontSize: "18px",
              height: "164px",
              width: "250px",
              overflow: "hidden",
            }}
          >
            {newCategoria.imagen ? (
              <img
                src={URL.createObjectURL(newCategoria.imagen)}
                alt="Vista previa"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            ) : newCategoria.imagenUrl ? (
              <img
                src={newCategoria.imagenUrl}
                alt="Vista previa"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            ) : (
              <>
                <i
                  className="bi bi-card-image"
                  style={{ fontSize: "64px", color: "#ccc" }}
                ></i>
                <p style={{ color: "#ccc" }}>Seleccionar imagen</p>
              </>
            )}
          </label>

          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            paddingBottom: "20px", // Más espacio superior para los botones
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
          }}
        >
          <button
            type="button"
            onClick={onClose}
            style={{
              padding: "12px 30px",
              borderRadius: "5px",
              backgroundColor: "#6c757d",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Cancelar
          </button>
          <button
            type="submit"
            style={{
              padding: "12px 30px",
              borderRadius: "5px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {categoria ? "Actualizar" : "Guardar"} {/* Texto estático */}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CategoriaModal;
