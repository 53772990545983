import React, { useEffect, useState } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Modal from "react-modal";
import ResumenCotizacion from "./ResumenCotizacion";
import ResumenOrden from "./ResumenOrden";
import ResumenRemision from "./ResumenRemision";

const ArchivosRecientes = () => {
  const [archivos, setArchivos] = useState([]);
  const [selectedArchivo, setSelectedArchivo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const colecciones = [
      {
        nombre: "cotizaciones",
        fechaCampo: "fechaCotizacion",
        tipo: "Cotización",
      },
      { nombre: "ordenes", fechaCampo: "fechaOrden", tipo: "Orden" },
      { nombre: "remisiones", fechaCampo: "fechaRemision", tipo: "Remisión" },
    ];
    const unsubscribes = [];

    const obtenerArchivos = () => {
      const archivosTotales = [];

      colecciones.forEach(({ nombre, fechaCampo, tipo }) => {
        const q = query(collection(db, nombre), orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const datos = snapshot.docs.map((doc) => ({
            id: doc.id,
            nombre:
              doc.data().nombreCliente ||
              doc.data().nombreProveedor ||
              "Desconocido",
            fecha: doc.data()[fechaCampo],
            estado: doc.data().estado || "Sin estado",
            total: doc.data().total || 0,
            usuario:
              doc.data().vendedorNombre || doc.data().comprador || "Sin nombre",
            tipo,
            createdAt: doc.data().createdAt?.toMillis() || 0,
            datos: doc.data(),
          }));

          archivosTotales.push(...datos);

          const archivosOrdenados = archivosTotales
            .sort((a, b) => b.createdAt - a.createdAt)
            .slice(0, 5); // Selecciona solo los 5 más recientes

          setArchivos(archivosOrdenados);
        });

        unsubscribes.push(unsubscribe);
      });
    };
    obtenerArchivos();

    return () => {
      unsubscribes.forEach((unsub) => unsub());
    };
  }, []);

  const handleRowClick = (archivo) => {
    setSelectedArchivo(archivo);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArchivo(null);
    setModalOpen(false);
  };

  function formatDate(dateString) {
    if (!dateString) return "Fecha no disponible";

    const date = new Date(dateString); // Convierte el string o timestamp a Date
    if (isNaN(date)) return "Fecha no válida"; // Verifica si la fecha es válida

    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  function formatTotal(amount) {
    if (isNaN(amount)) return "0.00"; // Maneja casos donde el total no es un número
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div style={{ marginTop: "20px", paddingLeft: "70px" }}>
      <div className="archivos-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h3 style={{ margin: 0 }}>Archivos Recientes</h3>
        </div>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th style={{ width: "25%" }}>NOMBRE</th>
                <th>FECHA</th>
                <th>ESTATUS</th>
                <th>TOTAL</th>
                <th>USUARIO</th>
                <th>TIPO</th>
              </tr>
            </thead>
            <tbody>
              {archivos.length > 0 ? (
                archivos.map((archivo) => (
                  <tr
                    key={archivo.id}
                    onClick={() => handleRowClick(archivo)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="ellipsis">{archivo.nombre}</td>
                    <td>{formatDate(archivo.fecha)}</td>
                    <td>
                      <div
                        className={`status ${archivo.estado
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {archivo.estado}
                      </div>
                    </td>
                    <td>{formatTotal(archivo.total)}</td>
                    <td>{archivo.usuario}</td>
                    <td>{archivo.tipo}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No hay archivos recientes</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Vista de Archivo"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "80%",
            "::WebkitScrollbar": {
            width: "0px",
            background: "transparent",
            },
            /* Ocultar scrollbar en IE y Edge */
            msOverflowStyle: "none",
            /* Ocultar scrollbar en Firefox */
            scrollbarWidth: "none",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 101,
          },
        }}
      >
        {selectedArchivo && selectedArchivo.tipo === "Cotización" && (
          <ResumenCotizacion
            cotizacion={selectedArchivo.datos}
            isOpen={modalOpen}
            onClose={closeModal}
          />
        )}
        {selectedArchivo && selectedArchivo.tipo === "Orden" && (
          <ResumenOrden
            orden={selectedArchivo.datos}
            isOpen={modalOpen}
            onClose={closeModal}
          />
        )}
        {selectedArchivo && selectedArchivo.tipo === "Remisión" && (
          <ResumenRemision
            remision={selectedArchivo.datos}
            isOpen={modalOpen}
            onClose={closeModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default ArchivosRecientes;