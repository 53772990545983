import React, { useState } from "react";
import PedidoForm from "./PedidoForm";
import Modal from "react-modal";
import ResumenPedidos from "./ResumenPedidos";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 102
  },
};

const EditarPedidoForm = ({
  orden,
  clientes,
  productos,
  setOrdenes,
  ordenes,
  onClose,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [mostrarResumen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    // Llamar a la función onClose para cerrar el ResumenOrden
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="editar-cotizacion-form">
          <PedidoForm
            orden={orden} // Pasa 'orden' aquí
            clientes={clientes}
            productos={productos}
            setOrdenes={setOrdenes}
            ordenes={ordenes}
            closeModal={onClose}
          />
        </div>
      </Modal>
      {mostrarResumen && (
        <ResumenPedidos orden={orden} onClose={onClose} />
      )}
    </>
  );
};

export default EditarPedidoForm;