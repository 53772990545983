import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/es"; // Importamos el idioma español
import {
  getFirestore,
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import Nav from "./Nav";
import Spinner from "./Spinner"; // Importar tu componente Spinner
import Modal from "react-modal";
import Swal from "sweetalert2";
import ModalCreateEvent from "./ModalCreateEvent"; // Asegúrate de que la ruta sea correcta
import PedidosActivos from "./PedidosActivos";
import ArchivosRecientes from "./ArchivosRecientes";

Modal.setAppElement("#root");

// Configura el localizador de fechas usando moment.js
moment.locale("es");

const Dashboard = ({
  closeSidebar,
  ordenesDeCompra,
  calculateDaysLeft,
  openModal,
  modalData,
  closeModal,
  agregarComentario,
  comentarios,
  setNuevoComentario,
  nuevoComentario,
}) => {
  const [events, setEvents] = useState([]); // Array para los eventos del calendario
  const [currentDate, setCurrentDate] = useState(moment());
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga de datos
  const navigate = useNavigate();
  const [tab, setTab] = useState("Todos");
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeMenu, setActiveMenu] = useState(null);
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null); // Para manejar la edición

  const openCreateModal = (event = null) => {
    if (event) {
      // Si se pasa un evento, es una edición
      setEditingEvent(event);
    } else {
      // Si no, es una creación
      setEditingEvent(null);
    }
    setModalCreateIsOpen(true);
  };

  // Función para cerrar el modal
  const closeCreateModal = () => {
    setModalCreateIsOpen(false);
    setEditingEvent(null);
  };

  const handleSaveEvent = async (eventData) => {
    if (editingEvent) {
      // Es una edición
      try {
        const firestore = getFirestore();
        const eventoRef = doc(firestore, "eventos", editingEvent.id);
        await updateDoc(eventoRef, {
          title: eventData.title,
          to: eventData.to,
        });

        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === editingEvent.id ? { ...event, ...eventData } : event
          )
        );

        Swal.fire({
          title: "Evento Actualizado",
          text: "El evento ha sido actualizado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {
        console.error("Error al actualizar evento:", error);
        Swal.fire({
          title: "Error",
          text: "No se pudo actualizar el evento. Intenta nuevamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      // Es una creación
      try {
        const firestore = getFirestore();
        const eventosRef = collection(firestore, "eventos");

        await addDoc(eventosRef, {
          title: eventData.title,
          to: eventData.to,
          from: getCurrentDateTime(),
        });

        Swal.fire({
          title: "Evento Registrado",
          text: "El evento ha sido registrado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {
        console.error("Error al agregar evento:", error);
        Swal.fire({
          title: "Error",
          text: "No se pudo agregar el evento. Intenta nuevamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleRemoveFromList = (eventId) => {
    // Elimina el evento de la lista local sin afectar la base de datos
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== eventId)
    );

    Swal.fire({
      title: "Eliminado",
      text: "El evento ha sido eliminado de la lista.",
      icon: "info",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  // Filtrar solo los eventos con fecha >= hoy
  const upcomingEvents = events.filter((ev) =>
    moment(ev.start).isSameOrAfter(moment().startOf("day"), "day")
  );

  const handleDayClick = (day) => {
    // Asigna el día que el usuario hace clic como "seleccionado"
    setSelectedDate(day);
  };

  let tabFilteredEvents = [];

  // Lógica de 8 próximos (si no hay día seleccionado):
  if (tab === "Cotizaciones") {
    tabFilteredEvents = upcomingEvents
      .filter((ev) => ev.resource === "cotizacion")
      .sort((a, b) => moment(a.start) - moment(b.start))
      .slice(0, 8);
  } else if (tab === "Eventos") {
    tabFilteredEvents = upcomingEvents
      .filter((ev) => ev.resource === "evento")
      .sort((a, b) => moment(a.start) - moment(b.start))
      .slice(0, 8);
  } else if (tab === "Festivos") {
    const startOfCurrentMonth = moment().startOf("month"); // Inicio del mes actual
    const endOfNextMonth = moment().add(1, "month").endOf("month"); // Fin del próximo mes
    tabFilteredEvents = upcomingEvents
      .filter(
        (ev) =>
          ev.resource === "festivo" &&
          moment(ev.start).isBetween(
            startOfCurrentMonth,
            endOfNextMonth,
            "day",
            "[]"
          )
      )
      .sort((a, b) => moment(a.start) - moment(b.start))
      .slice(0, 8); // Limita a los primeros 8 eventos
  } else if (tab === "Todos") {
    tabFilteredEvents = upcomingEvents
      .sort((a, b) => moment(a.start) - moment(b.start))
      .slice(0, 8);
  }

  let displayedEvents = [];

  if (selectedDate) {
    displayedEvents = events.filter((ev) =>
      moment(ev.start).isSame(selectedDate, "day")
    );
  } else {
    // Si no hay día seleccionado, usamos los eventos filtrados según la pestaña
    displayedEvents = tabFilteredEvents;
  }

  let filteredEvents = displayedEvents;

  if (searchTerm.trim() !== "") {
    filteredEvents = displayedEvents.filter((event) =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  let finalDisplayedEvents = displayedEvents;

  // Si hay un día seleccionado, filtrar por ese día
  if (selectedDate) {
    displayedEvents = tabFilteredEvents.filter((ev) =>
      moment(ev.start).isSame(selectedDate, "day")
    );
  }

  const handleCotizacionClick = () => {
    navigate("/cotizacion", { state: { openModal: true } });
    if (closeSidebar) {
      closeSidebar(); // Si el Sidebar está implementado, lo cierra
    }
  };

  const handleOrdenClick = () => {
    navigate("/orden", { state: { openModal: true } });
    if (closeSidebar) {
      closeSidebar(); // Si el Sidebar está implementado, lo cierra
    }
  };

  const handleRemisionClick = () => {
    navigate("/remisiones", { state: { openModal: true } });
    if (closeSidebar) {
      closeSidebar(); // Si el Sidebar está implementado, lo cierra
    }
  };

  const navigateToCalendario = () => {
    navigate("/calendario");
  };

  const navigateToTareas = () => {
    navigate("/tareas");
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      console.log("Intentando eliminar evento con ID:", eventId); // Log del ID

      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará el evento de forma permanente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        const firestore = getFirestore();
        const eventoRef = doc(firestore, "eventos", eventId);

        // Verificar si el documento existe
        const docSnap = await getDoc(eventoRef);
        if (!docSnap.exists()) {
          console.error("El documento no existe en Firestore:", eventId);
          Swal.fire({
            title: "Error",
            text: "El evento no existe.",
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }

        await deleteDoc(eventoRef);
        console.log("Evento eliminado correctamente:", eventId); // Confirmación de eliminación

        // Eliminar del estado local
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventId)
        );

        Swal.fire({
          title: "Evento Eliminado",
          text: "El evento ha sido eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error al eliminar evento:", error);
      Swal.fire({
        title: "Error",
        text: "No se pudo eliminar el evento. Intenta nuevamente.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePrev = () =>
    setCurrentDate(currentDate.clone().subtract(1, "week"));
  const handleNext = () => setCurrentDate(currentDate.clone().add(1, "week"));

  const daysOfWeek = Array.from({ length: 7 }).map((_, i) =>
    currentDate.clone().startOf("week").add(i, "days")
  );

  const handleOpenCreateModal = () => {
    openCreateModal(); // Sin pasar ningún evento, es una creación
  };

  const handleEditEvent = (event) => {
    openCreateModal(event);
    setActiveMenu(null); // Opcional: Cerrar el menú desplegable
  };

  function getCurrentDateTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // Ajuste para zona horaria
    return now.toISOString().slice(0, 16); // Formato para input datetime-local
  }

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6));

          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: cotizacion.id, // Mantener el ID original
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment.utc(cotizacion.fechaVencimiento).toDate(),
            end: moment.utc(cotizacion.fechaVencimiento).toDate(),
            resource: "cotizacion",
            allDay: false,
          }));

          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: evento.id, // Mantener el ID original
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto",
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });

        setLoading(false);
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      // Festivos base (mismo array que ya tienes)
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      // Generar SOLO para el año actual
      const currentYear = new Date().getFullYear();
      const años = [currentYear];

      const fechasFestivas = [];
      años.forEach((año) => {
        fechasFestivasBase.forEach((festivo) => {
          const fecha = moment(
            `${año}-${festivo.month}-${festivo.day}`,
            "YYYY-MM-DD"
          ).toDate();

          fechasFestivas.push({
            id: `festivo-${festivo.title}-${año}`,
            title: festivo.title,
            start: fecha,
            end: fecha,
            allDay: true,
            resource: "festivo",
            style: {
              backgroundColor: "#de2e03",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            },
          });
        });
      });

      return fechasFestivas;
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  // Mostrar Spinner mientras los datos están cargando
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div style={{ paddingLeft: "70px" }}>
        <div className="categories-container">
          <h3
            style={{
              fontWeight: "bold",
              textAlign: "left",
              gridColumn: "1 / -1",
              margin: 0,
            }}
          >
            Accesos Rápidos
          </h3>
          <div className="category" onClick={handleCotizacionClick}>
            <i className="bi bi-file-text" style={{ color: "#329B24" }}></i>
            <span>Cotización</span>
          </div>
          <div className="category" onClick={handleOrdenClick}>
            <i
              className="bi bi-file-earmark-text"
              style={{ color: "#0000FF" }}
            ></i>
            <span>Orden de Compra</span>
          </div>
          <div className="category" onClick={handleOpenCreateModal}>
            <i
              className="bi bi-calendar-event"
              style={{ color: "#F44336" }}
            ></i>
            <span>Evento</span>
          </div>
          <div className="category" onClick={handleRemisionClick}>
            <i className="bi bi-receipt" style={{ color: "#b100cd" }}></i>
            <span>Remisión</span>
          </div>
          <div className="category">
            <i
              className="bi bi-file-earmark-bar-graph"
              style={{ color: "#4fc3f7" }}
            ></i>
            <span>Reporte de Servicio</span>
          </div>
          <div className="category" onClick={navigateToTareas}>
            <i className="bi bi-list-task" style={{ color: "#fb8c00" }}></i>
            <span>Tarea</span>
          </div>
        </div>
      </div>

      <PedidosActivos
        ordenesDeCompra={ordenesDeCompra}
        calculateDaysLeft={calculateDaysLeft}
        openModal={openModal}
        modalData={modalData}
        closeModal={closeModal}
        agregarComentario={agregarComentario}
        comentarios={comentarios}
        setNuevoComentario={setNuevoComentario}
        nuevoComentario={nuevoComentario}
      />

      <ArchivosRecientes />

      <div className="dashboard-container">
        <div className="simple-calendar">
          {/* Encabezado */}
          <div className="calendar-header">
            <h3>Calendario</h3>
            <button
              className="see-all-button"
              onClick={() => navigateToCalendario()}
            >
              Ver Todo
            </button>
          </div>

          {/* Navegación */}
          <div className="calendar-navigation">
            <button className="nav-button" onClick={handlePrev}>
              <i className="bi bi-chevron-left"></i>
            </button>
            <span className="current-month">
              {currentDate.format("MMM YYYY")}
            </span>
            <button className="nav-button" onClick={handleNext}>
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>

          {/* Días */}
          <div className="calendar-days">
            {daysOfWeek.map((day, index) => {
              // Verificar si es el día actual
              const isToday = currentDate.isSame(day, "day");
              // Verificar si es el día que el usuario seleccionó
              const isSelected =
                selectedDate && selectedDate.isSame(day, "day");

              let dayClass = "";
              if (isToday) {
                dayClass = "selected"; // Tu clase azul existente
              }
              if (isSelected) {
                dayClass = "clicked"; // Nueva clase para morado
              }

              return (
                <div
                  key={index}
                  className={`day ${dayClass}`}
                  onClick={() => handleDayClick(day)} // Asigna el click
                >
                  <span className={`day-name ${dayClass}`}>
                    {day.format("ddd")}
                  </span>
                  <span className="day-number">{day.format("DD")}</span>
                </div>
              );
            })}
          </div>
        </div>

        {/* Barra de búsqueda */}
        <div className="calendar-search-bar">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="bi bi-search"></i>
        </div>

        {/* Pestañas */}
        <div className="tabs">
          <button
            className={`tab ${tab === "Todos" ? "active" : ""}`}
            onClick={() => {
              setTab("Todos");
              setSelectedDate(null); // <<--- reset selección de día
            }}
          >
            Todos
          </button>
          <button
            className={`tab ${tab === "Cotizaciones" ? "active" : ""}`}
            onClick={() => {
              setTab("Cotizaciones");
              setSelectedDate(null); // <<--- reset selección de día
            }}
          >
            Cotizaciones
          </button>
          <button
            className={`tab ${tab === "Eventos" ? "active" : ""}`}
            onClick={() => {
              setTab("Eventos");
              setSelectedDate(null); // <<--- reset selección de día
            }}
          >
            Eventos
          </button>
          <button
            className={`tab ${tab === "Festivos" ? "active" : ""}`}
            onClick={() => {
              setTab("Festivos");
              setSelectedDate(null); // <<--- reset selección de día
            }}
          >
            Festivos
          </button>
        </div>

        {/* Lista de eventos */}
        <div className="events-list">
          {finalDisplayedEvents.length === 0 && searchTerm.trim() === "" ? (
            <p style={{ marginTop: "50px" }}>
              No hay cotizaciones, eventos o días festivos.
            </p>
          ) : (
            finalDisplayedEvents
              .filter((event) =>
                searchTerm.trim() === ""
                  ? true
                  : event.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((event) => (
                <div
                  key={`${event.resource}-${event.id}`}
                  className={`event-item ${
                    event.resource === "festivo"
                      ? "festivo-bg"
                      : event.resource === "evento"
                      ? "evento-bg"
                      : "cotizacion-bg"
                  }`}
                >
                  <div className="event-header">
                    <h4 className="event-title">{event.title}</h4>
                    <div style={{ position: "relative" }}>
                      <button
                        className="dropdown-button"
                        onClick={() =>
                          setActiveMenu(
                            activeMenu === event.id ? null : event.id
                          )
                        }
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>
                      {activeMenu === event.id && (
                        <div className="event-options-menu">
                          {event.resource === "evento" ? (
                            <>
                              <button
                                onClick={() => handleEditEvent(event)}
                                className="editarEvento"
                              >
                                <i
                                  className="bi bi-pencil"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                Editar
                              </button>
                              <button
                                onClick={() => handleDeleteEvent(event.id)}
                                className="eliminarEvento"
                              >
                                <i
                                  className="bi bi-trash"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                Eliminar
                              </button>
                            </>
                          ) : (
                            <button
                              onClick={() => handleRemoveFromList(event.id)}
                              className="eliminarEvento"
                            >
                              <i
                                className="bi bi-trash"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Eliminar
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="event-time">
                    Vence el:{" "}
                    {moment
                      .utc(event.end) // Interpretar la fecha en UTC
                      .local() // Convertir a hora local del cliente
                      .format("dddd, DD [de] MMMM [de] YYYY") // Formato amigable
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </p>

                  <div className="event-role">
                    <span className="role-badge">
                      {event.resource === "cotizacion"
                        ? "COTIZACIÓN"
                        : event.resource === "evento"
                        ? "EVENTO"
                        : "FESTIVO"}
                    </span>
                  </div>
                </div>
              ))
          )}

          {/* Mensaje si no hay resultados para el término de búsqueda */}
          {finalDisplayedEvents.length > 0 && filteredEvents.length === 0 && (
            <p style={{ marginTop: "50px" }}>
              No hay resultados para la búsqueda.
            </p>
          )}
        </div>
      </div>

      <ModalCreateEvent
        isOpen={modalCreateIsOpen}
        onClose={closeCreateModal}
        onSave={handleSaveEvent}
        initialData={editingEvent}
      />
    </>
  );
};

export default Dashboard;