import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "./AuthContext";
import "bootstrap-icons/font/bootstrap-icons.css";
import Spinner from "./Spinner";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      Swal.fire({
        icon: "error",
        title: "Campos obligatorios",
        text: "Por favor, complete todos los campos.",
      });
      return;
    }
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      // Simulate data loading
      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 2000); // Ajusta el tiempo de carga simulado según sea necesario
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error en el inicio de sesión",
        text: "Las credenciales son incorrectas",
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="login-container">
      <div className="login-background"></div>
      <div className="login-form">
        <div className="logo-container">
          <img
            src="/img/logo-iciamex.png"
            alt="Logotipo"
            className="logo-login"
          />
        </div>
        <h1>¡Bienvenido!</h1>
        <p className="login-description">
          Ingrese su correo electrónico y contraseña para iniciar sesión en esta
          aplicación
        </p>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email"></label>
            <div className="input-container">
              <img
                src="/img/user.svg"
                alt="Email Icon"
                className="input-icon"
              />
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo Electrónico"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password"></label>
            <div className="input-container">
              <img
                src="/img/password.svg"
                alt="Password Icon"
                className="input-icon"
              />
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
              />
              <i
                className={`bi ${
                  showPassword ? "bi-eye-slash" : "bi-eye"
                } toggle-password`}
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          <div className="login-button-container">
            <button type="submit" className="login-button">
              Iniciar Sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
