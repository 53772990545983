import React from "react";
import Modal from "react-modal";

// Asegúrate de establecer el elemento raíz para accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "90vh",
    height: "430px", // Altura ajustada
    overflow: "auto",
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 102,
  },
  label: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    fontSize: "14px",
  },
  select: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    fontSize: "14px",
    appearance: "none",
    backgroundColor: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    backgroundSize: "12px",
    cursor: "pointer",
  },
  button: {
    width: "50%",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "20px",
    position: "absolute", // Cambiado a absolute
    bottom: "20px", // Ajusta según sea necesario
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const ProductDetailsModal = ({ isOpen, onClose, categoria }) => {
  const producto = categoria;
  if (!producto) return null; // No mostrar nada si no hay producto seleccionado

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <button
        className="modal-close"
        onClick={onClose}
        aria-label="Close Modal"
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        Detalles del Producto
      </h2>
      <form style={{ display: "flex", gap: "20px" }}>
        <div style={{ flex: 2 }}>
          <label style={customStyles.label}>Número de Parte Cliente:</label>
          <input
            type="text"
            name="numeroDeParteCliente"
            value={producto.numeroDeParteCliente}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del cliente"
          />

          <label style={customStyles.label}>Número de Parte Proveedor:</label>
          <input
            type="text"
            name="numeroDeParteProveedor"
            value={producto.numeroDeParteProveedor}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del proveedor"
          />

          <label style={customStyles.label}>Nombre:</label>
          <input
            type="text"
            name="nombre"
            value={producto.nombre}
            style={customStyles.input}
            placeholder="Ingresa la descripción del producto"
          />

          <label style={customStyles.label}>Costo:</label>
          <input
            type="number"
            name="costo"
            value={producto.costo}
            style={customStyles.input}
            placeholder="Ingresa el costo del producto"
            min="0"
            step="0.01"
          />

          <label style={customStyles.label}>Categoría:</label>
          <input
            type="text"
            name="categoria"
            value={producto.categoria}
            readOnly
            style={customStyles.input}
          />
        </div>

        <div style={{ flex: 1, textAlign: "center" }}>
          {producto.imagen && (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                height: "325px",
                width: "350px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                border: "2px dashed #ccc",
                borderRadius: "8px",
              }}
            >
              <img
                src={producto.imagen}
                alt="Categoría"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ProductDetailsModal;
