import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import OrdenForm from "./OrdenForm";
import Paginador from "./Paginador";

Modal.setAppElement("#root");

const BandejaOrdenes = ({
  ordenes,
  onRowClick,
  guardarOrden,
  selectedOrdenId,
  orden,
  proveedores,
}) => {
  const [filteredOrdenes, setFilteredOrdenes] = useState(ordenes);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showBulkActionsOptions, setShowBulkActionsOptions] = useState(false);
  const [selectedOrdenes, setSelectedOrdenes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredOrdenes.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const paginatedOrdenes = filteredOrdenes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = ordenes.filter((orden) => {
      const formattedImporte = parseFloat(orden?.total)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        orden.nombreProveedor
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        orden.fechaOrden.includes(searchTerm) ||
        orden.numeroOrden.toString().padStart(4, "0").includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredOrdenes(filtered);
    setCurrentPage(1);
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleBulkActionsOptions = () => {
    setShowBulkActionsOptions(!showBulkActionsOptions);
  };

  const handleSelectOrden = (ordenId) => {
    setSelectedOrdenes((prevSelected) =>
      prevSelected.includes(ordenId)
        ? prevSelected.filter((id) => id !== ordenId)
        : [...prevSelected, ordenId]
    );
  };

  // Checkbox genérico - seleccionar todos
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOrdenes([]); // Deselecciona todo
    } else {
      setSelectedOrdenes(ordenes.map((orden) => orden.id)); // Selecciona todo
    }
    setSelectAll(!selectAll);
  };

  const showBulkActions = selectedOrdenes.length > 0;

  const exportarPDF = () => {
    console.log("Exportar como PDF");
  };

  const imprimir = () => {
    console.log("Imprimir orden");
  };

  const eliminar = () => {
    console.log("Eliminar orden");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".bulk-actions-right")) {
        setShowBulkActionsOptions(false);
      }
    };
    if (showBulkActionsOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showBulkActionsOptions]);

  return (
    <div className="cotizaciones-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input
            type="text"
            className="bandeja-search-input"
            placeholder="Buscar..."
            onChange={handleSearch}
          />
        </div>
      </div>

      {showBulkActions && (
        <div className="bulk-actions">
          <div className="bulk-actions-left">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ marginTop: "0", alignSelf: "center" }}
            />
            <p>{selectedOrdenes.length} seleccionados</p>
          </div>

          <div className="bulk-actions-right-container">
            <button className="boton-accion">Marcar como enviado</button>
            <div
              className="bulk-actions-right"
              style={{ position: "relative" }}
            >
              <i
                className="bi bi-three-dots-vertical"
                onClick={toggleBulkActionsOptions}
                style={{ cursor: "pointer" }}
              ></i>
              {showBulkActionsOptions && (
                <div className="dropdown-options-bandeja">
                  <p onClick={exportarPDF}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-box-arrow-up-right"
                    ></i>
                    Exportar como PDF
                  </p>
                  <p onClick={imprimir}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-printer"
                    ></i>
                    Imprimir
                  </p>
                  <p onClick={eliminar}>
                    <i style={{ fontSize: "14px" }} className="bi bi-trash"></i>
                    Eliminar
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginLeft: "10px" }}>Todas las ordenes</p>
            <i
              className="bi bi-chevron-down"
              style={{ color: "#007bff", fontWeight: "bold" }}
            ></i>
          </div>
          {showDropdown && (
            <div className="dropdown-menu">
              <p>
                <i className="bi bi-envelope-check"></i>Ordenes enviadas
              </p>
              <p>
                <i className="bi bi-clock"></i>Ordenes pendientes
              </p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: "relative" }}>
          <button className="boton-accion" onClick={openFormulario}>
            + Nuevo
          </button>
          <div style={{ position: "relative", display: "inline-block" }}>
            <i
              className="bi bi-three-dots-vertical"
              onClick={toggleOptions}
              style={{ cursor: "pointer" }}
            ></i>
            {showOptions && (
              <div className="dropdown-options-bandeja">
                <p onClick={() => console.log("Ordenar")}>
                  <i className="bi bi-arrow-down-up"></i>Ordenar
                </p>
                <p onClick={() => console.log("Importar")}>
                  <i className="bi bi-box-arrow-in-down"></i>Importar
                </p>
                <p onClick={() => console.log("Exportar")}>
                  <i className="bi bi-box-arrow-up"></i>Exportar
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {paginatedOrdenes.length > 0 ? (
        paginatedOrdenes.map((orden) => (
          <div
            key={orden.id}
            className={`mensaje-cotizacion ${
              selectedOrdenId === orden.id ? "mensaje-seleccionado" : ""
            }`}
            onClick={() => {
              onRowClick(orden.id);
            }}
            tabIndex="0"
          >
            <div className="info-container">
              <input
                type="checkbox"
                onChange={() => handleSelectOrden(orden.id)}
                checked={selectedOrdenes.includes(orden.id)}
              />
              <div className="info-column">
                <div className="cliente-importe">
                  <span className="cliente-nombre">
                    {orden.nombreProveedor}
                  </span>
                  <span className="importe">
                    {orden.divisa}{" "}
                    {parseFloat(orden?.total)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <div className="fecha-referencia">
                  <p> {orden.numeroOrden.toString().padStart(4, "0")}</p>
                  <span className="punto">•</span>
                  <p>{formatDate(new Date(orden.fechaOrden))}</p>
                </div>
                <div
                  className={`status2 ${
                    orden.estado === "Pendiente"
                      ? "pendiente2"
                      : orden.estado === "En Proceso"
                      ? "en-proceso2"
                      : "pagada2"
                  }`}
                >
                  {orden.estado}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={{ position: "relative", right: "32%", marginTop: "80px" }}>
          <p style={{ fontSize: "14px" }}>
            No existe ninguna orden de compra que coincida con esta búsqueda.
          </p>

          <div className="sin-resultados-animation">
            <img
              src="/img/noSearch.svg"
              alt="Sin resultados"
              className="sin-resultados-imagen"
            />
          </div>
        </div>
      )}

      <div className="paginador">
        <div style={{ paddingLeft: "10px", width: "34%" }}>
          <Paginador
            totalItems={filteredOrdenes.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Orden"
        style={{
          overlay: {
            zIndex: 2,
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingLeft: 0,
            padding: 0,
            fontFamily: "Roboto, sans-serif",
            "::WebkitScrollbar": {
              width: "0px",
              background: "transparent",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>

        <OrdenForm
          proveedores={proveedores}
          guardarOrden={guardarOrden}
          orden={orden}
        />
      </Modal>
    </div>
  );
};

export default BandejaOrdenes;