import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Asegúrate de que la ruta es correcta
import Swal from "sweetalert2";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "90vh",
    height: "480px",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif",
    scrollbarWidth: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 102,
  },
  label: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    fontSize: "14px",
  },
  button: {
    width: "50%",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "700",
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const EditProductModal = ({ isOpen, onClose, product }) => {
  const [productDetails, setProductDetails] = useState({
    numeroDeParteCliente: "",
    numeroDeParteProveedor: "",
    nombre: "",
    costo: "",
    categoria: "",
    imagen: "",
  });

  useEffect(() => {
    if (product) {
      setProductDetails({
        numeroDeParteCliente: product.numeroDeParteCliente || "",
        numeroDeParteProveedor: product.numeroDeParteProveedor || "",
        nombre: product.nombre || "",
        costo: product.costo || "",
        categoria: product.categoria || "",
        imagen: product.imagen || "",
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones
    if (
      !productDetails.numeroDeParteCliente.trim() ||
      !productDetails.nombre.trim() ||
      !productDetails.costo
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los campos Número de Parte Cliente, Nombre y Costo son obligatorios.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    try {
      const productRef = doc(db, "productos", product.id);
      await updateDoc(productRef, productDetails);
      Swal.fire({
        icon: "success",
        title: "¡Producto actualizado!",
        showConfirmButton: false,
        timer: 1500,
      });
      onClose();
    } catch (error) {
      console.error("Error al actualizar el producto:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al actualizar el producto.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  if (!product) return null; // No mostrar el modal si no hay producto seleccionado

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <button
        className="modal-close"
        onClick={onClose}
        aria-label="Close Modal"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Editar Producto</h2>
      <form onSubmit={handleSubmit} style={{ display: "flex", gap: "20px" }}>
        <div style={{ flex: 2 }}>
          <label style={customStyles.label}>Número de Parte Cliente:</label>
          <input
            type="text"
            name="numeroDeParteCliente"
            value={productDetails.numeroDeParteCliente}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del cliente"
          />

          <label style={customStyles.label}>Número de Parte Proveedor:</label>
          <input
            type="text"
            name="numeroDeParteProveedor"
            value={productDetails.numeroDeParteProveedor}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del proveedor"
          />

          <label style={customStyles.label}>Nombre:</label>
          <input
            type="text"
            name="nombre"
            value={productDetails.nombre}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa la descripción del producto"
          />

          <label style={customStyles.label}>Costo:</label>
          <input
            type="number"
            name="costo"
            value={productDetails.costo}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el costo del producto"
            min="0"
            step="0.01"
          />

          <label style={customStyles.label}>Categoría:</label>
          <input
            type="text"
            name="categoria"
            value={productDetails.categoria}
            readOnly
            style={customStyles.input}
          />
        </div>

        <div style={{ flex: 1, textAlign: "center" }}>
          {product.imagen && (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                height: "325px",
                width: "350px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                border: "2px dashed #ccc",
                borderRadius: "8px",
              }}
            >
              <img
                src={product.imagen}
                alt="Producto"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
        <button type="submit" style={customStyles.button}>
          Guardar Cambios
        </button>
      </form>
    </Modal>
  );
};

export default EditProductModal;
