import React from 'react'
import NuevaOrden from '../components/NuevaOrden';

const orden = () => {
  return (
    <div>
      <NuevaOrden />
    </div>
  )
}

export default orden