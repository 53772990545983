import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  addDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import Modal from "react-modal";
import Nav from "./Nav";
import ModalCreateEvent from "./ModalCreateEvent";
import VistaDia from "./VistaDia";
import VistaSemana from "./VistaSemana";
import VistaMes from "./VistaMes";
import VistaAño from "./VistaAño";

Modal.setAppElement("#root");

const CalendarioGd = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewMode, setViewMode] = useState("Month view");
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);

  const getFechasFestivas = useCallback(() => {
    const fechasFestivasBase = [
      { title: "Año Nuevo", month: "01", day: "01", color: "#fee2e2" },
      {
        title: "Día de la Constitución",
        month: "02",
        day: "05",
        color: "#fee2e2",
      },
      {
        title: "Natalicio de Benito Juárez",
        month: "03",
        day: "21",
        color: "#fee2e2",
      },
      { title: "Día del Trabajo", month: "05", day: "01", color: "#fee2e2" },
      {
        title: "Independencia de México",
        month: "09",
        day: "16",
        color: "#fee2e2",
      },
      {
        title: "Revolución Mexicana",
        month: "11",
        day: "20",
        color: "#fee2e2",
      },
      { title: "Navidad", month: "12", day: "25", color: "#fee2e2" },
    ];

    return fechasFestivasBase.map((festivo) => ({
      id: `festivo-${festivo.title}`,
      title: festivo.title,
      start: moment(
        `${moment().year()}-${festivo.month}-${festivo.day}`,
        "YYYY-MM-DD"
      ).toDate(),
      end: moment(
        `${moment().year()}-${festivo.month}-${festivo.day}`,
        "YYYY-MM-DD"
      ).toDate(),
      allDay: true,
      resource: "festivo",
      color: festivo.color,
    }));
  }, []);

  const fetchFirestoreData = useCallback(async () => {
    const firestore = getFirestore();

    const cotizacionesSnap = await getDocs(
      query(collection(firestore, "cotizaciones"))
    );
    const cotizaciones = cotizacionesSnap.docs.map((doc) => {
      const data = doc.data();
      return {
        id: `cotizacion-${doc.id}`,
        title: `Cotización #${data.numeroCotizacion || "Sin número"}`,
        numeroCotizacion: data.numeroCotizacion || "Sin número",
        start: moment(data.fechaVencimiento).startOf("day").toDate(),
        end: moment(data.fechaVencimiento).endOf("day").toDate(),
        allDay: true,
        resource: "cotizacion",
        color: "#dbeafe",
        vence: moment(data.fechaVencimiento).format("DD/MM/YYYY"),
      };
    });

    const proximasCotizaciones = cotizaciones
      .filter((cotizacion) =>
        moment(cotizacion.start).isSameOrAfter(moment().startOf("day"))
      )
      .sort((a, b) => moment(a.start).diff(moment(b.start)))
      .slice(0, 6);
    setProximasAVencer(proximasCotizaciones);

    const eventosSnap = await getDocs(query(collection(firestore, "eventos")));
    const eventos = eventosSnap.docs.map((doc) => {
      const data = doc.data();
      return {
        id: `evento-${doc.id}`,
        title: data.title,
        start: moment(data.to).toDate(),
        end: moment(data.to).toDate(),
        allDay: true,
        resource: "evento",
        color: "#dcfce7",
      };
    });

    const proximosEventosFiltrados = eventos
      .filter((evento) =>
        moment(evento.start).isSameOrAfter(moment().startOf("day"))
      )
      .sort((a, b) => moment(a.start).diff(moment(b.start)))
      .slice(0, 6);
    setProximosEventos(proximosEventosFiltrados);

    const fechasFestivas = getFechasFestivas();
    setEvents([...fechasFestivas, ...cotizaciones, ...eventos]);
  }, [getFechasFestivas]);

  useEffect(() => {
    fetchFirestoreData();
  }, [fetchFirestoreData]);

  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);

  const handleSaveEvent = useCallback(
    async ({ title, date }) => {
      try {
        const firestore = getFirestore();
        const eventosRef = collection(firestore, "eventos");
        const from = moment(date).startOf("day").toISOString();
        const to = moment(date).endOf("day").toISOString();

        await addDoc(eventosRef, {
          title,
          from,
          to,
          color: "green",
          resource: "evento",
        });

        Swal.fire({
          title: "Evento registrado",
          text: "El evento ha sido añadido exitosamente.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });

        closeModal();
        fetchFirestoreData();
      } catch (error) {
        console.error("Error al agregar evento:", error);
      }
    },
    [closeModal, fetchFirestoreData]
  );

  const handlePrevMonth = useCallback(() => {
    setCurrentDate((prev) => prev.clone().subtract(1, "month"));
  }, []);

  const handleNextMonth = useCallback(() => {
    setCurrentDate((prev) => prev.clone().add(1, "month"));
  }, []);

  const handleToday = useCallback(() => {
    setCurrentDate(moment());
  }, []);

  const handleViewChange = useCallback((e) => setViewMode(e.target.value), []);

  const renderView = () => {
    switch (viewMode) {
      case "Day view":
        return (
          <VistaDia
            events={events}
            currentDate={currentDate}
            onAddEvent={openModal}
          />
        );
      case "Week view":
        return (
          <VistaSemana
            events={events}
            currentDate={currentDate}
            onAddEvent={openModal}
          />
        );
      case "Month view":
        return (
          <VistaMes
            events={events}
            currentDate={currentDate}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            handleToday={handleToday}
          />
        );
      case "Year view":
        return <VistaAño events={events} currentDate={currentDate} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        events={events}
      />
      
      <div className="tailwind-scope">
        <div className="flex justify-between items-center p-4 bg-white shadow-lg rounded-md">
          <div className="flex items-center gap-8 ml-12">
            <button
              onClick={handlePrevMonth}
              className="w-10 h-10 bg-white hover:bg-gray-100 rounded-full flex items-center justify-center"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <div className="text-xl font-semibold text-gray-900 capitalize">
              {currentDate.format("MMMM YYYY")}
            </div>
            <button
              onClick={handleNextMonth}
              className="w-10 h-10 bg-white hover:bg-gray-100 rounded-full flex items-center justify-center"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleToday}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Hoy
            </button>
            <select
              value={viewMode}
              onChange={handleViewChange}
              className="px-3 py-2 border rounded"
            >
              <option value="Day view">Vista de Día</option>
              <option value="Week view">Vista de Semana</option>
              <option value="Month view">Vista de Mes</option>
              <option value="Year view">Vista de Año</option>
            </select>
            <button
              onClick={openModal}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Agregar Evento
            </button>
          </div>
        </div>
        {renderView()}
        <ModalCreateEvent
          isOpen={modalIsOpen}
          onClose={closeModal}
          onSave={handleSaveEvent}
        />
      </div>
    </>
  );
};

export default CalendarioGd;