// VistaMes.js
import React, { useState } from "react";
import moment from "moment";
import Modal from "react-modal";

const VistaMes = ({ events, currentDate, getGradientBackground }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalEvents, setModalEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const daysInMonth = currentDate.daysInMonth();
  const startOfMonth = (currentDate.clone().startOf("month").day() + 6) % 7;
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const daysOfWeek = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];

  const handleDayClick = (category, events) => {
    setSelectedCategory(category);
    setModalEvents(events);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalEvents([]);
    setSelectedCategory(null);
  };

  return (
    <>
      <div className="ml-10 bg-gray-50 h-auto">
        <div className="mx-auto bg-white rounded-lg shadow-sm p-2 sm:p-4 max-w-full sm:max-w-[600px] md:max-w-[900px] lg:max-w-[1200px]">
          {/* Controles superiores */}

          {/* Encabezado de los días de la semana */}
          <div className="grid grid-cols-7 text-xs mt-6 text-gray-500 text-center border-b pb-2">
            {daysOfWeek.map((day) => (
              <div key={day}>{day}</div>
            ))}
          </div>

          {/* Días del mes */}
          <div className="grid grid-cols-7 text-sm">
            {/* Espacios vacíos antes del inicio del mes */}
            {Array.from({ length: startOfMonth }).map((_, idx) => (
              <div key={idx} className="h-28 border-b"></div>
            ))}
            {/* Días del mes */}
            {daysArray.map((day) => {
              const isToday =
                currentDate.year() === moment().year() &&
                currentDate.month() === moment().month() &&
                day === parseInt(moment().format("D"), 10);

              const dayEvents = events.filter((event) =>
                moment(event.start).isSame(currentDate.clone().date(day), "day")
              );

              const cotizaciones = dayEvents.filter(
                (event) => event.resource === "cotizacion"
              );
              const eventos = dayEvents.filter(
                (event) => event.resource === "evento"
              );
              const festivos = dayEvents.filter(
                (event) => event.resource === "festivo"
              );

              const hasMultipleEvents =
                cotizaciones.length + eventos.length + festivos.length > 1;

              return (
                <div
                  key={day}
                  className={`relative h-28 border-b p-2 flex flex-col ${
                    hasMultipleEvents
                      ? "justify-start mt-2"
                      : "justify-end mt-auto"
                  }`}
                >
                  {/* Día del mes */}
                  <span
                    className={`text-xs font-semibold absolute top-2 right-2 ${
                      isToday
                        ? "bg-blue-500 text-white rounded-full px-2 py-0.5"
                        : "text-gray-700"
                    }`}
                  >
                    {day}
                  </span>

                  {/* Botones de eventos */}
                  <div className="flex flex-col gap-2 items-center">
                    {cotizaciones.length > 0 && (
                      <button
                        className="px-3 py-1 w-[120px] text-xs bg-blue-100 text-blue-500 rounded-full hover:bg-blue-200 flex justify-center items-center"
                        onClick={() =>
                          handleDayClick("cotizacion", cotizaciones)
                        }
                      >
                        <i className="bi bi-file-earmark-text mr-1"></i>
                        Cotizaciones
                      </button>
                    )}

                    {eventos.length > 0 && (
                      <button
                        className="px-3 py-1 w-[120px] text-xs bg-green-100 text-green-500 rounded-full hover:bg-green-200 flex justify-center items-center"
                        onClick={() => handleDayClick("evento", eventos)}
                      >
                        <i className="bi bi-calendar-event mr-1"></i>
                        Eventos
                      </button>
                    )}

                    {festivos.length > 0 && (
                      <button
                        className="px-3 py-1 w-[120px] text-xs bg-red-100 text-red-500 rounded-full hover:bg-red-200 flex justify-center items-center"
                        onClick={() => handleDayClick("festivo", festivos)}
                      >
                        <i className="bi bi-star mr-1"></i>
                        Festivos
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            {/* Espacios vacíos al final del mes */}
            {Array.from({
              length: 7 - ((startOfMonth + daysInMonth) % 7 || 7),
            }).map((_, idx) => (
              <div key={idx} className="h-28 border-b"></div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Detalles del Día"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 101,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "90%",
            borderRadius: "12px",
            padding: "20px",
            boxShadow: "0 8px 24px rgba(0, 0, 0, 0.3)",
            border: "none",
            backgroundColor: "#fff",
            fontFamily: "'Roboto', sans-serif", // Aquí se aplica la fuente
          },
        }}
      >
        <div>
          {/* Botón de cierre */}
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#999",
            }}
            aria-label="Cerrar modal"
          >
            <i className="bi bi-x-lg"></i>
          </button>

          {/* Contenido del modal */}
          <div>
            <h2 style={{ textAlign: "center" }}>
              {selectedCategory === "cotizacion" && "Cotizaciones"}
              {selectedCategory === "evento" && "Eventos"}
              {selectedCategory === "festivo" && "Días Festivos"}
            </h2>
            <div>
              {modalEvents.map((event) => (
                <div
                  key={event.id}
                  style={{
                    background: event.color,
                    padding: "0.5rem",
                    borderRadius: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    style={{ marginRight: "10px" }}
                    className={`bi ${
                      selectedCategory === "cotizacion"
                        ? "bi-file-earmark-text"
                        : selectedCategory === "evento"
                        ? "bi-calendar-event"
                        : "bi-star"
                    } mr-2`}
                  ></i>
                  {event.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VistaMes;