import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, updateDoc, doc, query, where, deleteDoc, getDocs } from 'firebase/firestore';
import { sendSignInLinkToEmail } from 'firebase/auth';
import Swal from 'sweetalert2';
import Nav from './Nav';
import moment from 'moment';
import { UsuariosContext } from '../context/UsuariosContext';
import { useNavContext } from "../context/NavContext";

moment.locale('es');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
    height: '400px',
    maxHeight: '90vh',
    overflow: 'auto',
    fontFamily: 'Roboto, sans-serif',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    width: '50%',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    fontWeight: '700',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100
  },
};

const PermisosUsuarios = () => {
  const usuarios = useContext(UsuariosContext); // Obtenemos los usuarios del contexto
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [nuevoUsuario, setNuevoUsuario] = useState({
    nombre: '',
    apellido: '',
    email: '',
    rol: 'Ventas',
  });
  const { proximasAVencer, proximosEventos } = useNavContext();


  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setNuevoUsuario({
      nombre: '',
      apellido: '',
      email: '',
      rol: 'Ventas',
    });
  };

  const handleInputChange = (e) => {
    setNuevoUsuario({
      ...nuevoUsuario,
      [e.target.name]: e.target.value,
    });
  };

  const handleInviteUser = () => {
    handleOpenModal();
  };

  const handleSaveUser = async () => {
    if (nuevoUsuario.nombre.trim() === '' || nuevoUsuario.email.trim() === '') {
      alert('Por favor ingresa nombre y correo electrónico válidos.');
      return;
    }

    const emailNormalizado = nuevoUsuario.email.trim().toLowerCase();

    try {
      const userQuery = query(collection(db, 'usuarios'), where('email', '==', emailNormalizado));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        // El usuario ya existe, actualizamos sus datos en el mismo documento
        const existingUserRef = querySnapshot.docs[0].ref;
        await updateDoc(existingUserRef, {
          nombre: nuevoUsuario.nombre,
          apellido: nuevoUsuario.apellido,
          email: emailNormalizado,
          rol: nuevoUsuario.rol,
        });

        Swal.fire({
          icon: 'success',
          title: '¡Usuario actualizado!',
          text: 'El usuario ha sido actualizado correctamente.',
        });
      } else {
        // El usuario no existe, lo añadimos como nuevo con el email normalizado
        await addDoc(collection(db, 'usuarios'), {
          nombre: nuevoUsuario.nombre,
          apellido: nuevoUsuario.apellido,
          email: emailNormalizado,
          rol: nuevoUsuario.rol,
          registrado: false
        });

        await sendVerificationEmail(emailNormalizado);

        Swal.fire({
          icon: 'success',
          title: '¡Usuario agregado al equipo!',
          text: 'Se ha enviado un correo de verificación.',
        });
      }

      handleCloseModal();
    } catch (error) {
      console.error('Error al guardar usuario: ', error);
    }
  };


  const sendVerificationEmail = async (email) => {
    try {
      const actionCodeSettings = {
        url: 'https://app.iciamex.com/registro',
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      console.log('Correo de verificación enviado a: ', email);
    } catch (error) {
      console.error('Error al enviar correo de verificación:', error);
    }
  };

  const handleEditUser = (index) => {
    const usuarioAEditar = usuarios[index];
    setNuevoUsuario({
      nombre: usuarioAEditar.nombre,
      apellido: usuarioAEditar.apellido,
      email: usuarioAEditar.email,
      rol: usuarioAEditar.rol,
      id: usuarioAEditar.id,
    });
    handleOpenModal();
  };

  const handleDeleteUser = async (index) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: '¿Estás seguro?',
        text: 'Esta acción eliminará al usuario permanentemente.',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        const userIdToDelete = usuarios[index].id;
        await deleteDoc(doc(db, 'usuarios', userIdToDelete));

        Swal.fire({
          icon: 'success',
          title: '¡Usuario eliminado!',
          timer: 1000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
    }
  };

  // Verificar si los usuarios están cargados desde localStorage
  if (usuarios.length === 0) {
    return <p>Cargando usuarios...</p>;
  }

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="permisos-usuarios-container">
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <h4 style={{ textAlign: 'left', margin: 0 }}>Todos los usuarios</h4>
          <i className="bi bi-chevron-down" style={{ fontSize: '15px', marginLeft: '5px', color: "#007bff" }}></i>
        </div>

        <div className="invite-form">
          <button
            onClick={handleInviteUser}
            style={{
              position: 'absolute',
              padding: '12px 10px',
              fontSize: '14px',
              borderRadius: '5px',
              right: '70px',
              marginBottom: '40px',
            }}
          >
            Invitar a un usuario
          </button>
        </div>

        <table className="usuarios-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Estado</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario, index) => (
              <tr key={usuario.id}>
                <td>{usuario.nombre} {usuario.apellido}</td>
                <td>{usuario.email}</td>
                <td>{usuario.rol}</td>
                <td style={{ color: usuario.registrado ? 'green' : 'red' }}>
                  {usuario.registrado ? 'Registrado' : 'No Registrado'}
                </td>
                <td>
                  <button className='btnEditar' onClick={() => handleEditUser(index)}>Editar</button>
                  <button className='btnEliminar' onClick={() => handleDeleteUser(index)}>Eliminar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          style={customStyles}
          contentLabel="Modal de invitación"
          ariaHideApp={false}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              onClick={handleCloseModal}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px',
                color: '#000',
              }}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>

          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Invitar a un nuevo usuario</h2>

          <div>
            <div style={{ display: 'flex', marginBottom: '15px', gap: '10px' }}>
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  name="nombre"
                  placeholder="Nombre(s)"
                  value={nuevoUsuario.nombre}
                  onChange={handleInputChange}
                  style={customStyles.input}
                />
              </div>
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  name="apellido"
                  placeholder="Apellido(s)"
                  value={nuevoUsuario.apellido}
                  onChange={handleInputChange}
                  style={customStyles.input}
                />
              </div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <input
                type="email"
                name="email"
                placeholder="Correo electrónico"
                value={nuevoUsuario.email}
                onChange={handleInputChange}
                style={customStyles.input}
              />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label style={customStyles.label}>Rol:</label>
              <select
                name="rol"
                value={nuevoUsuario.rol}
                onChange={handleInputChange}
                style={customStyles.input}
              >
                <option value="Ventas">Ventas</option>
                <option value="Administrador">Administrador</option>
              </select>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: "center" }}>
              <button
                onClick={handleSaveUser}
                style={{
                  padding: "10px 20px",
                  margin: "10px 5px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#007bff",
                  color: "white",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Guardar
              </button>
              <button
                onClick={handleCloseModal}
                style={{
                  padding: "10px 20px",
                  margin: "10px 5px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#6c757d",
                  color: "white",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PermisosUsuarios;
