import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import Color from "@tiptap/extension-color";
import TableHeader from "@tiptap/extension-table-header";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline"; // Agregar la extensión de subrayado
import FontSize from "./FontSize";
import Nav from "./Nav";
import moment from "moment";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

// Estilos personalizados para el modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    padding: "20px",
    fontFamily: "'Inter', sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 101,
  },
};

Modal.setAppElement("#root");

const Plantilla = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showTableConfig, setShowTableConfig] = useState(false); // Controla el formulario de configuración de tabla
  const [tableConfig, setTableConfig] = useState({
    rows: 2,
    cols: 2,
    width: 100,
    border: 1,
  });
  const { searchTerm, proximasAVencer, proximosEventos } = useNavContext();

  const editor = useEditor({
    extensions: [
      StarterKit,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Link.configure({ openOnClick: false }),
      Table.configure({ resizable: true }),
      TableRow,
      TableCell,
      TableHeader,
      TextStyle,
      Underline, // Añadir la extensión de subrayado
      FontSize,
      Color,
    ],
    content: "<p>Añade tu contenido aquí...</p>",
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const setLink = () => {
    const url = prompt("Introduce el enlace");
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  const showTableForm = () => {
    setShowTableConfig(true);
  };

  const handleTableConfigChange = (event) => {
    const { name, value } = event.target;
    setTableConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const insertTable = () => {
    editor
      .chain()
      .focus()
      .insertTable({
        rows: parseInt(tableConfig.rows),
        cols: parseInt(tableConfig.cols),
        withHeaderRow: true,
      })
      .run();
    setShowTableConfig(false);
  };

  const handleFontSizeChange = (event) => {
    const size = event.target.value;
    editor.chain().focus().setFontSize(size).run();
  };

  const handleColorChange = (event) => {
    const color = event.target.value;
    editor.chain().focus().setMark("textStyle", { color }).run();
  };

  useEffect(() => {
    if (editor) {
      console.log("Marks in schema:", editor.schema.marks);
    }
  }, [editor]);

  return (
    <>
      <Nav
        handleSearch={searchTerm}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div style={{ paddingLeft: "100px", paddingRight: "20px" }}>
        <h2 style={{ textAlign: "left" }}>Otros detalles</h2>
        <p style={{ textAlign: "justify" }}>
          Haga clic en{" "}
          <span style={{ fontWeight: "bold" }}>Añadir contenido anexo</span>{" "}
          para especificar información adicional además de los Términos y
          condiciones.
        </p>

        <div style={{ textAlign: "left" }}>
          <button
            type="button"
            onClick={openModal}
            style={{
              padding: "10px",
              backgroundColor: "#f9f9fb",
              borderRadius: "5px",
              fontWeight: 400,
              border: "1px solid #ebedf6",
              cursor: "pointer",
            }}
          >
            Añadir contenido anexo
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h4>Añadir contenido anexo</h4>

        {/* Barra de herramientas */}
        <div
          style={{
            marginBottom: "10px",
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            title="Negrita"
          >
            <i className="bi bi-type-bold"></i>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            title="Cursiva"
          >
            <i className="bi bi-type-italic"></i>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()} // Ya debería funcionar el subrayado
            title="Subrayar"
          >
            <i className="bi bi-type-underline"></i>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            title="Tachado"
          >
            <i className="bi bi-type-strikethrough"></i>
          </button>

          <input
            type="color"
            onChange={handleColorChange}
            title="Color de texto"
            style={{
              width: "25px",
              border: "none",
              padding: "0",
              marginRight: "12px",
              marginLeft: "-5px",
            }}
          />

          <select
            onChange={handleFontSizeChange}
            title="Tamaño de fuente"
            defaultValue="13px"
          >
            <option value="10px">10px</option>
            <option value="13px">13px</option>
            <option value="16px">16px</option>
            <option value="18px">18px</option>
            <option value="24px">24px</option>
            <option value="32px">32px</option>
            <option value="48px">48px</option>
          </select>

          <button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            title="Alinear a la izquierda"
          >
            <i className="bi bi-text-left"></i>
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            title="Centrar"
          >
            <i className="bi bi-text-center"></i>
          </button>
          <button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            title="Alinear a la derecha"
          >
            <i className="bi bi-text-right"></i>
          </button>
          <button onClick={setLink} title="Insertar enlace">
            <i className="bi bi-link-45deg"></i>
          </button>
          <button onClick={showTableForm} title="Insertar tabla">
            <i className="bi bi-table"></i>
          </button>
        </div>

        {/* Formulario de configuración de tabla */}
        {showTableConfig && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
              gap: "15px",
            }}
          >
            <div>
              <label>Filas:</label>
              <input
                type="number"
                name="rows"
                value={tableConfig.rows}
                onChange={handleTableConfigChange}
                style={{ width: "60px", marginLeft: "5px" }}
              />
            </div>
            <div>
              <label>Columnas:</label>
              <input
                type="number"
                name="cols"
                value={tableConfig.cols}
                onChange={handleTableConfigChange}
                style={{ width: "60px", marginLeft: "5px" }}
              />
            </div>
            <div>
              <label>Ancho:</label>
              <input
                type="number"
                name="width"
                value={tableConfig.width}
                onChange={handleTableConfigChange}
                style={{ width: "60px", marginLeft: "5px" }}
              />
            </div>
            <div>
              <label>Borde:</label>
              <input
                type="number"
                name="border"
                value={tableConfig.border}
                onChange={handleTableConfigChange}
                style={{ width: "60px", marginLeft: "5px" }}
              />
            </div>
            <div style={{ gap: "5px" }}>
              <button
                onClick={insertTable}
                style={{
                  padding: "5px 15px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "3px",
                }}
              >
                OK
              </button>
              <button
                onClick={() => setShowTableConfig(false)}
                style={{
                  padding: "5px 15px",
                  backgroundColor: "gray",
                  color: "white",
                  border: "none",
                  borderRadius: "3px",
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        )}

        {/* Editor */}
        <EditorContent editor={editor} className="custom-editor" />

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <button
            onClick={closeModal}
            style={{
              padding: "10px",
              marginRight: "10px",
              backgroundColor: "gray",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Cancelar
          </button>
          <button
            onClick={closeModal}
            style={{
              padding: "10px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Guardar y ver vista preliminar
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Plantilla;
