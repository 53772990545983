import React, { useState, useEffect, useRef } from "react";
import PreviaRemision from "./PreviaRemision";
import { useLocation } from "react-router-dom";
import ResumenRemision from "./ResumenRemision";
import BandejaRemisiones from "./BandejaRemisiones";
import Nav from "./Nav";
import RemisionForm from "./RemisionForm";
import {
  collection,
  deleteDoc,
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import Paginador from "./Paginador";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

const styleForm = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    width: "100%",
    height: "550px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function TablaRemisiones({
  remisiones,
  clientes,
  setRemisiones,
  guardarRemision,
  modoEdicion,
  fechaRemision,
  remision,
  numeroCotizacion,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [remisionSeleccionada, setRemisionSeleccionada] = useState(null);
  const [loadingRemisiones, setLoadingRemisiones] = useState(true);
  const [selectedRemisiones, setSelectedRemisiones] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState({
    campo: "fechaRemision",
    ascendente: true,
  });
  /*const [showOptions, setShowOptions] = useState(false);*/
  const [selectedRemisionId, setSelectedRemisionId] = useState(null);
  const [resumenVisible, setResumenVisible] = useState(false);
  const [showBandeja, setShowBandeja] = useState(false);
  const location = useLocation();
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const { searchTerm, setSearchTerm, proximasAVencer, proximosEventos } =
    useNavContext();
  const setRemisionesRef = useRef(setRemisiones);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Valor inicial
  const [estadosCeldas, setEstadosCeldas] = useState({});
  const [formDirty, setFormDirty] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const ordenarRemisiones = (remisiones, { campo, ascendente }) => {
    if (!remisiones || remisiones.length === 0) {
      return [];
    }

    const sortedRemisiones = [...remisiones];
    switch (campo) {
      case "fechaRemision":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaRemision) - new Date(b.fechaRemision))
        );
        break;
      case "numeroCotizacion":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) * (a.numeroCotizacion - b.numeroCotizacion)
        );
        break;
      case "asunto":
        sortedRemisiones.sort(
          (a, b) => (ascendente ? 1 : -1) * a.asunto.localeCompare(b.asunto)
        );
        break;
      case "nombreCliente":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            a.nombreCliente.localeCompare(b.nombreCliente)
        );
        break;
      case "total":
        sortedRemisiones.sort(
          (a, b) => (ascendente ? 1 : -1) * (a.total - b.total)
        );
        break;
      case "referencia":
        sortedRemisiones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.referencia) - new Date(b.referencia))
        );
        break;
      default:
        break;
    }
    return sortedRemisiones;
  };

  const filteredRemisiones = React.useMemo(() => {
    if (!remisiones || remisiones.length === 0) return []; // Devuelve un arreglo vacío si no hay datos
    const searchLower = searchTerm.toLowerCase();

    return remisiones.filter((remision) => {
      const searchableFields = [
        remision.fechaRemision,
        remision.numeroRemision?.toString().toLowerCase() || "",
        remision.asunto?.toLowerCase(),
        remision.nombreCliente?.toLowerCase(),
        remision.total?.toString().toLowerCase() || "",
        remision.referencia?.toString(),
        remision.estado?.toLowerCase() || "",
      ];

      return searchableFields.some(
        (field) => field && field.includes(searchLower)
      );
    });
  }, [remisiones, searchTerm]);

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setMostrarFormulario(true);
    }
  }, [location]);

  // Al cargar la página, obtenemos las remisiones y los estados desde Firestore
  useEffect(() => {
    setLoadingRemisiones(true);
    const firestore = getFirestore();
    const remisionesRef = collection(firestore, "remisiones");

    const unsubscribe = onSnapshot(remisionesRef, (snapshot) => {
      const updatedRemisiones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Extraemos los estados de cada remisión desde Firestore
      const estados = {};
      updatedRemisiones.forEach((remision) => {
        estados[remision.id] = remision.estado || "Pendiente"; // Asignar 'Pendiente' si no hay estado
      });
      setEstadosCeldas(estados);

      setRemisionesRef.current(updatedRemisiones);
      setLoadingRemisiones(false);
    });

    return () => unsubscribe();
  }, [setRemisiones]);

  // Función para alternar entre los estados de cada celda seleccionada y guardarlo en Firestore
  const toggleEstado = async (remisionId) => {
    const newEstado =
      estadosCeldas[remisionId] === "Pendiente"
        ? "En Proceso"
        : estadosCeldas[remisionId] === "En Proceso"
        ? "Pagada"
        : "Pendiente";

    // Actualizamos el estado localmente
    setEstadosCeldas((prevEstados) => ({
      ...prevEstados,
      [remisionId]: newEstado,
    }));

    // Guardamos el nuevo estado en Firestore
    const firestore = getFirestore();
    const remisionDocRef = doc(firestore, "remisiones", remisionId);
    await updateDoc(remisionDocRef, { estado: newEstado });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedRemisiones = ordenarRemisiones(filteredRemisiones, ordenamiento);

  const totalOrdenes = filteredRemisiones.length;

  const handleFormDirtyChange = (dirty) => {
    setFormDirty(dirty);
  };

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const openFormulario = () => {
    setMostrarFormulario(true);
    setShowNuevoButton(false); // Oculta el botón + Nuevo al abrir el formulario
  };

  const closeModal = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setMostrarFormulario(false);
          setShowNuevoButton(true); // Vuelve a mostrar el botón + Nuevo al cerrar el formulario
          setFormDirty(false); // Restablece formDirty
        }
      });
    } else {
      setMostrarFormulario(false);
      setShowNuevoButton(true);
    }
  };

  useEffect(() => {
    setRemisionesRef.current = setRemisiones;
  }, [setRemisiones]);

  useEffect(() => {
    setLoadingRemisiones(true);
    const firestore = getFirestore();
    const remisionesRef = collection(firestore, "remisiones");

    const unsubscribe = onSnapshot(remisionesRef, (snapshot) => {
      const updatedRemisiones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRemisionesRef.current(updatedRemisiones);
      setLoadingRemisiones(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las remisiones seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const remisionesEliminadas = [];
        const remisionesRef = collection(firestore, "remisiones");

        for (const remisionId of selectedRemisiones) {
          const remisionDocRef = doc(remisionesRef, remisionId);
          await deleteDoc(remisionDocRef);
          remisionesEliminadas.push(remisionId);
        }

        const remisionesRestantes = remisiones.filter(
          (remision) => !remisionesEliminadas.includes(remision.id)
        );

        setRemisiones(remisionesRestantes);
        setSelectedRemisiones([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "¡Remisión eliminada!",
          text: "Las remisiones seleccionadas han sido eliminadas correctamente.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar remisiones:", error);
    }
  };

  const cerrarModalPrevia = () => {
    setModalIsOpen(false);
    setRemisionSeleccionada(null);
  };

  const handleRowClick = (remisionId) => {
    setSelectedRemisionId(remisionId);
    setResumenVisible(true);
    setShowBandeja(true);
  };

  const handleSelectRemision = (remisionId) => {
    setSelectedRemisiones((prevSelected) => {
      if (prevSelected.includes(remisionId)) {
        return prevSelected.filter((id) => id !== remisionId);
      } else {
        return [...prevSelected, remisionId];
      }
    });
  };

  const handleOrdenamientoChange = (campo) => {
    setOrdenamiento((prevOrdenamiento) => ({
      campo,
      ascendente:
        campo === prevOrdenamiento.campo ? !prevOrdenamiento.ascendente : true,
    }));
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las remisiones
      const allRemisionesIds = filteredRemisiones.map(
        (remision) => remision.id
      );
      setSelectedRemisiones(allRemisionesIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedRemisiones([]);
    }
  };

  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date(); // Usa la fecha actual si dateString no está definida
    const day = date.getDate().toString().padStart(2, "0");

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <>
      {!showBandeja && !resumenVisible && !mostrarFormulario && (
        <>
          <Nav
            handleSearch={setSearchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <div className="task-filters">
            <div className="task-filters-search">
              <i className="bi bi-search search-icon"></i>
              <input
                type="text"
                className="search-input2"
                placeholder="Buscar remisiones"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="task-filters-right">
              <div className="task-filter filter-control">
                <Link href="#" className="filter-link control-link">
                  <i className="bi bi-sliders2-vertical"></i> Filter
                </Link>
              </div>
              <div className="view-buttons-container">
                <button className="view-button grid-view">
                  <i className="bi bi-grid"></i>
                </button>
                <button className="view-button list-view">
                  <i className="bi bi-list"></i>
                </button>
              </div>
              <div>
                {showNuevoButton && (
                  <button className="new-task-button" onClick={openFormulario}>
                    + Nuevo
                  </button>
                )}
              </div>

              <div style={{ marginLeft: "-10px" }}>
                {selectedRemisiones.length > 0 && (
                  <div className="delete-button-container">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={handleDeleteSelected}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="cotizaciones-table">
        {showBandeja && (
          <BandejaRemisiones
            remisiones={remisiones}
            onRowClick={handleRowClick}
            clientes={clientes}
            guardarRemision={guardarRemision}
            remision={remision}
          />
        )}
        {!showBandeja && (
          <div className="tabla-cotizaciones-wrapper">
            <div className="tabla-cotizaciones-content">
              {loadingRemisiones ? (
                <Spinner />
              ) : (
                <>
                  {filteredRemisiones.length > 0 ? (
                    <table>
                      {!mostrarFormulario && (
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                className="checkbox-select-all check"
                                checked={
                                  selectedRemisiones.length ===
                                  filteredRemisiones.length
                                }
                              />
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("fechaRemision")
                              }
                            >
                              Fecha
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("numeroCotizacion")
                              }
                            >
                              No.
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("referencia")
                              }
                            >
                              Referencia
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("nombreCliente")
                              }
                              style={{ width: "20%" }}
                            >
                              Cliente
                            </th>
                            <th
                              onClick={() => handleOrdenamientoChange("estado")}
                            >
                              Estado
                            </th>
                            <th
                              onClick={() => handleOrdenamientoChange("total")}
                            >
                              Importe
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {sortedRemisiones.map((remision, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRemisiones.includes(
                                  remision.id
                                )}
                                onChange={() =>
                                  handleSelectRemision(remision.id)
                                }
                                className="checkbox"
                              />
                            </td>
                            <td onClick={() => handleRowClick(remision.id)}>
                              {formatDate(remision.fechaRemision)}
                            </td>
                            <td
                              style={{ color: "#007bff", fontWeight: "500" }}
                              onClick={() => handleRowClick(remision.id)}
                            >
                              {remision.numeroRemision
                                ? remision.numeroRemision
                                    .toString()
                                    .padStart(4, "0")
                                : "N/A"}
                            </td>
                            <td onClick={() => handleRowClick(remision.id)}>
                              {remision.referencia}
                            </td>
                            <td onClick={() => handleRowClick(remision.id)}>
                              {remision.nombreCliente
                                ? remision.nombreCliente
                                : "Cliente desconocido"}
                            </td>
                            <td>
                              <div
                                className={`status2 ${
                                  estadosCeldas[remision.id] === "Pendiente"
                                    ? "pendiente2"
                                    : estadosCeldas[remision.id] ===
                                      "En Proceso"
                                    ? "en-proceso2"
                                    : estadosCeldas[remision.id] === "Pagada"
                                    ? "pagada2"
                                    : "pendiente2"
                                }`}
                                onClick={() => toggleEstado(remision.id)}
                              >
                                {estadosCeldas[remision.id] || "Pendiente"}
                              </div>
                            </td>
                            <td
                              className="importe"
                              onClick={() => handleRowClick(remision.id)}
                            >
                              {remision.divisa}{" "}
                              {parseFloat(remision.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-resultados">
                      <div
                        className="mensaje-sin-resultados"
                        style={{ marginTop: "30px" }}
                      >
                        <p style={{ fontSize: "14px" }}>
                          No existe ninguna orden de compra que coincida con
                          esta búsqueda.
                        </p>
                      </div>
                      <div className="imagen-sin-resultados">
                        <img
                          src="/img/noSearch.svg"
                          alt="Sin resultados"
                          className="sin-resultados-imagen"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <Modal
                isOpen={mostrarFormulario}
                onRequestClose={closeModal}
                contentLabel="Nueva Cotización"
                style={styleForm}
              >
                <button onClick={closeModal} className="cerrar-button">
                  <i className="bi bi-x-lg"></i>
                </button>
                <RemisionForm
                  clientes={clientes}
                  guardarRemision={guardarRemision}
                  modoEdicion={modoEdicion}
                  remision={remision}
                  onFormDirtyChange={handleFormDirtyChange}
                  closeModal={closeModal}
                />
              </Modal>
            </div>
            <div className="tabla-cotizaciones-footer">
              <div className="paginador-container">
                <Paginador
                  totalItems={totalOrdenes}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {remisionSeleccionada && (
            <PreviaRemision
              remision={remisionSeleccionada}
              numeroCotizacion={remisionSeleccionada.numeroCotizacion}
              clientes={clientes}
              cerrarPrevia={cerrarModalPrevia}
            />
          )}
        </Modal>

        <div
          className={`resumen-container ${selectedRemisionId ? "active" : ""}`}
        >
          <ResumenRemision
            remision={
              remisiones &&
              remisiones.find((remision) => remision.id === selectedRemisionId)
            }
            isOpen={resumenVisible}
            onClose={() => {
              setResumenVisible(false);
              setSelectedRemisionId(null);
              setShowBandeja(false);
            }}
            numeroCotizacion={
              remisionSeleccionada
                ? remisionSeleccionada.numeroCotizacion
                : null
            }
            clientes={clientes}
            mostrarBotonNuevo={false}
          />
        </div>
      </div>
    </>
  );
}

export default TablaRemisiones;
