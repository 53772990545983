// AddProductModal.js

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";

// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "90vh",
    height: "480px", // Altura ajustada
    overflow: "auto",
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 102,
  },
  label: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    fontSize: "14px",
  },
  select: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    fontSize: "14px",
    appearance: "none",
    backgroundColor: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    backgroundSize: "12px",
    cursor: "pointer",
  },
  button: {
    width: "50%",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "20px",
    position: "absolute", // Cambiado a absolute
    bottom: "5px", // Ajusta según sea necesario
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const AddProductModal = ({ isOpen, onClose, onSubmit, editingProduct }) => {
  const [productDetails, setProductDetails] = useState({
    id: "", // Añade el campo id
    numeroDeParteCliente: "",
    numeroDeParteProveedor: "",
    nombre: "",
    costo: "",
    categoria: "",
    imagen: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const firestore = getFirestore();
    const categoriasRef = collection(firestore, "categorias");

    const unsubscribe = onSnapshot(categoriasRef, (snapshot) => {
      const fetchedCategorias = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategorias(fetchedCategorias);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (editingProduct) {
        setProductDetails({
          id: editingProduct.id, // Incluye el id aquí
          numeroDeParteCliente: editingProduct.numeroDeParteCliente || "",
          numeroDeParteProveedor: editingProduct.numeroDeParteProveedor || "",
          nombre: editingProduct.nombre || "",
          costo: editingProduct.costo || "",
          categoria: editingProduct.categoria || "",
          imagen: editingProduct.imagen || null,
        });
        setImagePreviewUrl(editingProduct.imagen || "");
      } else {
        setProductDetails({
          id: "",
          numeroDeParteCliente: "",
          numeroDeParteProveedor: "",
          nombre: "",
          costo: "",
          categoria: "",
          imagen: null,
        });
        setImagePreviewUrl("");
      }
      setErrorMessage("");
    }
  }, [isOpen, editingProduct]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "imagen" && files.length > 0) {
      const imageFile = files[0];
      if (!isValidImageFile(imageFile)) {
        setErrorMessage("Seleccione un archivo de imagen válido.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setProductDetails((prevState) => ({
          ...prevState,
          imagen: reader.result,
        }));
      };
      reader.readAsDataURL(imageFile);
    } else {
      setProductDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    // Validación de campos
    if (
      !productDetails.numeroDeParteCliente.trim() ||
      !productDetails.nombre.trim() ||
      !productDetails.costo
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los campos Descripción, Número de Parte Cliente y el costo son obligatorios.",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (!editingProduct) {
      // Agregar nuevo producto, omitir 'id'
      const { id, ...productData } = productDetails;
      onSubmit(productData);
    } else {
      // Editar producto existente, incluir 'id'
      onSubmit(productDetails);
    }

    onClose();

    Swal.fire({
      icon: "success",
      title: editingProduct ? "¡Producto actualizado!" : "¡Producto agregado!",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const isValidImageFile = (file) => file.type.startsWith("image/");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={editingProduct ? "Editar Producto" : "Agregar Producto"}
      key={editingProduct ? `edit-${editingProduct.id}` : "add-product-modal"} // Clave única para forzar remontaje
    >
      <button
        className="modal-close"
        onClick={onClose}
        aria-label="Close Modal"
      >
        <i className="bi bi-x-lg"></i>
      </button>
      <h2 style={{ textAlign: "left", marginBottom: "20px" }}>
        {editingProduct ? "Editar Producto" : "Agregar Producto"}
      </h2>
      <form
        onSubmit={handleSubmitForm}
        style={{ display: "flex", gap: "20px" }}
      >
        <div style={{ flex: 2 }}>
          <label style={customStyles.label}>Número de Parte Cliente:</label>
          <input
            type="text"
            name="numeroDeParteCliente"
            value={productDetails.numeroDeParteCliente}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del cliente"
          />

          <label style={customStyles.label}>Número de Parte Proveedor:</label>
          <input
            type="text"
            name="numeroDeParteProveedor"
            value={productDetails.numeroDeParteProveedor}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el número de parte del proveedor"
          />

          <label style={customStyles.label}>Nombre:</label>
          <input
            type="text"
            name="nombre"
            value={productDetails.nombre}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa la descripción del producto"
          />

          <label style={customStyles.label}>Costo:</label>
          <input
            type="number"
            name="costo"
            value={productDetails.costo}
            onChange={handleChange}
            style={customStyles.input}
            placeholder="Ingresa el costo del producto"
            min="0"
            step="0.01"
          />

          <label style={customStyles.label}>Categoría:</label>
          <select
            name="categoria"
            value={productDetails.categoria}
            onChange={handleChange}
            style={customStyles.select}
          >
            <option value="">Selecciona una categoría</option>
            {categorias.map((categoria) => (
              <option key={categoria.id} value={categoria.nombre}>
                {categoria.nombre}
              </option>
            ))}
          </select>
        </div>

        <div style={{ flex: 1, textAlign: "center", marginTop: "20px" }}>
          <label
            htmlFor="file-upload"
            style={{
              display: "block",
              border: "2px dashed #ccc",
              borderRadius: "8px",
              padding: "20px",
              width: "280px",
              cursor: "pointer",
              height: "283px",
            }}
          >
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Vista previa"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <>
                <div style={{ marginTop: "50px" }}>
                  <i
                    className="bi bi-card-image"
                    style={{ fontSize: "50px", color: "#ccc" }}
                  ></i>
                  <p style={{ color: "#ccc" }}>Seleccionar Imagen</p>
                </div>
              </>
            )}
          </label>
          <input
            id="file-upload"
            type="file"
            name="imagen"
            onChange={handleChange}
            accept="image/*"
            style={{ display: "none" }}
          />
        </div>

        {errorMessage && (
          <p style={{ color: "red", textAlign: "center", flexBasis: "100%" }}>
            {errorMessage}
          </p>
        )}
        <button type="submit" style={customStyles.button}>
          {editingProduct ? "Guardar Cambios" : "Agregar Producto"}
        </button>
      </form>
    </Modal>
  );
};

export default AddProductModal;