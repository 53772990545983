// RolAdmin.js
import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "bootstrap-icons/font/bootstrap-icons.css";

const RolAdmin = ({ isOpen, toggleSidebar, closeSidebar }) => {
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  // Función para alternar un submenú
  const toggleMenu = useCallback(
    (menu) => {
      if (!isOpen) {
        // 1) Abre el sidebar
        toggleSidebar();
        // 2) Asegura que el submenú esté abierto
        setOpenMenus((prev) => ({ ...prev, [menu]: true }));
      } else {
        // Sidebar ya abierto: alterna normalmente
        setOpenMenus((prev) => ({
          ...prev,
          [menu]: !prev[menu],
        }));
      }
    },
    [isOpen, toggleSidebar]
  );

  useEffect(() => {
    setOpenMenus({});
  }, [location.pathname]);

  // Cierra todos los submenús
  const resetMenus = useCallback(() => {
    setOpenMenus({}); // Resetea todos los menús abiertos
    closeSidebar(); // Cierra el sidebar
  }, [closeSidebar]);

  const isMenuOpen = (menu) => openMenus[menu] || false;

  const handleLogout = async () => {
    await logout();
    localStorage.removeItem("userRole"); // Eliminar el rol de localStorage al cerrar sesión
    navigate("/login");
  };

  // Función personalizada para manejar el clic en los enlaces
  const handleLinkClick = (e, path) => {
    if (location.pathname !== path) {
      // Solo cierra el sidebar si la ruta cambia
      closeSidebar();
    }
    // Si ya estás en la misma ruta, no haces nada
  };

  // Función personalizada para manejar el clic en los iconos de plus
  const handlePlusIconClick = (e, path) => {
    e.preventDefault(); // Evita la navegación predeterminada del enlace
    e.stopPropagation(); // Previene que el evento burbujee al <Link>
    navigate(path, { state: { openModal: true } });
    closeSidebar(); // Cierra el Sidebar
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
      <div className="logo-container-sidebar">
          <Link to="/">
            <img
              className={`logo ${isOpen ? "open" : "closed"}`}
              src="/img/logo-iciamex.png"
              alt="ICIAMEX logo"
            />
          </Link>
        </div>

        <Link to="/">
          <img className="menu-icon" src="/img/ic.svg" alt="Menu Icon" />
        </Link>
        {isOpen && (
          <div className="menu-toggle-btn" onClick={resetMenus}>
            <i className="bi bi-chevron-left"></i>
          </div>
        )}
        {!isOpen && (
          <div className="sidebar-open-btn" onClick={toggleSidebar}>
            <i className="bi bi-chevron-right"></i>
          </div>
        )}
      </div>

      <div className="sidebar-content">
        <nav id="primary-menu" className="navbar">
          <ul className="nav navbar-nav">
            {isOpen && <span className="main-text">Principal</span>}
            <li
              className={`sidebar-item ${
                location.pathname === "/" ? "border-bottom-active" : ""
              }`}
              data-tooltip="Inicio"
            >
              <Link
                className={location.pathname === "/" ? "active" : ""}
                to="/"
                onClick={() => handleLinkClick("/")}
              >
                <span className="icon">
                  <i className="bi bi-house"></i>
                </span>
                <span className="text">Inicio</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${
                location.pathname === "/tareas" ? "border-bottom-active" : ""
              }`}
              data-tooltip="Tareas"
            >
              <Link
                className={location.pathname === "/tareas" ? "active" : ""}
                to="/tareas"
                onClick={() => handleLinkClick("/tareas")}
              >
                <span className="icon">
                  <i className="bi bi-check2-square"></i>
                </span>
                <span className="text">Tareas</span>
              </Link>
            </li>
            <li
              className={`sidebar-item ${
                location.pathname === "/calendario"
                  ? "border-bottom-active"
                  : ""
              }`}
              data-tooltip="Calendario"
            >
              <Link
                className={location.pathname === "/calendario" ? "active" : ""}
                to="/calendario"
                onClick={() => handleLinkClick("/calendario")}
              >
                <span className="icon">
                  <i className="bi bi-calendar2-day"></i>
                </span>
                <span className="text">Calendario</span>
              </Link>
            </li>

            <br />

            {isOpen && <span className="main-text">Utilidades</span>}
            <li
              data-tooltip="Clientes"
              className={isMenuOpen("Clientes") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Clientes") ? "border-bottom-active" : ""
                }`}
                to="#"
                onClick={() => toggleMenu("Clientes")}
                data-tooltip="Clientes"
              >
                <span className="icon">
                  <i className="bi bi-graph-up-arrow"></i>
                </span>
                <span className="text">Clientes</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Clientes") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Clientes") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Clientes") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Clientes") ? "open" : "closed"
                  }`}
                  data-tooltip="Clientes"
                >
                  <li>
                    <Link
                      className={`text ${
                        location.pathname === "/cotizacion" ? "active" : ""
                      }`}
                      to="/cotizacion"
                      onClick={(e) => handleLinkClick(e, "/cotizacion")}
                      aria-label="Cotizaciones"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Cotizaciones</span>
                      <span
                        onClick={(e) => handlePlusIconClick(e, "/cotizacion")}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-plus-circle"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`text ${
                        location.pathname === "/remisiones" ? "active" : ""
                      }`}
                      to="/remisiones"
                      onClick={(e) => handleLinkClick(e, "/remisiones")}
                      aria-label="Remisiones"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Remisiones</span>
                      <span
                        onClick={(e) => handlePlusIconClick(e, "/remisiones")}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-plus-circle"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text"
                      to="/pedidos"
                      onClick={(e) => handleLinkClick(e, "/pedidos")}
                    >
                      <span
                        onClick={(e) => handleLinkClick(e, "/pedidos")}
                      >
                        Pedidos
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Pagos Recibidos</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Agregar otros menús específicos de Administrador */}
            <li
              data-tooltip="Proveedores"
              className={isMenuOpen("Proveedores") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Proveedores") ? "border-bottom-active" : ""
                }`}
                onClick={() => toggleMenu("Proveedores")}
                data-tooltip="Proveedores"
              >
                <span className="icon">
                  <i className="bi bi-currency-dollar"></i>
                </span>
                <span className="text">Proveedores</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Proveedores") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Proveedores") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Proveedores") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Proveedores") ? "open" : "closed"
                  }`}
                >
                  <li>
                    <Link className="text">
                      <span>Facturas de Proveedores</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Pagos</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Notas de Débito</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`text ${
                        location.pathname === "/orden" ? "active" : ""
                      }`}
                      to="/orden"
                      onClick={(e) => handleLinkClick(e, "/orden")}
                      aria-label="Órdenes de Compra"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Órdenes de Compra</span>
                      <span
                        onClick={(e) => handlePlusIconClick(e, "/orden")}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="bi bi-plus-circle"></i>
                      </span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li
              data-tooltip="Inventario"
              className={isMenuOpen("Inventario") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Inventario") ? "border-bottom-active" : ""
                }`}
                onClick={() => toggleMenu("Inventario")}
                data-tooltip="Inventario"
              >
                <span className="icon">
                  <i className="bi bi-box"></i>
                </span>
                <span className="text">Inventario</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Inventario") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Inventario") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Inventario") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Inventario") ? "open" : "closed"
                  }`}
                >
                  <li>
                    <Link
                      className="text"
                      to="/articulos-venta"
                      onClick={(e) => handleLinkClick(e, "/articulos-venta")}
                    >
                      <span>Productos de Venta</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text"
                      to="/articulos-compra"
                      onClick={(e) => handleLinkClick(e, "/articulos-compra")}
                    >
                      <span
                        onClick={(e) => handleLinkClick(e, "/articulos-compra")}
                      >
                        Productos de Compra
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Ajustes de Inventario</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                     className="text"
                    >
                      <span>Gestión de Productos</span>
                    </Link>
                  </li>
                  <li>
                    <Link 
                      className="text"
                      to="/categorias"
                      onClick={(e) => handleLinkClick(e, "/categorias")}
                    >
                      <span>Categorías</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Almacenes</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li
              data-tooltip="Reportes"
              className={isMenuOpen("Reportes") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Reportes") ? "border-bottom-active" : ""
                }`}
                data-tooltip="Reportes"
                onClick={() => toggleMenu("Reportes")}
              >
                <span className="icon">
                  <i className="bi bi-file-earmark-bar-graph"></i>
                </span>
                <span className="text">Reportes</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Reportes") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Reportes") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Reportes") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Reportes") ? "open" : "closed"
                  }`}
                >
                  <li>
                    <Link className="text">
                      <span>Opción 1</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="text">
                      <span>Opción 2</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <div
              style={{
                position: "relative",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderBottom: isOpen ? "1px solid #4C5165" : "none",
                  width: isOpen ? "85%" : "auto",
                }}
              ></div>
            </div>

            <br />

            {isOpen && <span className="main-text">Registros</span>}
            <li
              data-tooltip="Contactos"
              className={isMenuOpen("Contactos") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Contactos") ? "border-bottom-active" : ""
                }`}
                data-tooltip="Contactos"
                to="#"
                onClick={() => toggleMenu("Contactos")}
              >
                <span className="icon">
                  <i className="bi bi-telephone"></i>
                </span>
                <span className="text">Contactos</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Contactos") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Contactos") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Contactos") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Contactos") ? "open" : "closed"
                  }`}
                >
                  <li>
                    <Link
                      className="text"
                      to="/todos"
                      onClick={(e) => handleLinkClick(e, "/todos")}
                    >
                      <span onClick={(e) => handleLinkClick(e, "/todos")}>
                        Todos
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text"
                      to="/clientes"
                      onClick={(e) => handleLinkClick(e, "/clientes")}
                    >
                      <span onClick={(e) => handleLinkClick(e, "/clientes")}>
                        Clientes
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text"
                      to="/proveedores"
                      onClick={(e) => handleLinkClick(e, "/proveedores")}
                    >
                      <span onClick={(e) => handleLinkClick(e, "/proveedores")}>
                        Proveedores
                      </span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <div
              style={{
                position: "relative",
                width: "100%",
                textAlign: "center", // Centra horizontalmente el contenido
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderBottom: isOpen ? "1px solid #4C5165" : "none",
                  width: isOpen ? "85%" : "auto",
                }}
              ></div>
            </div>

            <br />

            {isOpen && <span className="main-text">Soporte</span>}
            <li
              data-tooltip="Configuración"
              className={isMenuOpen("Configuracion") ? "open" : ""}
            >
              <Link
                className={`sidebar-item ${
                  isMenuOpen("Configuracion") ? "border-bottom-active" : ""
                }`}
                data-tooltip="Configuración"
                to="#"
                onClick={() => toggleMenu("Configuracion")}
              >
                <span className="icon">
                  <i className="bi bi-gear"></i>
                </span>
                <span className="text">Configuración</span>
                <div className="dropdown-arrow-container">
                  <span
                    className={`dropdown-arrow ${
                      isOpen && isMenuOpen("Configuracion") ? "active" : ""
                    }`}
                  >
                    {isOpen ? (
                      isMenuOpen("Configuracion") ? (
                        <i className="bi bi-chevron-up"></i>
                      ) : (
                        <i className="bi bi-chevron-right"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Link>
              {isMenuOpen("Configuracion") && (
                <ul
                  className={`sub-menu ${
                    isMenuOpen("Configuracion") ? "open" : "closed"
                  }`}
                >
                  <li>
                    <Link
                      className="text"
                      to="/usuarios"
                      onClick={(e) => handleLinkClick(e, "/usuarios")}
                    >
                      <span onClick={(e) => handleLinkClick(e, "/usuarios")}>
                        Usuarios
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text"
                      to="/plantillas"
                      onClick={(e) => handleLinkClick(e, "/plantillas")}
                    >
                      <span onClick={(e) => handleLinkClick(e, "/plantillas")}>
                        Plantillas
                      </span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </nav>

        {isOpen && (
          <div
            style={{
              marginTop: "20px",
              bottom: "20px", // Espacio desde el fondo del sidebar
              width: "100%", // Asegura que el botón esté centrado en el sidebar
              display: "flex",
              justifyContent: "center",
              transition: "all 0.3s ease-in-out", // Transición suave para los cambios
            }}
          >
            <button
              onClick={handleLogout}
              style={{
                padding: isOpen ? "15px 20px" : "10px", // Ajusta el tamaño dinámicamente
                display: "flex",
                flexDirection: "row", // Asegura que el ícono y el texto estén en fila
                alignItems: "center", // Centra verticalmente el contenido
                justifyContent: "center", // Centra horizontalmente ícono y texto
                backgroundColor: "#4C5165",
                color: "#e0e0e0",
                borderRadius: "8px",
                fontSize: isOpen ? "14px" : "12px", // Tamaño de fuente dinámico
                width: isOpen ? "80%" : "40px", // Ancho dinámico
                height: isOpen ? "auto" : "40px", // Altura para modo cerrado
                margin: "0 auto",
                cursor: "pointer",
                border: "none",
                transition: "all 0.3s ease-in-out", // Transición suave
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#626879")} // Efecto hover
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#4C5165")}
            >
              <i
                style={{
                  marginRight: isOpen ? "10px" : "0", // Espaciado dinámico del ícono
                  fontSize: "16px",
                  transition: "margin 0.3s ease-in-out", // Transición suave para el ícono
                }}
                className="bi bi-box-arrow-in-right"
              ></i>
              {isOpen && <span>Cerrar Sesión</span>}
            </button>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default RolAdmin;
