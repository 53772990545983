import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import {
  collection,
  getFirestore,
  onSnapshot,
  doc,
  deleteDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Paginador from "./Paginador";
import CategoriaModal from "./CategoriaModal";
import Spinner from "./Spinner";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

const Categorias = () => {
  const navigate = useNavigate();
  const { searchTerm, setSearchTerm, proximasAVencer, proximosEventos } =
    useNavContext();
  const [selectedCategorias, setSelectedCategorias] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Valor inicial
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriaEditando, setCategoriaEditando] = useState(null);

  const handleEditarCategoria = (categoria) => {
    setCategoriaEditando(categoria); // Establece la categoría que se está editando
    setIsModalOpen(true); // Abre el modal
  };

  const handleVerDetalles = (categoriaNombre) => {
    navigate(`/categorias/${categoriaNombre}/productos`); // Navegar con el nombre de la categoría
  };

  const handleOpenModal = () => {
    setCategoriaEditando(null); // Asegúrate de que no hay categoría editando
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmitCategoria = async (newCategoria) => {
    if (newCategoria.id) {
      // **Editar una categoría existente**

      // Actualizar el estado local de manera optimista
      setCategorias((prevCategorias) =>
        prevCategorias.map((cat) =>
          cat.id === newCategoria.id
            ? {
                ...cat,
                nombre: newCategoria.nombre,
                descripcion: newCategoria.descripcion,
              }
            : cat
        )
      );

      // Cerrar el modal inmediatamente
      handleCloseModal();

      try {
        const firestore = getFirestore();
        const categoriaDocRef = doc(firestore, "categorias", newCategoria.id);
        let imageUrl = newCategoria.imagenUrl || "";

        // Subir la imagen si se ha seleccionado una nueva
        if (newCategoria.imagen && typeof newCategoria.imagen !== "string") {
          const storage = getStorage();
          const imageRef = ref(
            storage,
            `categorias/${newCategoria.imagen.name}`
          );
          const snapshot = await uploadBytes(imageRef, newCategoria.imagen);
          imageUrl = await getDownloadURL(snapshot.ref);
        }

        // Actualizar la categoría en Firestore
        await updateDoc(categoriaDocRef, {
          nombre: newCategoria.nombre,
          descripcion: newCategoria.descripcion || "",
          imagenUrl: imageUrl || "",
        });

        // Actualizar el estado local con la URL de la imagen
        setCategorias((prevCategorias) =>
          prevCategorias.map((cat) =>
            cat.id === newCategoria.id ? { ...cat, imagenUrl: imageUrl } : cat
          )
        );

        Swal.fire({
          title: "¡Categoría actualizada!",
          text: "Los cambios se guardaron correctamente.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } catch (error) {
        console.error("Error al actualizar la categoría:", error);
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al actualizar la categoría. Inténtalo de nuevo.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      // Asignar un ID temporal
      const tempId = `temp-${Date.now()}`;
      const tempCategoria = {
        id: tempId,
        nombre: newCategoria.nombre,
        descripcion: newCategoria.descripcion || "",
        imagenUrl: "", // Puedes asignar una URL de imagen temporal si lo deseas
      };

      // Actualizar el estado local de manera optimista
      setCategorias((prevCategorias) => [...prevCategorias, tempCategoria]);

      // Cerrar el modal inmediatamente
      handleCloseModal();

      try {
        const firestore = getFirestore();
        const categoriaRef = collection(firestore, "categorias");
        let imageUrl = "";

        // Subir la imagen si se ha seleccionado una nueva
        if (newCategoria.imagen && typeof newCategoria.imagen !== "string") {
          const storage = getStorage();
          const imageRef = ref(
            storage,
            `categorias/${newCategoria.imagen.name}`
          );
          const snapshot = await uploadBytes(imageRef, newCategoria.imagen);
          imageUrl = await getDownloadURL(snapshot.ref);
        }

        // Agregar la nueva categoría a Firestore
        const docRef = await addDoc(categoriaRef, {
          nombre: newCategoria.nombre,
          descripcion: newCategoria.descripcion || "",
          imagenUrl: imageUrl || "",
        });

        // Reemplazar la categoría temporal con la real en el estado local
        setCategorias((prevCategorias) =>
          prevCategorias.map((cat) =>
            cat.id === tempId
              ? { ...cat, id: docRef.id, imagenUrl: imageUrl }
              : cat
          )
        );

        Swal.fire({
          title: "¡Categoría agregada!",
          text: "La categoría se agregó correctamente.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } catch (error) {
        console.error("Error al agregar la categoría:", error);
        Swal.fire({
          title: "Error",
          text: "Hubo un problema al agregar la categoría. Inténtalo de nuevo.",
          icon: "error",
          confirmButtonText: "Ok",
        });

        // Remover la categoría temporal del estado local si ocurre un error
        setCategorias((prevCategorias) =>
          prevCategorias.filter((cat) => cat.id !== tempId)
        );
      }
    }
  };

  useEffect(() => {
    const fetchCategorias = () => {
      setLoading(true); // Establecer loading a true antes de comenzar la carga

      const firestore = getFirestore();
      const categoriasRef = collection(firestore, "categorias");

      const unsubscribe = onSnapshot(categoriasRef, (snapshot) => {
        const fetchedCategorias = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategorias(fetchedCategorias); // Actualizar el estado local con las categorías
        setLoading(false); // Indicar que los datos ya están listos
      });

      return unsubscribe;
    };

    const unsubscribe = fetchCategorias();
    return () => unsubscribe(); // Cleanup de la suscripción
  }, []);

  const handleSelectCategoria = (categoriaId) => {
    setSelectedCategorias((prevSelected) => {
      if (prevSelected.includes(categoriaId)) {
        return prevSelected.filter((id) => id !== categoriaId);
      } else {
        return [...prevSelected, categoriaId];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las cotizaciones
      const allCategoriasIds = filteredCategorias.map(
        (categoria) => categoria.id
      );
      setSelectedCategorias(allCategoriasIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedCategorias([]);
    }
  };

  const filteredCategorias = React.useMemo(() => {
    if (!categorias || categorias.length === 0) return [];
    const searchLower = searchTerm.toLowerCase();
    return categorias.filter((categoria) => {
      const searchableFields = [
        categoria.nombre?.toLowerCase() || "",
        categoria.descripcion?.toLowerCase() || "",
      ];
      return searchableFields.some((field) => field.includes(searchLower));
    });
  }, [categorias, searchTerm]);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las categorías seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const categoriasEliminadas = [];
        const categoriasRef = collection(firestore, "categorias");

        for (const categoriaId of selectedCategorias) {
          const categoriaDocRef = doc(categoriasRef, categoriaId);
          await deleteDoc(categoriaDocRef);
          categoriasEliminadas.push(categoriaId);
        }

        const categoriasRestantes = categorias.filter(
          (categoria) => !categoriasEliminadas.includes(categoria.id)
        );
        setCategorias(categoriasRestantes);
        setSelectedCategorias([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "Categoría eliminada!",
          text: "Las categorías seleccionadas han sido eliminadas correctamente.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar categoría:", error);
      Swal.fire({
        title: "Error",
        text: "Hubo un problema al eliminar las categorías. Inténtalo de nuevo.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const totalOrdenes = filteredCategorias.length;

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Nav
        handleSearch={setSearchTerm}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el término de búsqueda
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <Link to="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </Link>
          </div>
          <div className="view-buttons-container">
            <button className="view-button grid-view">
              <i className="bi bi-grid"></i>
            </button>
            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>
          <div>
            <button className="new-task-button" onClick={handleOpenModal}>
              + Nueva Categoría
            </button>
          </div>

          <CategoriaModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmitCategoria}
            categoria={categoriaEditando}
          />

          <div style={{ marginLeft: "-10px" }}>
            {selectedCategorias.length > 0 && (
              <div className="delete-button-container">
                <button
                  type="button"
                  className="delete-button"
                  onClick={handleDeleteSelected}
                >
                  Eliminar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="cotizaciones-table">
        <div className="tabla-cotizaciones-wrapper">
          <div className="tabla-cotizaciones-content">
            {loading ? ( // Mostrar spinner mientras los datos se cargan
              <Spinner />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        className="checkbox-select-all check"
                      />
                    </th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCategorias.map((categoria) => (
                    <tr key={categoria.id}>
                      <td className="check">
                        <input
                          type="checkbox"
                          checked={selectedCategorias.includes(categoria.id)}
                          onChange={() => handleSelectCategoria(categoria.id)}
                          className="checkbox"
                        />
                      </td>
                      <td>{categoria.nombre}</td>
                      <td>{categoria.descripcion}</td>
                      <td className="acciones-container">
                        <div className="acciones-icons">
                          <div
                            className="icon-wrapper"
                            onClick={() => handleVerDetalles(categoria.nombre)}
                          >
                            <i className="bi bi-eye"></i>
                            <span className="tooltipCategorias">
                              Mostrar Detalles
                            </span>
                          </div>
                          <div
                            className="icon-wrapper"
                            onClick={() => handleEditarCategoria(categoria)}
                          >
                            <i className="bi bi-pencil"></i>
                            <span className="tooltipCategorias">Editar</span>
                          </div>
                          <div className="icon-wrapper">
                            <i className="bi bi-shield-slash"></i>
                            <span className="tooltipCategorias">
                              Desactivar
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="tabla-cotizaciones-footer">
            <div className="paginador-container">
              <Paginador
                totalItems={totalOrdenes}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categorias;
