import React, { useState, useEffect, useMemo } from "react";
import {
  pdf, // Importamos pdf para generar el blob
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import EditarOrdenForm from "./EditarOrdenForm";
import OpenRegular from "../fonts/OpenSans-Regular.ttf"; // Ruta de la fuente
import OpenBold from "../fonts/OpenSans-Bold.ttf"; // Ruta de la fuente
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { ref, deleteObject } from "firebase/storage";
import Modal from "react-modal";
import Swal from "sweetalert2";

Font.register({
  family: "Open",
  fonts: [
    { src: OpenRegular, fontWeight: "normal" },
    { src: OpenBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 30,
    fontSize: 10,
    fontFamily: "Open",
  },
  pdfContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pdfViewer: {
    width: "80%",
    height: "90%",
    border: "1px solid #ccc",
    borderRadius: 5,
    overflow: "hidden",
  },
  closeButton: {
    color: "#fff",
    top: "15px",
    fontSize: "20px",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  logoContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  logo: {
    width: 120,
    marginBottom: 5,
  },
  companyTitle: {
    fontSize: 14,
    color: "#1976d2",
    fontWeight: "bold",
  },
  companySubtitle: {
    fontSize: 9,
    color: "#333",
  },
  orderTitleContainer: {
    alignItems: "flex-end",
  },
  orderTitle: {
    fontSize: 14,
    color: "#3579CB",
    fontWeight: "bold",
    letterSpacing: "1px",
  },
  orderNumber: {
    fontSize: 11,
    backgroundColor: "#FF0000",
    color: "#fff",
    fontWeight: "bold",
    paddingHorizontal: 8,
    paddingVertical: 2,
    marginTop: 2,
    letterSpacing: "0.5px",
  },
  infoSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  infoLeft: {
    width: "45%",
  },
  infoRight: {
    width: "45%",
    alignItems: "flex-end",
  },
  infoTitle: {
    fontWeight: "bold",
    fontSize: 10,
    marginBottom: 10,
    textDecoration: "underline",
  },
  infoText: {
    fontSize: 9,
    marginBottom: 2,
  },
  proveedorNombre: {
    fontSize: 9,
    marginBottom: 2,
    fontWeight: "bold",
  },
  textoCondiciones: {
    fontSize: 9,
    marginBottom: 10,
    fontWeight: "bold",
  },
  infoTextRazon: {
    fontSize: 9,
    marginBottom: 2,
    fontWeight: "bold",
  },
  totalBox: {
    backgroundColor: "#3579CB",
    paddingHorizontal: 8,
    paddingVertical: 7,
    marginTop: 6,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalBoxText: {
    color: "#fff",
    fontWeight: "bold",
    letterSpacing: "0.5px",
  },
  labelTotal: {
    color: "#fff",
    fontWeight: "bold",
    textAlign: "right",
    width: "45%",
  },
  dataSection: {
    flexDirection: "row",
    marginBottom: 10,
    gap: 2,
  },
  dataBox: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#eaeaea",
    backgroundColor: "#eee",
    padding: 8,
    justifyContent: "center",
  },
  dataBoxTitle: {
    fontSize: 8,
    color: "#555",
    fontWeight: "semibold",
    textAlign: "center",
    letterSpacing: "0.1px",
  },
  dataBoxValue: {
    fontSize: 8,
    fontWeight: 600,
    marginTop: 2,
    textTransform: "uppercase",
    textAlign: "center",
    letterSpacing: "0.1px",
  },
  tableContainer: {
    marginTop: 10,
  },
  tableHeaderRow: {
    flexDirection: "row",
    backgroundColor: "#555",
  },
  tableHeaderCell: {
    paddingVertical: 6,
    paddingHorizontal: 4,
    fontSize: 9,
    backgroundColor: "#555",
    color: "#fff",
    letterSpacing: "0.2px",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    fontSize: 9,
    justifyContent: "center",
  },
  footerSection: {
    marginTop: 15,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  conditions: {
    width: "60%",
    fontSize: 9,
    lineHeight: 1.3,
  },
  totals: {
    width: "35%",
    fontSize: 9,
    minHeight: 100,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 2,
  },
  totalLabel: {
    fontSize: 9,
    textAlign: "right",
    width: "45%",
    fontWeight: "bold",
  },
  totalValue: {
    fontSize: 9,
    paddingRight: 8,
  },
  pageFooter: {
    position: "absolute",
    bottom: 10,
    left: 30,
    right: 30,
    fontSize: 8,
    color: "#999999",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  numeroDePagina: {
    color: "#999999",
    fontSize: 9,
  },
});

const ResumenOrden = ({
  orden,
  isOpen,
  onClose,
  proveedores,
  setOrdenes,
  ordenes,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [showAdjuntosModal, setShowAdjuntosModal] = useState(false);
  const [adjuntos, setAdjuntos] = useState([]);

  useEffect(() => {
    if (showAdjuntosModal && orden.archivos) {
      setAdjuntos(orden.archivos);
    }
  }, [orden, showAdjuntosModal]);

  const handleDeleteArchivo = async (archivo) => {
    // Mostrar mensaje de confirmación
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: `Estás a punto de eliminar el archivo "${archivo.nombre}". Esta acción no se puede deshacer.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    });

    // Si el usuario confirma, proceder con la eliminación
    if (result.isConfirmed) {
      try {
        // 1. Eliminar el archivo de Firebase Storage
        const fileRef = ref(storage, archivo.url); // Suponiendo que 'url' es el path en Storage
        await deleteObject(fileRef);

        // 2. Actualizar la cotización en Firestore para eliminar el archivo
        const ordenRef = doc(db, "ordenes", orden.id);
        await updateDoc(ordenRef, {
          archivos: orden.archivos.filter((a) => a.url !== archivo.url),
        });

        // 3. Actualizar el estado local de adjuntos
        setAdjuntos((prevAdjuntos) =>
          prevAdjuntos.filter((a) => a.url !== archivo.url)
        );

        // 4. Actualizar la lista de órdenes si es necesario
        if (typeof setOrdenes === "function") {
          setOrdenes((prevOrdenes) =>
            prevOrdenes.map((c) =>
              c.id === orden.id
                ? {
                    ...c,
                    archivos: c.archivos.filter((a) => a.url !== archivo.url),
                  }
                : c
            )
          );
        }

        // Mostrar una notificación de éxito
        Swal.fire({
          icon: "success",
          title: "Archivo eliminado",
          text: `El archivo "${archivo.nombre}" ha sido eliminado correctamente.`,
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error("Error al eliminar el archivo:", error);
        Swal.fire({
          icon: "error",
          title: "Error al eliminar",
          text: "Hubo un problema al eliminar el archivo. Por favor, intenta nuevamente.",
        });
      }
    }
  };

  const obtenerIconoArchivo = (nombreArchivo) => {
    const extension = nombreArchivo.split(".").pop().toLowerCase();
    switch (extension) {
      case "xls":
      case "xlsx":
      case "csv":
        return { icon: "bi-file-earmark-excel", color: "green" };
      case "doc":
      case "docx":
        return { icon: "bi-file-earmark-word", color: "blue" };
      case "pdf":
        return { icon: "bi-file-earmark-pdf", color: "red" };
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
        return { icon: "bi-image", color: "blue" };
      default:
        return { icon: "bi-file-earmark", color: "gray" }; // Ícono genérico
    }
  };

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  const handlePrint = () => {
    setPdfVisible(true);
  };

  const handleClosePDF = () => {
    setPdfVisible(false);
  };

  const memoizedPdfDocument = useMemo(() => {
    if (!orden || !orden.productosSeleccionados.length) {
      return null;
    }

    // Buscar el proveedor
    const proveedorSeleccionado = Array.isArray(proveedores)
      ? proveedores.find((p) => p.id === orden.proveedor) || {}
      : {};

    const {
      empresa = "",
      rfc = "",
      domicilio = "",
      numeroExt = "",
      numeroInt = "",
      colonia = "",
      ciudad = "",
      estado = "",
      codigoPostal = "",
    } = proveedorSeleccionado;

    // Particionamos los productos por páginas
    const productosPorPagina = 25;
    const paginasDeProductos = [];

    for (
      let i = 0;
      i < orden.productosSeleccionados.length;
      i += productosPorPagina
    ) {
      paginasDeProductos.push(
        orden.productosSeleccionados.slice(i, i + productosPorPagina)
      );
    }

    // Cálculos de totales
    const subtotal = orden.productosSeleccionados.reduce(
      (acumulador, producto) => {
        const precioBruto = (producto.tarifa || 0) * (producto.cantidad || 0);
        let descuentoProducto = 0;
        if (producto.tipoDescuento === "%") {
          descuentoProducto = precioBruto * ((producto.descuento || 0) / 100);
        } else if (producto.tipoDescuento === "$") {
          descuentoProducto = producto.descuento || 0;
        }
        return acumulador + (precioBruto - descuentoProducto);
      },
      0
    );

    const descuentoPorcentaje = orden.descuento || 0;
    const descuentoAplicado = subtotal * (descuentoPorcentaje / 100);
    const subtotalConDescuento = subtotal - descuentoAplicado;
    const ivaRate = 0.16;
    const ivaTotal = subtotalConDescuento * ivaRate;
    const total = subtotalConDescuento + ivaTotal;

    function formatDate(dateString) {
      const date = dateString ? new Date(dateString) : new Date();
      const day = date.getDate().toString().padStart(2, "0");
      const months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    }

    return (
      <Document>
        {paginasDeProductos.map((productosPagina, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page}>
            {/* Encabezado */}
            <View style={styles.headerContainer}>
              <View style={styles.logoContainer}>
                <Image src="/img/logo-iciamex2.png" style={styles.logo} />
              </View>
              <View style={styles.orderTitleContainer}>
                <Text style={styles.orderTitle}>ORDEN DE COMPRA</Text>
                <Text style={styles.orderNumber}>
                  #{orden.numeroOrden?.toString().padStart(4, "0")}
                </Text>
              </View>
            </View>

            <View style={{ marginBottom: 10 }}>
              <View
                style={{
                  borderTopWidth: 2,
                  borderTopColor: "#3579CB",
                  borderColor: "#000",
                  position: "relative",
                  borderWidth: "0.4px",
                  backgroundColor: "#3579CB",
                }}
              />
              <View
                style={{
                  position: "absolute",
                  top: -3,
                  left: 0,
                  right: 0,
                  height: 3,
                  backgroundColor: "#3579CB",
                  width: "25%",
                }}
              />
            </View>

            {/* Proveedor y Facturación */}
            <View style={styles.infoSection}>
              <View style={styles.infoLeft}>
                <Text style={styles.infoTitle}>PROVEEDOR:</Text>
                <Text style={styles.proveedorNombre}>{empresa}</Text>
                <Text style={styles.infoText}>
                  {domicilio} {numeroExt}
                  {numeroInt ? ` Int. ${numeroInt}` : ""} {colonia} C.P.{" "}
                  {codigoPostal}
                </Text>
                <Text style={styles.infoText}>
                  {ciudad}, {estado}, México.
                </Text>
                <Text style={styles.infoText}>RFC: {rfc}</Text>
              </View>
              <View style={styles.infoRight}>
                <Text style={styles.infoTitle}>FACTURACIÓN Y ENTREGA:</Text>
                <Text style={styles.infoTextRazon}>
                  UNIFICACION PARA LA AUTOMATIZACION
                </Text>
                <Text style={styles.infoText}>
                  LIC. RAMIRO HERNÁNDEZ 448, COL. LAS PINTITAS,
                </Text>
                <Text style={styles.infoText}>EL SALTO, JAL. C.P. 45693</Text>
                <Text style={styles.infoText}>RFC: UAU151222IT1</Text>
              </View>
            </View>

            {/* Datos Principales */}
            <View style={styles.dataSection}>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>FECHA:</Text>
                <Text style={styles.dataBoxValue}>
                  {formatDate(orden.fechaOrden)}
                </Text>
              </View>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>COTIZACIÓN:</Text>
                <Text style={styles.dataBoxValue}>
                  {orden.cotizacion || "N/A"}
                </Text>
              </View>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>INCOTERM:</Text>
                <Text style={styles.dataBoxValue}>
                  {orden.incoterm || "EXW"}
                </Text>
              </View>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>DIVISA:</Text>
                <Text style={styles.dataBoxValue}>{orden.divisa}</Text>
              </View>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>COND. PAGO:</Text>
                <Text style={styles.dataBoxValue}>{orden.condicion}</Text>
              </View>
              <View style={styles.dataBox}>
                <Text style={styles.dataBoxTitle}>COMPRADOR:</Text>
                <Text style={styles.dataBoxValue}>{orden.comprador}</Text>
              </View>
            </View>

            {/* Tabla de Productos */}
            <View style={styles.tableContainer}>
              <View style={styles.tableHeaderRow}>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 0.5, textAlign: "center" },
                  ]}
                >
                  PDA
                </Text>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 2, textAlign: "center" },
                  ]}
                >
                  NO. PARTE
                </Text>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 5, textAlign: "center" },
                  ]}
                >
                  DESCRIPCIÓN
                </Text>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 1, textAlign: "center" },
                  ]}
                >
                  CANTIDAD
                </Text>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 1, textAlign: "center" },
                  ]}
                >
                  PRECIO
                </Text>
                <Text
                  style={[
                    styles.tableHeaderCell,
                    { flex: 1, textAlign: "center" },
                  ]}
                >
                  SUBTOTAL
                </Text>
              </View>

              {productosPagina.map((producto, index) => (
                <View
                  key={index}
                  style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 === 0 ? "#fff" : "#EFF2F2" },
                  ]}
                >
                  <Text
                    style={[
                      styles.tableCell,
                      { flex: 0.5, textAlign: "center" },
                    ]}
                  >
                    {index + 1 + pageIndex * productosPorPagina}
                  </Text>
                  <Text
                    style={[styles.tableCell, { flex: 2, textAlign: "center" }]}
                  >
                    {producto.modelo || producto.numeroDeParteCliente}
                  </Text>
                  <Text
                    style={[styles.tableCell, { flex: 5, textAlign: "left" }]}
                  >
                    {producto.nombre || "Sin descripción"}
                  </Text>
                  <Text
                    style={[styles.tableCell, { flex: 1, textAlign: "center" }]}
                  >
                    {producto.cantidad}
                  </Text>
                  <Text
                    style={[styles.tableCell, { flex: 1, textAlign: "center" }]}
                  >
                    $
                    {parseFloat(producto.tarifa)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                  <Text
                    style={[styles.tableCell, { flex: 1, textAlign: "center" }]}
                  >
                    {parseFloat(producto.importe)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
              ))}
            </View>

            <View>
              <View
                style={{
                  border: "0.5px solid #3579CB",
                  backgroundColor: "#3579CB",
                  marginTop: 0,
                }}
              />
            </View>

            {/* Totales en la última página */}
            {pageIndex === paginasDeProductos.length - 1 && (
              <>
                <View style={styles.footerSection}>
                  <View style={styles.conditions}>
                    <Text style={styles.textoCondiciones}>
                      CONDICIONES / OBSERVACIONES:
                    </Text>
                    <Text>{orden.notas}</Text>
                  </View>
                  <View style={styles.totals}>
                    {/* SUBTOTAL */}
                    <View style={styles.totalRow}>
                      <Text style={styles.totalLabel}>Subtotal</Text>
                      <Text style={styles.totalValue}>
                        $
                        {subtotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </View>

                    {/* DESCUENTO (si aplica) */}
                    {descuentoAplicado > 0 && (
                      <>
                        <View style={styles.totalRow}>
                          <Text style={styles.totalLabel}>
                            Descuento ({descuentoPorcentaje}%)
                          </Text>
                          <Text style={styles.totalValue}>
                            -$
                            {descuentoAplicado
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Text>
                        </View>

                        <View style={styles.totalRow}>
                          <Text style={styles.totalLabel}>Restante</Text>
                          <Text style={styles.totalValue}>
                            $
                            {subtotalConDescuento
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Text>
                        </View>
                      </>
                    )}

                    {/* IVA */}
                    <View style={styles.totalRow}>
                      <Text style={styles.totalLabel}>IVA</Text>
                      <Text style={styles.totalValue}>
                        $
                        {ivaTotal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Text>
                    </View>

                    {/* TOTAL */}
                    <View style={[styles.totalRow, styles.totalBox]}>
                      <Text style={[styles.labelTotal]}>Total</Text>
                      <Text style={[styles.totalBoxText]}>
                        $
                        {total.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {orden.divisa}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            )}

            {/* Footer: número de página (se mantiene fijo) */}
            {pageIndex === paginasDeProductos.length - 1 && (
              <>
                {/* Contenedor de todo el contenido adicional */}
                <View
                  style={{
                    flex: 1, // Hace que este contenedor llene todo el espacio disponible
                    justifyContent: "flex-end",
                    marginBottom: 10, // Separación con el footer
                  }}
                  wrap={false} // Evita que el contenido se divida entre páginas
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* Información de contacto */}
                    <View
                      style={{ width: "50%", marginRight: 10, marginTop: 40 }}
                    >
                      <Text style={{ fontSize: 9 }}>
                        Para cualquier duda, comunicarse al teléfono:
                      </Text>
                      <Text style={{ fontSize: 9 }}>
                        33 2192 8621 / 33 3619 2439 ó al correo{" "}
                        <Text
                          style={{ textDecoration: "underline", fontSize: 9 }}
                        >
                          victor@iciamex.com
                        </Text>
                      </Text>
                    </View>

                    {/* Firma */}
                    <View style={{ width: "50%", alignItems: "flex-end" }}>
                      <Image
                        src="/img/firma.png"
                        style={{
                          width: 70,
                          height: "auto",
                        }}
                      />
                      <View
                        style={{
                          border: "1px solid #000",
                          borderWidth: "0.4px",
                          width: "30%",
                          marginTop: 10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 9,
                          fontWeight: "bold",
                          marginTop: 5,
                          textAlign: "right",
                        }}
                      >
                        Víctor García
                      </Text>
                      <Text style={{ fontSize: 9, textAlign: "right" }}>
                        Proyectos
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Footer fijo */}
                <View
                  fixed
                  style={{
                    position: "absolute",
                    bottom: 15, // Posición fija del footer
                    left: 30,
                    right: 30,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTopWidth: 0.5,
                    borderTopColor: "#999999",
                    paddingTop: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      textAlign: "left",
                      marginLeft: 0,
                      color: "#999999",
                    }}
                  >
                    UNIFICACION PARA LA AUTOMATIZACION SA DE CV
                  </Text>

                  <Text
                    style={styles.numeroDePagina}
                    render={({ pageNumber }) => `${pageNumber}`}
                  />
                </View>
              </>
            )}
          </Page>
        ))}
      </Document>
    );
  }, [orden, proveedores]);

  useEffect(() => {
    let isMounted = true;
    let url;

    const generateBlobUrl = async () => {
      if (!memoizedPdfDocument) {
        setPdfBlobUrl(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      const blob = await pdf(memoizedPdfDocument).toBlob();
      if (isMounted) {
        url = URL.createObjectURL(blob);
        setPdfBlobUrl(url);
        setLoading(false);
      }
    };

    generateBlobUrl();

    return () => {
      isMounted = false;
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [memoizedPdfDocument]);

  if (pdfVisible) {
    return (
      <div style={styles.pdfContainer}>
        <button
          className="cerrar-button"
          style={styles.closeButton}
          onClick={handleClosePDF}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <PDFViewer className="pdf-viewer" style={styles.pdfViewer}>
          {memoizedPdfDocument}
        </PDFViewer>
      </div>
    );
  }

  const handleEdit = () => {
    setEditMode(true);
    setShowSummary(false);
  };

  const closeModal = () => {
    setEditMode(false);
    setShowSummary(true);
    onClose();
  };

  if (editMode) {
    return (
      <EditarOrdenForm
        orden={orden}
        proveedores={proveedores}
        productos={orden && orden.productosSeleccionados}
        setOrdenes={setOrdenes}
        ordenes={ordenes}
        onClose={closeModal}
      />
    );
  }

  if (showSummary && orden && orden.productosSeleccionados) {
    return (
      <div className="resumen-cotizacion-container">
        <button className="cerrarBotonResumen" onClick={onClose}>
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="resumen-cotizacion-actions">
          <div
            className="dropdown"
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
          >
            <button className="dropbtn">
              <i className="bi bi-printer iconResumen"></i> Imprimir / PDF
            </button>
            {showOptions && (
              <div className="dropdown-content">
                <button onClick={handlePrint}>
                  <i
                    className="bi bi-file-earmark-text"
                    style={{ marginRight: 10 }}
                  />
                  Imprimir
                </button>
                <a
                  href={pdfBlobUrl}
                  download={`${orden.numeroOrden || "sin_numero"}-${
                    orden.nombreProveedor || "sin_nombre"
                  }.pdf`}
                  style={{ textDecoration: "none" }}
                >
                  <button>
                    <i className="bi bi-download" style={{ marginRight: 10 }} />
                    Descargar PDF
                  </button>
                </a>
              </div>
            )}
          </div>
          <button>
            <i className="bi bi-envelope-fill iconResumen"></i> Correo
          </button>
          <button onClick={handleEdit}>
            <i className="bi bi-pencil-fill iconResumen"></i> Editar
          </button>
          <button>
            <i className="bi bi-receipt iconResumen"></i> Convertir en Factura
          </button>
          {orden.archivos && orden.archivos.length > 0 && (
            <button onClick={() => setShowAdjuntosModal(true)}>
              <i className="bi bi-paperclip iconResumen"></i> Archivos Adjuntos
            </button>
          )}
        </div>

        <Modal
          isOpen={showAdjuntosModal}
          onRequestClose={() => setShowAdjuntosModal(false)}
          contentLabel="Archivos Adjuntos"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "35%", // Tamaño del modal ajustado
              maxHeight: "80vh",
              overflowY: "auto",
              padding: "20px",
              borderRadius: "10px",
              position: "relative", // Importante para la "X" flotante
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {/* Botón "X" para cerrar */}
          <button
            onClick={() => setShowAdjuntosModal(false)}
            className="modal-close"
            title="Cerrar"
          >
            <i className="bi bi-x-lg"></i>
          </button>

          {/* Título */}
          <h3
            style={{
              marginBottom: "20px",
              color: "#000",
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Archivos Adjuntos
          </h3>

          {/* Lista de Archivos Adjuntos */}
          <ul style={{ listStyle: "none", padding: 0 }}>
            {adjuntos.map((archivo, index) => {
              const { icon, color } = obtenerIconoArchivo(archivo.nombre);
              return (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  }}
                >
                  {/* Información del Archivo */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className={`bi ${icon}`}
                      style={{
                        color: color,
                        fontSize: "1.5rem",
                        marginRight: "10px",
                      }}
                    ></i>
                    <a
                      href={archivo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      {archivo.nombre}
                    </a>
                  </div>

                  {/* Acciones */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a href={archivo.url} download={archivo.nombre}>
                      <button
                        onClick={(e) => {
                          e.preventDefault(); // Prevenir comportamiento predeterminado
                          const link = document.createElement("a");
                          link.href = archivo.url;
                          link.target = "_blank"; // Abrir en nueva pestaña
                          link.rel = "noopener noreferrer"; // Seguridad adicional
                          link.download = archivo.nombre; // Establecer nombre para descarga
                          document.body.appendChild(link);
                          link.click(); // Simular clic
                          document.body.removeChild(link); // Remover del DOM
                        }}
                        style={{
                          background: "none",
                          border: "none",
                          color: "#007bff",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        title="Descargar archivo"
                      >
                        <i className="bi bi-download"></i>
                      </button>
                    </a>
                    <button
                      onClick={() => handleDeleteArchivo(archivo)}
                      style={{
                        background: "none",
                        border: "none",
                        color: "#dc3545",
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "20px",
                      }}
                      title="Eliminar archivo"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </Modal>

        <div
          style={{
            height: "600px",
            width: "100%",
            marginTop: "-20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <div className="loader2"></div>
          ) : (
            pdfBlobUrl && (
              <iframe
                src={`${pdfBlobUrl}#toolbar=0`}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
                title="Vista previa del PDF"
              />
            )
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default ResumenOrden;
