import React, { useState, useEffect, useRef } from "react";
import PreviaCotizacion from "./PreviaCotizacion";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import ResumenCotizacion from "./ResumenCotizacion";
import Paginador from "./Paginador";
import FloatingActionButton from "./FloatingActionButton";
import BandejaCotizaciones from "./BandejaCotizaciones";
import Nav from "./Nav";
import CotizacionForm from "./CotizacionForm"; // Importa el componente CotizacionForm
import {
  collection,
  deleteDoc,
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { useNavContext } from "../context/NavContext";
import Swal from "sweetalert2";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

// Configura el localizador de fechas usando moment.js
moment.locale("es");

const styleForm = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    width: "100%",
    height: "550px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function TablaCotizaciones({
  cotizaciones,
  clientes,
  setCotizaciones,
  guardarCotizacion,
  modoEdicion,
  cotizacion,
  numeroCotizacion,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cotizacionSeleccionada, setCotizacionSeleccionada] = useState(null);
  const [loadingCotizaciones, setLoadingCotizaciones] = useState(true);
  const [selectedCotizaciones, setSelectedCotizaciones] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState({
    campo: "fechaCotizacion",
    ascendente: false,
  });
  const [selectedCotizacionId, setSelectedCotizacionId] = useState(null);
  const [resumenVisible, setResumenVisible] = useState(false);
  const [showBandeja, setShowBandeja] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const setCotizacionesRef = useRef(setCotizaciones);
  const { searchTerm, setSearchTerm, proximasAVencer, proximosEventos } = useNavContext();
  const [formDirty, setFormDirty] = useState(false); // Añade esto
  const location = useLocation();
  const [estadosCeldas, setEstadosCeldas] = useState({});
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const dropdownRef = useRef(null); // Referencia al menú desplegable
  const fileInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Valor inicial

  const ordenarCotizaciones = (cotizaciones, { campo, ascendente }) => {
    if (!cotizaciones || cotizaciones.length === 0) {
      return [];
    }

    const sortedCotizaciones = [...cotizaciones];
    switch (campo) {
      case "fechaCotizacion":
        sortedCotizaciones.sort((a, b) => {
          const dateA = new Date(a.fechaCotizacion);
          const dateB = new Date(b.fechaCotizacion);
          return ascendente ? dateA - dateB : dateB - dateA;
        });
        break;
      case "numeroCotizacion":
        sortedCotizaciones.sort((a, b) => {
          // Extraer la parte numérica de "numeroCotizacion"
          const numA = parseInt(a.numeroCotizacion.replace(/\D/g, ""), 10) || 0;
          const numB = parseInt(b.numeroCotizacion.replace(/\D/g, ""), 10) || 0;
          return ascendente ? numA - numB : numB - numA;
        });
        break;
      case "referencia":
        sortedCotizaciones.sort((a, b) => {
          const refA = a.referencia.toLowerCase();
          const refB = b.referencia.toLowerCase();
          return ascendente
            ? refA.localeCompare(refB)
            : refB.localeCompare(refA);
        });
        break;
      case "nombreCliente":
        sortedCotizaciones.sort((a, b) => {
          const clienteA = a.nombreCliente.toLowerCase();
          const clienteB = b.nombreCliente.toLowerCase();
          return ascendente
            ? clienteA.localeCompare(clienteB)
            : clienteB.localeCompare(clienteA);
        });
        break;
      case "total":
        sortedCotizaciones.sort((a, b) => {
          return ascendente ? a.total - b.total : b.total - a.total;
        });
        break;
      default:
        break;
    }
    return sortedCotizaciones;
  };

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const handleImportClick = () => {
    fileInputRef.current.click(); // Simula el clic en el input de archivo
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      Swal.fire({
        title: "Error",
        text: "No se seleccionó ningún archivo.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const reader = new FileReader();

    // Evitar que las advertencias de la librería se muestren en consola
    const originalConsoleError = console.error;
    console.error = (message, ...args) => {
      if (message.includes("Bad uncompressed size")) {
        return; // Ignorar mensajes específicos
      }
      originalConsoleError(message, ...args); // Mostrar otros errores
    };

    function formatExcelDate(excelDate) {
      let dateObj;

      if (typeof excelDate === "string") {
        // Intentar parsear como cadena con diferentes formatos
        dateObj = moment(
          excelDate,
          ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY"],
          true
        );
      } else if (excelDate instanceof Date) {
        // Si es un objeto Date
        dateObj = moment(excelDate);
      } else if (typeof excelDate === "number") {
        // Si es un número (fecha serial de Excel)
        dateObj = moment(
          new Date(Math.round((excelDate - 25569) * 86400 * 1000))
        );
      } else {
        // Formato desconocido
        return null;
      }

      if (dateObj.isValid()) {
        // Devuelve la fecha en formato ISO
        return dateObj.toISOString();
      } else {
        return null;
      }
    }

    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array",
          cellDates: true,
          raw: true,
        });

        if (!workbook.SheetNames.length)
          throw new Error("El archivo no contiene hojas válidas.");
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        if (!sheet) throw new Error("No se pudo leer la hoja seleccionada.");

        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: "" });
        if (!jsonData.length)
          throw new Error("El archivo no contiene datos válidos.");

        const firestore = getFirestore();
        const cotizacionesCollectionRef = collection(firestore, "cotizaciones");

        // Obtener todos los clientes de Firebase y crear un mapa de nombre a ID
        const clientesSnapshot = await getDocs(
          collection(firestore, "clientes")
        );
        const clientesMap = {};
        clientesSnapshot.forEach((doc) => {
          const data = doc.data();
          clientesMap[data.nombreCliente] = doc.id;
        });

        const groupedCotizaciones = jsonData.reduce((acc, row) => {
          const numeroCotizacion = row["Número de Estimación"] || "";
          if (!numeroCotizacion) return acc;

          if (!acc[numeroCotizacion]) {
            acc[numeroCotizacion] = {
              fechaCotizacion: row["Fecha del Estimación"]
                ? formatExcelDate(row["Fecha del Estimación"])
                : null,
              fechaVencimiento: row["Expiry Date"]
                ? formatExcelDate(row["Expiry Date"])
                : null,
              numeroCotizacion,
              referencia: row["PurchaseOrder"] || "",
              nombreCliente: row["Customer Name"] || "",
              vendedorNombre: row["Sales person"] || "", // Agregar Vendedor
              atencion: row["CF.Atención"] || "", // Agregar Atención
              planta: row["CF.Planta"] || "", // Agregar Planta
              area: row["CF.Área"] || "",
              estado: "Pendiente", // Forzar estado a "Pendiente"
              notas: row["Notes"] || "", // Agregar este campo
              terminos: row["Terms & Conditions"]
                ? row["Terms & Conditions"]
                    .toString()
                    .replace(/\r\n/g, "\n")
                    .replace(/\r/g, "\n")
                : "",
              total: 0,
              divisa: "MXN",
              datosCompletos: [], // Para almacenar todas las filas relacionadas
              productosSeleccionados: [], // Almacenar productos mapeados
              direccionCliente: {
                calle: row["Billing Address"] || "",
                ciudad: row["Billing City"] || "",
                estado: row["Billing State"] || "",
                pais: row["Billing Country"] || "",
                codigoPostal: row["Billing Code"] || "",
              },
            };

            // Buscar el ID del cliente usando el nombre del cliente
            const clienteId = clientesMap[acc[numeroCotizacion].nombreCliente];
            if (clienteId) {
              acc[numeroCotizacion].cliente = clienteId;
            } else {
              // Opcional: Puedes crear un nuevo cliente si no existe
            }
          }

          // Agregar la fila completa a "datosCompletos"
          acc[numeroCotizacion].datosCompletos.push(row);

          // Validar el total para evitar acumulaciones incorrectas
          const totalValue = row["Total"];
          const total =
            typeof totalValue === "string"
              ? parseFloat(
                  totalValue.replace(/[^0-9.-]+/g, "") // Eliminar caracteres no numéricos
                )
              : totalValue || 0;

          // Si el total es mayor a 0, sobrescribir el valor actual para la cotización
          if (total > 0) {
            acc[numeroCotizacion].total = total;
          }

          // Mapear productos específicos
          const producto = {
            nombre: row["Item Name"] || "producto",
            descripcion: row["Item Desc"] || "",
            modelo: row["Item.CF.Modelo"] || "",
            cantidad:
              parseFloat(
                row["Quantity"]
                  ? row["Quantity"].toString().replace(/,/g, "")
                  : 0
              ) || 0,
            tarifa:
              typeof row["Item Price"] === "string"
                ? parseFloat(
                    row["Item Price"].toString().replace(/[^0-9.-]+/g, "")
                  )
                : row["Item Price"] || 0,
            importe:
              typeof row["Item Total"] === "string"
                ? parseFloat(
                    row["Item Total"].toString().replace(/[^0-9.-]+/g, "")
                  )
                : row["Item Total"] || 0,
          };

          // Agregar productos a la cotización si tienen un nombre válido
          if (producto.nombre && producto.cantidad > 0) {
            acc[numeroCotizacion].productosSeleccionados.push(producto);
          } else {
            console.warn(
              `Producto inválido en cotización ${numeroCotizacion}:`,
              producto
            );
          }

          return acc;
        }, {});

        const nuevasCotizaciones = [];
        const actualizaciones = [];

        for (const numeroCotizacion in groupedCotizaciones) {
          const cotizacion = groupedCotizaciones[numeroCotizacion];

          // Verificar si ya existe en Firebase
          const cotizacionQuery = query(
            cotizacionesCollectionRef,
            where("numeroCotizacion", "==", cotizacion.numeroCotizacion)
          );
          const querySnapshot = await getDocs(cotizacionQuery);

          if (querySnapshot.empty) {
            // Si no existe, crear una nueva
            await addDoc(cotizacionesCollectionRef, cotizacion);
            nuevasCotizaciones.push(cotizacion);
          } else {
            // Si existe, actualizar los datos
            querySnapshot.forEach(async (docSnapshot) => {
              const docRef = doc(firestore, "cotizaciones", docSnapshot.id);
              await updateDoc(docRef, { ...cotizacion, estado: "Pendiente" });
              actualizaciones.push(cotizacion);
            });
          }
        }

        // Actualizar el estado local
        setCotizaciones((prevCotizaciones) => {
          const nuevasUnicas = nuevasCotizaciones.filter(
            (newCot) =>
              !prevCotizaciones.some(
                (prevCot) =>
                  prevCot.numeroCotizacion === newCot.numeroCotizacion
              )
          );

          const cotizacionesActualizadas = prevCotizaciones.map((prevCot) => {
            const actualizacion = actualizaciones.find(
              (actCot) => actCot.numeroCotizacion === prevCot.numeroCotizacion
            );
            return actualizacion ? { ...prevCot, ...actualizacion } : prevCot;
          });

          return [...cotizacionesActualizadas, ...nuevasUnicas];
        });

        Swal.fire({
          title: "Importación exitosa",
          text: `${nuevasCotizaciones.length} cotización(es) agregada(s), ${actualizaciones.length} actualizada(s).`,
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error al procesar el archivo:", error);
        Swal.fire({
          title: "Error",
          text: `Error al procesar el archivo: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        // Restaurar el comportamiento original de console.error
        console.error = originalConsoleError;
      }
    };

    reader.onerror = () => {
      console.error = originalConsoleError; // Restaurar en caso de error
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al leer el archivo. Inténtalo nuevamente.",
        icon: "error",
        confirmButtonText: "OK",
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const toggleOptionsMenu = () => {
    setShowOptionsMenu((prevShowOptionsMenu) => !prevShowOptionsMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptionsMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFormDirtyChange = (dirty) => {
    setFormDirty(dirty);
  };

  // Al cargar la página, obtenemos las cotizaciones y los estados de Firestore
  useEffect(() => {
    setLoadingCotizaciones(true);
    const firestore = getFirestore();
    const cotizacionesRef = collection(firestore, "cotizaciones");

    const unsubscribe = onSnapshot(cotizacionesRef, (snapshot) => {
      const updatedCotizaciones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const estados = {};
      updatedCotizaciones.forEach((cotizacion) => {
        estados[cotizacion.id] = cotizacion.estado || "Pendiente";
      });
      setEstadosCeldas(estados);
      setCotizacionesRef.current(updatedCotizaciones);
      setLoadingCotizaciones(false);
    });

    return () => unsubscribe();
  }, [setCotizaciones]);

  // Función para alternar entre los estados de cada celda seleccionada y guardarlo en Firestore
  const toggleEstado = async (cotizacionId) => {
    const newEstado =
      estadosCeldas[cotizacionId] === "Pendiente"
        ? "En Proceso"
        : estadosCeldas[cotizacionId] === "En Proceso"
        ? "Facturado"
        : estadosCeldas[cotizacionId] === "Facturado"
        ? "Pagada"
        : "Pendiente"; // Regresa a "Pendiente" si está en "Facturado"

    // Actualizamos el estado localmente
    setEstadosCeldas((prevEstados) => ({
      ...prevEstados,
      [cotizacionId]: newEstado,
    }));

    // Guardamos el nuevo estado en Firestore
    const firestore = getFirestore();
    const cotizacionDocRef = doc(firestore, "cotizaciones", cotizacionId);
    await updateDoc(cotizacionDocRef, { estado: newEstado });
  };

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setMostrarFormulario(true);
    }
  }, [location]);


  const openFormulario = () => {
    setMostrarFormulario(true);
    setShowNuevoButton(false); // Oculta el botón + Nuevo al abrir el formulario
  };

  // Dentro de TablaCotizaciones.js
  const closeModal = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setMostrarFormulario(false);
          setShowNuevoButton(true); // Vuelve a mostrar el botón + Nuevo al cerrar el formulario
          setFormDirty(false); // Restablece formDirty
        }
      });
    } else {
      setMostrarFormulario(false);
      setShowNuevoButton(true);
    }
  };

  useEffect(() => {
    setCotizacionesRef.current = setCotizaciones;
  }, [setCotizaciones]);

  useEffect(() => {
    setLoadingCotizaciones(true);
    const firestore = getFirestore();
    const cotizacionesRef = collection(firestore, "cotizaciones");

    const unsubscribe = onSnapshot(cotizacionesRef, (snapshot) => {
      const updatedCotizaciones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCotizacionesRef.current(updatedCotizaciones);
      setLoadingCotizaciones(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las cotizaciones seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const cotizacionesEliminadas = [];
        const cotizacionesRef = collection(firestore, "cotizaciones");

        for (const cotizacionId of selectedCotizaciones) {
          const cotizacionDocRef = doc(cotizacionesRef, cotizacionId);
          await deleteDoc(cotizacionDocRef);
          cotizacionesEliminadas.push(cotizacionId);
        }

        const cotizacionesRestantes = cotizaciones.filter(
          (cotizacion) => !cotizacionesEliminadas.includes(cotizacion.id)
        );
        setCotizaciones(cotizacionesRestantes);
        setSelectedCotizaciones([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "Cotización eliminada",
          text: "Cotización eliminada correctamente.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar cotizaciones:", error);
    }
  };

  const filterCotizaciones = () => {
    if (!cotizaciones || cotizaciones.length === 0) return []; // Si no hay cotizaciones, retorna un array vacío

    const searchLower = searchTerm.toLowerCase(); // Convertir término de búsqueda a minúsculas

    return cotizaciones.filter((cotizacion) => {
      // Campos relevantes para la búsqueda
      const searchableFields = [
        cotizacion.numeroCotizacion?.toString().toLowerCase() || "",
        cotizacion.referencia?.toLowerCase() || "",
        cotizacion.nombreCliente?.toLowerCase() || "",
        cotizacion.estado?.toLowerCase() || "",
        cotizacion.total?.toString().toLowerCase() || "",
        cotizacion.notas?.toLowerCase() || "", // Incluye notas
        formatDate(cotizacion.fechaCotizacion).toLowerCase(), // Fecha de cotización formateada
        formatDate(cotizacion.fechaVencimiento).toLowerCase(), // Fecha de vencimiento formateada
      ];

      // Verifica si alguno de los campos incluye el término de búsqueda
      return searchableFields.some((field) => field.includes(searchLower));
    });
  };

  const filteredCotizaciones = React.useMemo(() => {
    if (!cotizaciones || cotizaciones.length === 0) return [];
    const searchLower = searchTerm.toLowerCase();
    const searchDate = moment(searchTerm, ["DD MMM YYYY", "MMM YYYY", "YYYY"], true);
    return cotizaciones.filter((cotizacion) => {
      const searchableFields = [
        cotizacion.numeroCotizacion?.toString().toLowerCase() || "",
        cotizacion.referencia?.toLowerCase() || "",
        cotizacion.nombreCliente?.toLowerCase() || "",
        cotizacion.total?.toString().toLowerCase() || "",
        cotizacion.planta?.toLowerCase() || "",
        cotizacion.vendedorNombre?.toLowerCase() || "",
        cotizacion.estado?.toLowerCase() || "",
        cotizacion.atencion?.toLowerCase() || "",
        cotizacion.area?.toLowerCase() || "",
        cotizacion.divisa?.toLowerCase() || "",
        cotizacion.asunto?.toLowerCase() || "",
        cotizacion.notas?.toLowerCase() || "",
        cotizacion.terminos?.toLowerCase() || "",
        formatDate(cotizacion.fechaCotizacion).toLowerCase(), // Fecha de cotización
        formatDate(cotizacion.fechaVencimiento).toLowerCase(), // Fecha de vencimiento
      ];

      // Verifica si la búsqueda incluye una fecha y si la fecha coincide con alguna cotización
      if (searchDate.isValid()) {
        const fechaCotizacion = moment(cotizacion.fechaCotizacion);
        const fechaVencimiento = moment(cotizacion.fechaVencimiento);

        return (
          fechaCotizacion.isSame(searchDate, 'month') || // Coincide con el mes
          fechaCotizacion.isSame(searchDate, 'year') || // Coincide con el año
          fechaCotizacion.isSame(searchDate, 'day') || // Coincide con el día exacto
          fechaVencimiento.isSame(searchDate, 'month') ||
          fechaVencimiento.isSame(searchDate, 'year') ||
          fechaVencimiento.isSame(searchDate, 'day')
        );
      }

      return searchableFields.some((field) => field.includes(searchLower));
    });
  }, [cotizaciones, searchTerm]);

  const paginatedCotizaciones = React.useMemo(() => {
    const cotizacionesOrdenadas = ordenarCotizaciones(
      filteredCotizaciones,
      ordenamiento
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return cotizacionesOrdenadas.slice(startIndex, endIndex);
  }, [filteredCotizaciones, currentPage, itemsPerPage, ordenamiento]);

  const totalCotizaciones = filteredCotizaciones.length;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const cerrarModalPrevia = () => {
    setModalIsOpen(false);
    setCotizacionSeleccionada(null);
  };

  const handleRowClick = (cotizacionId) => {
    setSelectedCotizacionId(cotizacionId);
    setResumenVisible(true);
    setShowBandeja(true);
  };

  const handleSelectCotizacion = (cotizacionId) => {
    setSelectedCotizaciones((prevSelected) => {
      if (prevSelected.includes(cotizacionId)) {
        return prevSelected.filter((id) => id !== cotizacionId);
      } else {
        return [...prevSelected, cotizacionId];
      }
    });
  };

  const handleOrdenamientoChange = (campo) => {
    setOrdenamiento((prevOrdenamiento) => ({
      campo,
      ascendente:
        campo === prevOrdenamiento.campo ? !prevOrdenamiento.ascendente : true,
    }));
  };

  // Define ordenarCotizaciones antes de usarla en el componente

  // Función para seleccionar/deseleccionar todas las cotizaciones
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las cotizaciones
      const allCotizacionesIds = filterCotizaciones().map(
        (cotizacion) => cotizacion.id
      );
      setSelectedCotizaciones(allCotizacionesIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedCotizaciones([]);
    }
  };

  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date(); // Usa la fecha actual si dateString no está definida
    const day = date.getDate().toString().padStart(2, "0");

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <>
      {!showBandeja && !resumenVisible && !mostrarFormulario && (
        <>
          <Nav
            handleSearch={setSearchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <div className="task-filters">
            <div className="task-filters-search">
              <i className="bi bi-search search-icon"></i>
              <input
                type="text"
                className="search-input2"
                placeholder="Buscar cotizaciones"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="task-filters-right">
              <div className="task-filter filter-control">
                <Link href="#" className="filter-link control-link">
                  <i className="bi bi-sliders2-vertical"></i> Filter
                </Link>
              </div>
              <div className="view-buttons-container">
                <button
                  className="view-button dots-vertical"
                  onClick={toggleOptionsMenu}
                >
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <button className="view-button list-view">
                  <i className="bi bi-list"></i>
                </button>
              </div>
              <div>
                {showNuevoButton && (
                  <button className="new-task-button" onClick={openFormulario}>
                    + Nuevo
                  </button>
                )}
              </div>

              <div style={{ marginLeft: "-10px" }}>
                {selectedCotizaciones.length > 0 && (
                  <div className="delete-button-container">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={handleDeleteSelected}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showOptionsMenu && (
            <div className="dropdown-options" ref={dropdownRef}>
              <p onClick={handleImportClick}>
                <i
                  className="bi bi-box-arrow-in-down"
                  style={{ marginRight: "10px" }}
                ></i>
                Importar
              </p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
              <p>
                <i
                  className="bi bi-box-arrow-up"
                  style={{ marginRight: "10px" }}
                ></i>
                Exportar
              </p>
            </div>
          )}
        </>
      )}

      <div className="cotizaciones-table">
        {showBandeja && (
          <BandejaCotizaciones
            cotizaciones={cotizaciones}
            setCotizaciones={setCotizaciones}
            onRowClick={handleRowClick}
            clientes={clientes}
            guardarCotizacion={guardarCotizacion}
            cotizacion={cotizacion}
            selectedCotizacionId={selectedCotizacionId}
          />
        )}
        {!showBandeja && (
          <div className="tabla-cotizaciones-wrapper">
            <div className="tabla-cotizaciones-content">
              {loadingCotizaciones ? (
                <Spinner />
              ) : (
                <>
                  {filteredCotizaciones.length > 0 ? (
                    <table>
                      {!mostrarFormulario && (
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                className="checkbox-select-all check"
                                checked={
                                  selectedCotizaciones.length ===
                                  filteredCotizaciones.length
                                }
                              />
                            </th>
                            <th
                              className="fecha"
                              onClick={() =>
                                handleOrdenamientoChange("fechaCotizacion")
                              }
                            >
                              Fecha
                            </th>
                            <th
                              className="no"
                              onClick={() =>
                                handleOrdenamientoChange("numeroCotizacion")
                              }
                            >
                              No.
                            </th>
                            <th
                              className="referencia"
                              onClick={() =>
                                handleOrdenamientoChange("referencia")
                              }
                            >
                              Referencia
                            </th>
                            <th
                              className="cliente"
                              onClick={() =>
                                handleOrdenamientoChange("nombreCliente")
                              }
                              style={{ width: "20%" }}
                            >
                              Cliente
                            </th>
                            <th
                              className="estado"
                              onClick={() => handleOrdenamientoChange("estado")}
                            >
                              Estado
                            </th>
                            <th
                              className="importe"
                              onClick={() => handleOrdenamientoChange("total")}
                            >
                              Importe
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {paginatedCotizaciones.map((cotizacion) => (
                          <tr key={cotizacion.id}>
                            <td className="check">
                              <input
                                type="checkbox"
                                checked={selectedCotizaciones.includes(
                                  cotizacion.id
                                )}
                                onChange={() =>
                                  handleSelectCotizacion(cotizacion.id)
                                }
                                className="checkbox"
                              />
                            </td>
                            <td onClick={() => handleRowClick(cotizacion.id)}>
                              {formatDate(cotizacion.fechaCotizacion)}
                            </td>
                            <td
                              style={{ color: "#007bff", fontWeight: "500" }}
                              onClick={() => handleRowClick(cotizacion.id)}
                            >
                              {cotizacion.numeroCotizacion
                                ?.toString()
                                .padStart(4, "0")}
                            </td>
                            <td onClick={() => handleRowClick(cotizacion.id)}>
                              {cotizacion.referencia}
                            </td>
                            <td onClick={() => handleRowClick(cotizacion.id)}>
                              {cotizacion.nombreCliente}
                            </td>
                            <td>
                              <div
                                className={`status2 ${
                                  estadosCeldas[cotizacion.id] === "Pendiente"
                                    ? "pendiente2"
                                    : estadosCeldas[cotizacion.id] ===
                                      "En Proceso"
                                    ? "en-proceso2"
                                    : estadosCeldas[cotizacion.id] ===
                                      "Facturado"
                                    ? "facturado2"
                                    : estadosCeldas[cotizacion.id] === "Pagada"
                                    ? "pagada2"
                                    : "pendiente2" // Clase por defecto en caso de estado desconocido
                                }`}
                                onClick={() => toggleEstado(cotizacion.id)}
                              >
                                {estadosCeldas[cotizacion.id] || "Pendiente"}
                              </div>
                            </td>
                            <td
                              className="importe"
                              onClick={() => handleRowClick(cotizacion.id)}
                            >
                              {cotizacion.divisa}{" "}
                              {parseFloat(cotizacion.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-resultados">
                      <div
                        className="mensaje-sin-resultados"
                        style={{ marginTop: "30px" }}
                      >
                        <p style={{ fontSize: "14px" }}>
                          No existe ninguna cotización que coincida con esta
                          búsqueda.
                        </p>
                      </div>
                      <div className="imagen-sin-resultados">
                        <img
                          src="/img/noSearch.svg"
                          alt="Sin resultados"
                          className="sin-resultados-imagen"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <Modal
                isOpen={mostrarFormulario}
                onRequestClose={closeModal}
                contentLabel="Nueva Cotización"
                style={styleForm}
              >
                <button onClick={closeModal} className="cerrar-button">
                  <i className="bi bi-x-lg"></i>
                </button>
                <CotizacionForm
                  clientes={clientes}
                  guardarCotizacion={guardarCotizacion}
                  modoEdicion={modoEdicion}
                  cotizacion={cotizacion}
                  numeroCotizacion={numeroCotizacion}
                  onFormDirtyChange={handleFormDirtyChange}
                  closeModal={closeModal}
                />
              </Modal>
            </div>
            <div className="tabla-cotizaciones-footer">
              <div className="paginador-container">
                <Paginador
                  totalItems={totalCotizaciones}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {cotizacionSeleccionada && (
            <PreviaCotizacion
              cotizacion={cotizacionSeleccionada}
              numeroCotizacion={cotizacionSeleccionada.numeroCotizacion}
              clientes={clientes}
              cerrarPrevia={cerrarModalPrevia}
            />
          )}
        </Modal>
        <div
          className={`resumen-container ${
            selectedCotizacionId ? "active" : ""
          }`}
        >
          <ResumenCotizacion
            cotizacion={
              cotizaciones &&
              cotizaciones.find(
                (cotizacion) => cotizacion.id === selectedCotizacionId
              )
            }
            isOpen={resumenVisible}
            onClose={() => {
              setResumenVisible(false);
              setSelectedCotizacionId(null);
              setShowBandeja(false);
            }}
            numeroCotizacion={
              cotizacionSeleccionada
                ? cotizacionSeleccionada.numeroCotizacion
                : null
            }
            clientes={clientes}
            mostrarBotonNuevo={false}
          />
        </div>
      </div>

      {!showBandeja && !resumenVisible && !mostrarFormulario && (
        <FloatingActionButton />
      )}
    </>
  );
}

export default TablaCotizaciones;