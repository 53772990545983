import React, { useState, useEffect } from "react";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import Swal from "sweetalert2";
import Modal from "react-modal";
import ProveedorTable from "./ProveedorTable";
import Nav from "./Nav";
import moment from "moment";
import Spinner from "./Spinner";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { useNavContext } from "../context/NavContext";

moment.locale("es");

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  label: {
    display: "block",
    width: "160px", // Fija el ancho de los labels
    textAlign: "right", // Alinea los labels a la derecha
    whiteSpace: "nowrap",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  select: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 101,
  },
};

const stepContainerStyle = {
  display: "flex",
  justifyContent: "left", // Para mantener los pasos más juntos
  marginLeft: "100px",
  gap: "50px", // Ajusta la separación entre los pasos
  marginBottom: "20px",
  cursor: "pointer",
};

const stepStyle = (isActive) => ({
  padding: "10px 15px", // Ajustamos un poco el padding
  borderBottom: isActive ? "4px solid #007bff" : "4px solid transparent",
  transition: "border-bottom 0.3s ease",
  fontWeight: isActive ? "bold" : "normal",
});

const ListaProveedores = () => {
  const [proveedores, setProveedores] = useState([]);
  const [formData, setFormData] = useState({
    empresa: "",
    rfc: "",
    regimenFiscal: "",
    moneda: "",
    telefono: "",
    correo: "",
    imagenURL: null,
    domicilio: "",
    numeroExt: "",
    numeroInt: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [editingProveedorId, setEditingProveedorId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm, setSearchTerm, proximasAVencer, proximosEventos } =
    useNavContext();
  const [showNuevoButton, setShowNuevoButton] = useState(true);

  const searchProveedores = (term) => {
    return proveedores.filter(
      (proveedor) =>
        proveedor.empresa.toLowerCase().includes(term.toLowerCase()) ||
        proveedor.rfc.toLowerCase().includes(term.toLowerCase()) ||
        proveedor.regimenFiscal.toLowerCase().includes(term.toLowerCase())
    );
  };

  useEffect(() => {
    obtenerProveedores();
  }, []);

  const obtenerProveedores = async () => {
    const proveedoresSnapshot = await getDocs(collection(db, "proveedores"));
    const listaProveedores = proveedoresSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProveedores(listaProveedores);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar campos obligatorios para el paso actual
    if (step === 1 || step === 2) {
      const requiredFields = ["empresa"]; // Agrega más campos si son obligatorios
      for (let field of requiredFields) {
        if (!formData[field] || formData[field].trim() === "") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `El campo "${
              field === "empresa" ? "Nombre del Proveedor" : field
            }" es obligatorio!`,
          });
          return;
        }
      }
    }

    try {
      const proveedorData = {
        ...formData,
        telefono: formData.telefono || "", // Validar que no haya campos undefined
        correo: formData.correo || "",
        rfc: formData.rfc || "",
        regimenFiscal: formData.regimenFiscal || "",
      };

      if (editingProveedorId) {
        // Actualizar un proveedor existente
        await updateDoc(
          doc(db, "proveedores", editingProveedorId),
          proveedorData
        );
        Swal.fire({
          icon: "success",
          title: "¡Proveedor actualizado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        // Agregar un nuevo proveedor
        await addDoc(collection(db, "proveedores"), proveedorData);
        Swal.fire({
          icon: "success",
          title: "¡Proveedor registrado con éxito!",
          showConfirmButton: false,
          timer: 1000,
        });
      }

      // Actualizar lista de proveedores y limpiar el formulario
      await obtenerProveedores();
      setFormData({
        empresa: "",
        rfc: "",
        regimenFiscal: "",
        moneda: "",
        telefono: "",
        correo: "",
        imagenURL: null,
        domicilio: "",
        numeroExt: "",
        numeroInt: "",
        colonia: "",
        codigoPostal: "",
        ciudad: "",
        estado: "",
      });
      setEditingProveedorId(null);
      setStep(1);
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error al guardar proveedor:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al guardar el proveedor. Intenta nuevamente.",
      });
    }
  };

  const handleEditProveedor = (id) => {
    const proveedorToEdit = proveedores.find(
      (proveedor) => proveedor.id === id
    );

    // Validar que proveedorToEdit no sea undefined
    if (!proveedorToEdit) return;

    // Asegurar que todos los campos de formData estén presentes
    setFormData({
      empresa: proveedorToEdit.empresa || "",
      rfc: proveedorToEdit.rfc || "",
      regimenFiscal: proveedorToEdit.regimenFiscal || "",
      moneda: proveedorToEdit.moneda || "",
      telefono: proveedorToEdit.telefono || "",
      correo: proveedorToEdit.correo || "",
      imagenURL: proveedorToEdit.imagenURL || null,
      domicilio: proveedorToEdit.domicilio || "",
      numeroExt: proveedorToEdit.numeroExt || "",
      numeroInt: proveedorToEdit.numeroInt || "",
      colonia: proveedorToEdit.colonia || "",
      codigoPostal: proveedorToEdit.codigoPostal || "",
      ciudad: proveedorToEdit.ciudad || "",
      estado: proveedorToEdit.estado || "",
    });

    setEditingProveedorId(id);
    setStep(1); // Vuelve al primer paso del modal
    setModalIsOpen(true); // Abre el modal
  };

  const handleDeleteProveedor = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "proveedores", id));
          Swal.fire({
            icon: "success",
            title: "¡Proveedor eliminado con éxito!",
            showConfirmButton: false,
            timer: 1000,
          });
          obtenerProveedores();
        } catch (error) {
          console.error("Error al eliminar proveedor:", error);
        }
      }
    });
  };

  const resetFormData = () => {
    setFormData({
      empresa: "",
      rfc: "",
      regimenFiscal: "",
      moneda: "",
      telefono: "",
      correo: "",
      imagenURL: null,
      domicilio: "",
      numeroExt: "",
      numeroInt: "",
      colonia: "",
      codigoPostal: "",
      ciudad: "",
      estado: "",
    });
  };

  const handleOpenModal = () => {
    resetFormData();
    setEditingProveedorId(null);
    setModalIsOpen(true);
  };

  return (
    <>
      <Nav
        handleSearch={searchTerm}
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div className="task-filters">
        <div className="task-filters-search">
          <i className="bi bi-search search-icon"></i>
          <input
            type="text"
            className="search-input2"
            placeholder="Buscar proveedores"
            value={searchTerm} // Vincula el valor del input al estado
            onChange={(e) => setSearchTerm(e.target.value)} // Actualiza el estado en cada cambio
          />
        </div>
        <div className="task-filters-right">
          <div className="task-filter filter-control">
            <Link href="#" className="filter-link control-link">
              <i className="bi bi-sliders2-vertical"></i> Filter
            </Link>
          </div>
          <div className="view-buttons-container">
            <button className="view-button grid-view">
              <i className="bi bi-grid"></i>
            </button>
            <button className="view-button list-view">
              <i className="bi bi-list"></i>
            </button>
          </div>
          <div>
            {showNuevoButton && (
              <button
                className="new-task-button"
                onClick={handleOpenModal}
                setShowNuevoButton={setShowNuevoButton}
              >
                + Nuevo
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="cotizaciones-table">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={customStyles}
        >
          <div>
            <button
              className="cerrar-button"
              onClick={() => setModalIsOpen(false)}
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>

          <Nav
            handleSearch={searchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <Sidebar />

          {step === 1 && (
            <>
              <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                {editingProveedorId
                  ? "Editar Proveedor"
                  : "Agregar nuevo proveedor"}
              </h2>
              <div style={stepContainerStyle}>
                <div style={stepStyle(step === 1)} onClick={() => setStep(1)}>
                  Info
                </div>
                <div style={stepStyle(step === 2)} onClick={() => setStep(2)}>
                  Dirección
                </div>
              </div>
              <form onSubmit={handleSubmit} className="client-form">
                <div className="form-row">
                  <label className="required" style={customStyles.label}>
                    Nombre del Proveedor*
                  </label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="empresa"
                    placeholder="Nombre del Proveedor"
                    value={formData.empresa}
                    onChange={handleChange}
                  />
                  <label style={customStyles.label}>RFC</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="rfc"
                    placeholder="RFC"
                    value={formData.rfc}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>Régimen Fiscal</label>
                  <select
                    style={customStyles.select}
                    id="regimen"
                    name="regimenFiscal"
                    value={formData.regimenFiscal}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Seleccione un régimen fiscal
                    </option>
                    <option value="601">
                      601 - General de Ley Personas Morales
                    </option>
                    <option value="612">
                      612 - Personas Físicas con Actividades Empresariales y
                      Profesionales
                    </option>
                    <option value="616">616 - Sin obligaciones fiscales</option>
                    <option value="626">
                      626 - Régimen Simplificado de Confianza (RESICO)
                    </option>
                  </select>

                  <label style={customStyles.label}>Moneda</label>
                  <select
                    style={customStyles.select}
                    id="moneda"
                    name="moneda"
                    value={formData.moneda}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Seleccione una moneda
                    </option>
                    <option value="MXN">MXN</option>
                    <option value="USD">USD</option>
                  </select>
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>Teléfono</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="telefono"
                    placeholder="Teléfono"
                    value={formData.telefono}
                    onChange={handleChange}
                  />

                  <label style={customStyles.label}>Email</label>
                  <input
                    style={customStyles.input}
                    type="email"
                    name="correo"
                    placeholder="Correo Electrónico"
                    value={formData.correo}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>Imagen</label>
                  <input
                    type="file"
                    name="imagen"
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      boxSizing: "border-box",
                    }}
                  />
                </div>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <h2 style={{ textAlign: "left", marginLeft: "100px" }}>
                {editingProveedorId
                  ? "Editar dirección del proveedor"
                  : "Dirección del proveedor"}
              </h2>
              <div style={stepContainerStyle}>
                <div style={stepStyle(step === 1)} onClick={() => setStep(1)}>
                  Info
                </div>
                <div style={stepStyle(step === 2)} onClick={() => setStep(2)}>
                  Dirección
                </div>
              </div>
              <form onSubmit={handleSubmit} className="client-form">
                <div className="form-row">
                  <label style={customStyles.label}>Domicilio</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="domicilio"
                    placeholder="Domicilio"
                    value={formData.domicilio}
                    onChange={handleChange}
                  />

                  <label style={customStyles.label}>No. Exterior</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="numeroExt"
                    placeholder="No. Ext"
                    value={formData.numeroExt}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>No. Interior</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="numeroInt"
                    placeholder="No. Int"
                    value={formData.numeroInt}
                    onChange={handleChange}
                  />

                  <label style={customStyles.label}>Colonia</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="colonia"
                    placeholder="Colonia"
                    value={formData.colonia}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>Código Postal</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="codigoPostal"
                    placeholder="C.P."
                    value={formData.codigoPostal}
                    onChange={handleChange}
                  />

                  <label style={customStyles.label}>Ciudad</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="ciudad"
                    placeholder="Ciudad"
                    value={formData.ciudad}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-row">
                  <label style={customStyles.label}>Estado</label>
                  <input
                    style={customStyles.input}
                    type="text"
                    name="estado"
                    placeholder="Estado"
                    value={formData.estado}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-toolbar">
                  <div className="leftBtn">
                    <button
                      id="guardar-enviar"
                      className="btn btn-primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {editingProveedorId ? "Actualizar" : "Guardar y enviar"}
                    </button>
                    <button
                      id="cancelar"
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => setModalIsOpen(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </Modal>
        {isLoading ? (
          <Spinner />
        ) : searchTerm !== "" && searchProveedores(searchTerm).length === 0 ? (
          <div className="no-resultados">
            <div
              className="mensaje-sin-resultados"
              style={{ marginTop: "30px" }}
            >
              <p style={{ fontSize: "14px" }}>
                No existe ningún proveedor que coincida con esta búsqueda.
              </p>
            </div>
            <div className="imagen-sin-resultados">
              <img
                src="/img/noSearch.svg"
                alt="Sin resultados"
                className="sin-resultados-imagen"
              />
            </div>
          </div>
        ) : (
          <ProveedorTable
            proveedores={
              searchTerm ? searchProveedores(searchTerm) : proveedores
            }
            onEditProveedor={handleEditProveedor}
            onDeleteProveedor={handleDeleteProveedor}
          />
        )}
      </div>
    </>
  );
};

export default ListaProveedores;