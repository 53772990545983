import React, { useState } from 'react';
import CotizacionForm from './CotizacionForm';
import Modal from 'react-modal';
import ResumenCotizacion from './ResumenCotizacion';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 102
  },
};

const EditarCotizacionForm = ({ cotizacion, clientes, productos, onClose }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [mostrarResumen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    // Llamar a la función onClose para cerrar el ResumenCotizacion
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="editar-cotizacion-form">
          <CotizacionForm
            cotizacion={cotizacion}
            clientes={clientes}
            productos={productos}
            closeModal={closeModal}
          />
        </div>
      </Modal>
      {mostrarResumen && (
        <ResumenCotizacion cotizacion={cotizacion} onClose={onClose} />
      )}
    </>
  );
};

export default EditarCotizacionForm;
