import React, { useState } from "react";
import moment from "moment";

const VistaDia = ({ events, onAddEvent }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  // Filtrar eventos del día seleccionado
  const dayEvents = events.filter((event) =>
    moment(event.start).isSame(selectedDate, "day")
  );

  const startOfMonth = moment(selectedDate).startOf("month");
  const endOfMonth = moment(selectedDate).endOf("month");
  const firstDayOfWeek = startOfMonth.startOf("week");
  const lastDayOfWeek = endOfMonth.endOf("week");

  const calendarDays = [];
  let currentDay = firstDayOfWeek.clone();

  while (currentDay.isBefore(lastDayOfWeek, "day")) {
    calendarDays.push(currentDay.clone());
    currentDay.add(1, "day");
  }

  const handleDayClick = (day) => {
    setSelectedDate(day);
  };

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.clone().add(1, "month"));
  };

  const handleAddEvent = () => {
    // Usamos la misma lógica que el botón externo
    const now = moment(); // Captura la hora y minuto exactos actuales
    const newEvent = {
      id: `event-${Date.now()}`,
      title: `Evento creado a las ${now.format("h:mm A")}`, // Incluye hora y minuto
      description: "Descripción del evento desde Vista Día",
      start: now.toISOString(), // Usa la fecha y hora exacta
      resource: "cotizacion",
    };

    onAddEvent(newEvent); // Agrega el evento utilizando la función del padre
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Agenda (horas y eventos) */}
      <div className="flex-[2] bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
        {/* Encabezado */}
        <div className="p-6 border-b flex items-center">
          <div className="flex flex-col ml-10">
            <h1 className="text-4xl font-bold text-gray-800">
              {selectedDate.format("dddd, DD MMMM YYYY")}
            </h1>
            <p className="text-gray-500 text-lg capitalize mt-1">
              {selectedDate.format("dddd")}
            </p>
          </div>
          <button
            onClick={handleAddEvent}
            className="ml-auto px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
          >
            Agregar evento
          </button>
        </div>

        {/* Vista de horas */}
        <div className="overflow-y-auto flex-1 p-4">
          {hours.map((hour) => (
            <div
              key={hour}
              className="border-b flex items-start py-4 hover:bg-gray-50"
            >
              <span className="w-24 text-sm text-gray-500 ml-4">{hour}</span>
              <div className="flex-1">
                {dayEvents
                  .filter(
                    (event) =>
                      moment(event.start).hour() ===
                      parseInt(hour.split(":")[0], 10)
                  )
                  .map((event) => (
                    <div
                      key={event.id}
                      className={`p-4 mb-2 rounded-lg shadow-md text-sm transition-all duration-200 flex flex-col ${
                        event.resource === "cotizacion"
                          ? "bg-blue-100 text-blue-800 border border-blue-300"
                          : "bg-green-100 text-green-800 border border-green-300"
                      }`}
                    >
                      <span className="text-xs font-bold mb-1">
                        {moment(event.start).format("h:mm A")}
                      </span>
                      <span className="font-semibold text-base">
                        {event.title}
                      </span>
                      {event.description && (
                        <p className="text-xs mt-1 text-gray-600">
                          {event.description}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Calendario compacto */}
      <div className="flex-[1.5] bg-white rounded-lg shadow-lg ml-6 border">
        {/* Encabezado del calendario */}
        <div className="p-6 border-b flex justify-between items-center bg-gray-100">
          <button
            onClick={handlePrevMonth}
            className="text-gray-600 hover:text-gray-800"
          >
            &lt;
          </button>
          <h2 className="text-xl font-bold text-gray-800">
            {selectedDate.format("MMMM YYYY")}
          </h2>
          <button
            onClick={handleNextMonth}
            className="text-gray-600 hover:text-gray-800"
          >
            &gt;
          </button>
        </div>

        {/* Días del calendario */}
        <div className="grid grid-cols-7 text-sm text-center p-4 gap-1 border-t">
          {["L", "M", "X", "J", "V", "S", "D"].map((day, index) => (
            <span key={index} className="text-gray-500 font-semibold uppercase">
              {day}
            </span>
          ))}
          {calendarDays.map((day, index) => (
            <div
              key={index}
              className={`p-3 border rounded-sm cursor-pointer transition-all duration-200 ${
                day.isSame(selectedDate, "day")
                  ? "bg-blue-500 text-white font-bold"
                  : day.isSame(startOfMonth, "month")
                  ? "hover:bg-gray-200 text-gray-700"
                  : "text-gray-400"
              }`}
              onClick={() => handleDayClick(day)}
            >
              {day.date()}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VistaDia;
