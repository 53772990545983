import React, { useState, useEffect } from "react";
import PreviaOrden from "./PreviaOrden";
import ResumenOrden from "./ResumenOrden";
import BandejaOrdenes from "./BandejaOrdenes";
import Nav from "./Nav";
import Paginador from "./Paginador";
import OrdenForm from "./OrdenForm";
import {
  collection,
  deleteDoc,
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import { useNavContext } from "../context/NavContext";
import { Link } from "react-router-dom";

moment.locale("es");

const styleForm = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    width: "100%",
    height: "550px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function TablaOrdenes({
  ordenes,
  proveedores,
  setOrdenes,
  guardarOrden,
  modoEdicion,
  orden,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [ordenSeleccionada, setOrdenSeleccionada] = useState(null);
  const [loadingOrdenes, setLoadingOrdenes] = useState(true);
  const [selectedOrdenes, setSelectedOrdenes] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState({
    campo: "fechaOrden",
    ascendente: true,
  });
  const [selectedOrdenId, setSelectedOrdenId] = useState(null);
  const [resumenVisible, setResumenVisible] = useState(false);
  const [showBandeja, setShowBandeja] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const { searchTerm, setSearchTerm, proximasAVencer, proximosEventos } =
    useNavContext();
  const location = useLocation();
  const [estadosCeldas, setEstadosCeldas] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Valor inicial
  const [formDirty, setFormDirty] = useState(false);

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFormDirtyChange = (dirty) => {
    setFormDirty(dirty);
  };

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setMostrarFormulario(true);
    }
  }, [location]);

  // Al cargar la página, obtenemos las ordenes y los estados desde Firestore
  useEffect(() => {
    const firestore = getFirestore();
    const ordenesRef = collection(firestore, "ordenes");

    const unsubscribe = onSnapshot(ordenesRef, (snapshot) => {
      const updatedOrdenes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrdenes(updatedOrdenes);
    });

    return () => unsubscribe();
  }, [setOrdenes]);

  // Función para alternar entre los estados de cada celda seleccionada y guardarlo en Firestore
  const toggleEstado = async (ordenId) => {
    const newEstado =
      estadosCeldas[ordenId] === "Pendiente"
        ? "En Proceso"
        : estadosCeldas[ordenId] === "En Proceso"
        ? "Pagada"
        : "Pendiente";

    // Actualizamos el estado localmente
    setEstadosCeldas((prevEstados) => ({
      ...prevEstados,
      [ordenId]: newEstado,
    }));

    // Guardamos el nuevo estado en Firestore
    const firestore = getFirestore();
    const ordenDocRef = doc(firestore, "ordenes", ordenId);
    await updateDoc(ordenDocRef, { estado: newEstado });
  };

  const openFormulario = () => {
    setMostrarFormulario(true);
    setShowNuevoButton(false); // Oculta el botón + Nuevo al abrir el formulario
  };

  const closeModal = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setMostrarFormulario(false);
          setShowNuevoButton(true); // Vuelve a mostrar el botón + Nuevo al cerrar el formulario
          setFormDirty(false); // Restablece formDirty
        }
      });
    } else {
      setMostrarFormulario(false);
      setShowNuevoButton(true);
    }
  };

  useEffect(() => {
    const firestore = getFirestore();
    const ordenesRef = collection(firestore, "ordenes");

    const unsubscribe = onSnapshot(ordenesRef, (snapshot) => {
      const updatedOrdenes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Actualizar el estado general de las órdenes
      setOrdenes(updatedOrdenes);

      // Actualizar estadosCeldas basado en los datos recuperados
      const initialEstados = {};
      updatedOrdenes.forEach((orden) => {
        initialEstados[orden.id] = orden.estado || "Pendiente";
      });
      setEstadosCeldas(initialEstados);

      setLoadingOrdenes(false);
    });

    return () => unsubscribe();
  }, [setOrdenes]);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las órdenes seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const ordenesEliminadas = [];
        const ordenesRef = collection(firestore, "ordenes");

        for (const ordenId of selectedOrdenes) {
          const ordenDocRef = doc(ordenesRef, ordenId);
          await deleteDoc(ordenDocRef);
          ordenesEliminadas.push(ordenId);
        }

        const ordenesRestantes = ordenes.filter(
          (orden) => !ordenesEliminadas.includes(orden.id)
        );
        setOrdenes(ordenesRestantes);
        setSelectedOrdenes([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "¡Orden eliminada!",
          text: "Las órdenes seleccionadas han sido eliminadas correctamente.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar órdenes:", error);
    }
  };

  const cerrarModalPrevia = () => {
    setModalIsOpen(false);
    setOrdenSeleccionada(null);
  };

  const handleRowClick = (ordenId) => {
    setSelectedOrdenId(ordenId);
    setResumenVisible(true);
    setShowBandeja(true);
  };

  const handleSelectOrden = (ordenId) => {
    setSelectedOrdenes((prevSelected) => {
      if (prevSelected.includes(ordenId)) {
        return prevSelected.filter((id) => id !== ordenId);
      } else {
        return [...prevSelected, ordenId];
      }
    });
  };

  const handleOrdenamientoChange = (campo) => {
    setOrdenamiento((prevOrdenamiento) => ({
      campo,
      ascendente:
        campo === prevOrdenamiento.campo ? !prevOrdenamiento.ascendente : true,
    }));
  };

  const ordenarOrdenes = (ordenes, { campo, ascendente }) => {
    if (!ordenes || ordenes.length === 0) {
      return [];
    }
    const sortedOrdenes = [...ordenes];
    switch (campo) {
      case "fechaOrden":
        sortedOrdenes.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaOrden) - new Date(b.fechaOrden))
        );
        break;
      case "numeroOrden":
        sortedOrdenes.sort(
          (a, b) => (ascendente ? 1 : -1) * (a.numeroOrden - b.numeroOrden)
        );
        break;
      case "nombreProveedor":
        sortedOrdenes.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            a.nombreProveedor.localeCompare(b.nombreProveedor)
        );
        break;
      case "total":
        sortedOrdenes.sort(
          (a, b) => (ascendente ? 1 : -1) * (a.total - b.total)
        );
        break;
      case "fechaVencimiento":
        sortedOrdenes.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento))
        );
        break;
      default:
        break;
    }
    return sortedOrdenes;
  };

  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date(); // Usa la fecha actual si dateString no está definida
    const day = date.getDate().toString().padStart(2, "0");

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  // Función para seleccionar/deseleccionar todas las ordenes
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las cotizaciones
      const allOrdenesIds = filteredOrdenes.map((orden) => orden.id);
      setSelectedOrdenes(allOrdenesIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedOrdenes([]);
    }
  };

  const filteredOrdenes = React.useMemo(() => {
    if (!ordenes || ordenes.length === 0) return [];
    const searchLower = searchTerm.toLowerCase();
    return ordenes.filter((orden) => {
      const searchableFields = [
        orden.numeroOrden?.toString().toLowerCase() || "",
        orden.referencia?.toLowerCase() || "",
        orden.nombreProveedor?.toLowerCase() || "",
        orden.total?.toString().toLowerCase() || "",
      ];
      return searchableFields.some((field) => field.includes(searchLower));
    });
  }, [ordenes, searchTerm]);

  const paginatedOrdenes = React.useMemo(() => {
    const ordenesOrdenadas = ordenarOrdenes(filteredOrdenes, ordenamiento);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return ordenesOrdenadas.slice(startIndex, endIndex);
  }, [filteredOrdenes, currentPage, itemsPerPage, ordenamiento]);

  const totalOrdenes = filteredOrdenes.length;

  return (
    <>
      {!showBandeja && !resumenVisible && !mostrarFormulario && (
        <>
          <Nav
            handleSearch={setSearchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <div className="task-filters">
            <div className="task-filters-search">
              <i className="bi bi-search search-icon"></i>
              <input
                type="text"
                className="search-input2"
                placeholder="Buscar órdenes de compra"
              />
            </div>
            <div className="task-filters-right">
              <div className="task-filter filter-control">
                <Link href="#" className="filter-link control-link">
                  <i className="bi bi-sliders2-vertical"></i> Filter
                </Link>
              </div>
              <div className="view-buttons-container">
                <button className="view-button grid-view">
                  <i className="bi bi-grid"></i>
                </button>
                <button className="view-button list-view">
                  <i className="bi bi-list"></i>
                </button>
              </div>
              <div>
                {showNuevoButton && (
                  <button className="new-task-button" onClick={openFormulario}>
                    + Nuevo
                  </button>
                )}
              </div>

              <div style={{ marginLeft: "-10px" }}>
                {selectedOrdenes.length > 0 && (
                  <div className="delete-button-container">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={handleDeleteSelected}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="cotizaciones-table">
        {showBandeja && (
          <BandejaOrdenes
            ordenes={ordenes}
            onRowClick={handleRowClick}
            proveedores={proveedores}
            guardarOrden={guardarOrden}
            orden={orden}
          />
        )}
        {!showBandeja && (
          <div className="tabla-cotizaciones-wrapper">
            <div className="tabla-cotizaciones-content">
              {loadingOrdenes ? (
                <Spinner />
              ) : (
                <>
                  {filteredOrdenes.length > 0 ? (
                    <table>
                      {!mostrarFormulario && (
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                className="checkbox-select-all check"
                                checked={
                                  selectedOrdenes.length ===
                                  filteredOrdenes.length
                                }
                              />
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("fechaOrden")
                              }
                            >
                              Fecha
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("numeroOrden")
                              }
                            >
                              No.
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("condicion")
                              }
                            >
                              Referencia
                            </th>
                            <th
                              onClick={() =>
                                handleOrdenamientoChange("nombreProveedor")
                              }
                            >
                              Proveedor
                            </th>
                            <th
                              onClick={() => handleOrdenamientoChange("estado")}
                            >
                              Estado
                            </th>
                            <th
                              onClick={() => handleOrdenamientoChange("total")}
                            >
                              Importe
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {paginatedOrdenes.map((orden, index) => (
                          <tr key={index}>
                            <td className="check">
                              <input
                                type="checkbox"
                                checked={selectedOrdenes.includes(orden.id)}
                                onChange={() => handleSelectOrden(orden.id)}
                                className="checkbox"
                              />
                            </td>
                            <td onClick={() => handleRowClick(orden.id)}>
                              {formatDate(orden.fechaOrden)}
                            </td>
                            <td
                              style={{ color: "#007bff", fontWeight: "500" }}
                              onClick={() => handleRowClick(orden.id)}
                            >
                              {orden.numeroOrden?.toString().padStart(4, "0")}
                            </td>
                            <td onClick={() => handleRowClick(orden.id)}>
                              {orden.referencia}
                            </td>
                            <td onClick={() => handleRowClick(orden.id)}>
                              {orden.nombreProveedor}
                            </td>
                            <td>
                              <div
                                className={`status2 ${
                                  estadosCeldas[orden.id] === "Pendiente"
                                    ? "pendiente2"
                                    : estadosCeldas[orden.id] === "En Proceso"
                                    ? "en-proceso2"
                                    : estadosCeldas[orden.id] === "Pagada"
                                    ? "pagada2"
                                    : "pendiente2"
                                }`}
                                onClick={() => toggleEstado(orden.id)}
                              >
                                {estadosCeldas[orden.id] || "Pendiente"}
                              </div>
                            </td>
                            <td
                              className="importe"
                              onClick={() => handleRowClick(orden.id)}
                            >
                              {orden.divisa}{" "}
                              {parseFloat(orden.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-resultados">
                      <div
                        className="mensaje-sin-resultados"
                        style={{ marginTop: "30px" }}
                      >
                        <p style={{ fontSize: "14px" }}>
                          No existe ninguna orden de compra que coincida con
                          esta búsqueda.
                        </p>
                      </div>
                      <div className="imagen-sin-resultados">
                        <img
                          src="/img/noSearch.svg"
                          alt="Sin resultados"
                          className="sin-resultados-imagen"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <Modal
                isOpen={mostrarFormulario}
                onRequestClose={closeModal}
                contentLabel="Nueva Cotización"
                style={styleForm}
              >
                <button onClick={closeModal} className="cerrar-button">
                  <i className="bi bi-x-lg"></i>
                </button>
                <OrdenForm
                  proveedores={proveedores}
                  guardarOrden={guardarOrden}
                  modoEdicion={modoEdicion}
                  orden={orden}
                  onFormDirtyChange={handleFormDirtyChange}
                  closeModal={closeModal}
                />
              </Modal>
            </div>
            <div className="tabla-cotizaciones-footer">
              <div className="paginador-container">
                <Paginador
                  totalItems={totalOrdenes}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {ordenSeleccionada && (
            <PreviaOrden
              orden={ordenSeleccionada}
              numeroOrden={ordenSeleccionada.numeroOrden}
              proveedores={proveedores}
              cerrarPrevia={cerrarModalPrevia}
            />
          )}
        </Modal>

        <div className={`resumen-container ${selectedOrdenId ? "active" : ""}`}>
          <ResumenOrden
            orden={
              ordenes && ordenes.find((orden) => orden.id === selectedOrdenId)
            }
            isOpen={resumenVisible}
            onClose={() => {
              setResumenVisible(false);
              setSelectedOrdenId(null);
              setShowBandeja(false);
            }}
            numeroOrden={
              ordenSeleccionada ? ordenSeleccionada.numeroOrden : null
            }
            proveedores={proveedores}
            mostrarBotonNuevo={false}
          />
        </div>
      </div>
    </>
  );
}

export default TablaOrdenes;