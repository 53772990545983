import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import RolVentas from "./RolVentas";
import RolAdmin from "./RolAdmin";
import "bootstrap-icons/font/bootstrap-icons.css";

const Sidebar = ({ onToggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { userRole } = useAuth();
  const [preloaded, setPreloaded] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prev) => {
      const newState = !prev;
      if (onToggleSidebar) {
        onToggleSidebar(newState); // Notifica al Nav el estado del Sidebar
      }
      return newState;
    });
  };

  const closeSidebar = () => {
    setIsOpen(false);
    if (onToggleSidebar) {
      onToggleSidebar(false); // Asegura sincronización al cerrar
    }
  };

  useEffect(() => {
    const preLoadImage = new Image();
    preLoadImage.src = "/img/logo-iciamex.png";
    preLoadImage.onload = () => setPreloaded(true);
  }, []);

  document.querySelectorAll(".sidebar-item").forEach((item) => {
    item.addEventListener("mouseenter", (event) => {
      // Busca un tooltip existente y reutilízalo
      let tooltip = document.querySelector(".tooltip");
      if (!tooltip) {
        tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        document.body.appendChild(tooltip);
      }

      // Actualiza el contenido y la posición del tooltip
      tooltip.textContent = item.getAttribute("data-tooltip");

      const rect = item.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`; // Ajusta la distancia a la derecha del elemento
      tooltip.style.top = `${rect.top + window.scrollY}px`; // Ajusta la posición vertical
      tooltip.style.opacity = 1;
      tooltip.style.visibility = "visible";
    });

    item.addEventListener("mouseleave", () => {
      // Oculta el tooltip al salir del elemento
      const tooltip = document.querySelector(".tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
        tooltip.style.visibility = "hidden";
      }
    });

    item.addEventListener("click", () => {
      // Oculta el tooltip al hacer clic en un elemento del menú
      const tooltip = document.querySelector(".tooltip");
      if (tooltip) {
        tooltip.style.opacity = 0;
        tooltip.style.visibility = "hidden";
      }
    });
  });

  return (
    <>
      {userRole === "Ventas" && (
        <RolVentas
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          closeSidebar={closeSidebar}
          preloaded={preloaded}
        />
      )}
      {userRole === "Administrador" && (
        <RolAdmin
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          closeSidebar={closeSidebar}
          preloaded={preloaded}
        />
      )}
    </>
  );
};

export default Sidebar;