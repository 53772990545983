// src/context/NavContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import moment from "moment";

// Crear el contexto
const NavContext = createContext();

// Proveedor del contexto
export const NavProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);

  useEffect(() => {
    const firestore = getFirestore();

    // Función para obtener cotizaciones
    const cotizacionesRef = collection(firestore, "cotizaciones");
    const unsubscribeCotizaciones = onSnapshot(cotizacionesRef, (snapshot) => {
      const cotizaciones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filtrar y ordenar cotizaciones
      const proximas = cotizaciones
        .filter(
          (cotizacion) =>
            moment(cotizacion.fechaVencimiento).isSameOrAfter(moment().startOf("day"))
        )
        .sort(
          (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
        );

      setProximasAVencer(proximas.slice(0, 6)); // Limitar a 6
    });

    // Función para obtener eventos
    const eventosRef = collection(firestore, "eventos");
    const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
      const eventos = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filtrar y ordenar eventos
      const proximos = eventos
        .filter((evento) => moment(evento.to).isSameOrAfter(moment().startOf("day")))
        .sort((a, b) => moment(a.to) - moment(b.to));

      setProximosEventos(proximos.slice(0, 6)); // Limitar a 6

      // Filtrar fechas festivas próximas (opcional)
      const fechasFestivasProximas = [];
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        { title: "Día de la Constitución", month: "02", day: "05", color: "#de2e03" },
        { title: "Natalicio de Benito Juárez", month: "03", day: "21", color: "#de2e03" },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        { title: "Independencia de México", month: "09", day: "16", color: "#de2e03" },
        { title: "Transición del Poder Ejecutivo", month: "10", day: "01", color: "#de2e03" },
        { title: "Revolución Mexicana", month: "11", day: "20", color: "#de2e03" },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const today = moment().startOf("day");
      const oneWeekFromNow = moment().add(7, "days").startOf("day");

      fechasFestivasBase.forEach((festivo) => {
        const festivoDate = moment(
          `${today.year()}-${festivo.month}-${festivo.day}`,
          "YYYY-MM-DD"
        );
        if (festivoDate.isBetween(today, oneWeekFromNow, null, "[]")) {
          fechasFestivasProximas.push({
            title: festivo.title,
            start: festivoDate.toDate(),
            end: festivoDate.toDate(),
            allDay: true,
            resource: "festivo",
            style: { backgroundColor: festivo.color },
          });
        }
      });

      if (fechasFestivasProximas.length > 0) {
        console.log(
          `Fechas festivas próximas: ${fechasFestivasProximas
            .map(
              (festivo) =>
                `${festivo.title} el ${moment(festivo.start).format("LL")}`
            )
            .join(", ")}`
        );
      }
    });

    // Cleanup
    return () => {
      unsubscribeCotizaciones();
      unsubscribeEventos();
    };
  }, []);

  return (
    <NavContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        proximasAVencer,
        proximosEventos,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useNavContext = () => {
  return useContext(NavContext);
};
