import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  // Cargar las búsquedas anteriores desde localStorage al montar el componente
  useEffect(() => {
    const previousSearches =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSuggestions(previousSearches);
  }, []);

  const pageTitles = {
    "/inicio": "Inicio / Dashboard",
    "/tareas": "Tareas / Tareas",
    "/calendario": "Calendario / Calendario",
    "/cotizacion": "Clientes / Cotizaciones",
    "/remisiones": "Clientes / Remisiones",
    "/pedidos": "Clientes / Pedidos",
    "/orden": "Proveedores / Órdenes de Compra",
    "/articulos-venta": "Inventario / Artículos de venta",
    "/articulos-compra": "Inventario / Artículos de compra",
    "/categorias": "Inventario / Categorías",
    "/todos": "Contactos / Todos",
    "/clientes": "Contactos / Clientes",
    "/proveedores": "Contactos / Proveedores",
    "/usuarios": "Configuración / Usuarios",
    "/plantillas": "Configuración / Plantillas",
  };

  const normalizeSearchTerm = (term) => {
    return term
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-");
  };

  useEffect(() => {
    const previousSearches =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSuggestions(previousSearches);
  }, []);

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    const filteredSuggestions = Object.keys(pageTitles).filter((key) =>
      pageTitles[key].toLowerCase().includes(query.toLowerCase())
    );

    const formattedSuggestions = filteredSuggestions.map(
      (key) => pageTitles[key]
    );
    setSuggestions(formattedSuggestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Guardar la búsqueda actual en localStorage si no existe ya
    if (searchTerm && !suggestions.includes(searchTerm)) {
      const updatedSuggestions = [...new Set([searchTerm, ...suggestions])];
      localStorage.setItem("searchHistory", JSON.stringify(updatedSuggestions));
      setSuggestions(updatedSuggestions);
    }

    // Redirigir al usuario según el término de búsqueda
    if (searchTerm.toLowerCase() === "inicio") {
      navigate("/"); // Redirige correctamente a la página raíz
    } else {
      const normalizedSearchTerm = normalizeSearchTerm(searchTerm);
      const matchedPath = Object.keys(pageTitles).find(
        (key) => normalizeSearchTerm(pageTitles[key]) === normalizedSearchTerm
      );

      if (matchedPath) {
        navigate(matchedPath);
      } else {
        alert("Página no encontrada.");
      }
    }
  };

  const handleFocus = () => {
    setSuggestions(Object.values(pageTitles));
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);

    if (suggestion === "Inicio") {
      navigate("/"); // Redirige correctamente a la página raíz
    } else {
      const normalizedSuggestion = normalizeSearchTerm(suggestion);
      const matchedPath = Object.keys(pageTitles).find(
        (key) => normalizeSearchTerm(pageTitles[key]) === normalizedSuggestion
      );

      if (matchedPath) {
        navigate(matchedPath);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="search-form"
      style={{ position: "relative" }}
    >
      <i className="bi bi-search"></i>
      <input
        className="search-input"
        type="text"
        value={searchTerm}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder="Buscar..."
      />
      {searchTerm && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <i className="bi bi-clock-history"></i> {suggestion}
            </li>
          ))}
        </ul>
      )}
    </form>
  );
};

export default SearchBar;