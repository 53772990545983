import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import RemisionForm from "./RemisionForm";
import Swal from "sweetalert2";
import Paginador from "./Paginador";

Modal.setAppElement("#root");

const BandejaRemisiones = ({
  remisiones,
  onRowClick,
  selectedRemisionId,
  guardarRemision,
  modoEdicion,
  remision,
  clientes,
}) => {
  const [filteredRemisiones, setFilteredRemisiones] = useState(remisiones);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showBulkActionsOptions, setShowBulkActionsOptions] = useState(false);
  const [selectedRemisiones, setSelectedRemisiones] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredRemisiones.length / itemsPerPage);

  const paginatedRemisiones = filteredRemisiones.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = remisiones.filter((remision) => {
      const formattedImporte = parseFloat(remision?.total)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        remision.nombreCliente
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        remision.fechaRemision.includes(searchTerm) ||
        remision.numeroRemision
          .toString()
          .padStart(4, "0")
          .includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredRemisiones(filtered);
    setCurrentPage(1);
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setIsFormDirty(false);
    setShowModal(true);
  };

  const closeModal = () => {
    if (isFormDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setShowModal(false);
          setIsFormDirty(false); // Restablecer el estado formDirty
        }
      });
    } else {
      setShowModal(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleBulkActionsOptions = () => {
    setShowBulkActionsOptions(!showBulkActionsOptions);
  };

  const handleSelectRemision = (remisionId) => {
    setSelectedRemisiones((prevSelected) =>
      prevSelected.includes(remisionId)
        ? prevSelected.filter((id) => id !== remisionId)
        : [...prevSelected, remisionId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRemisiones([]); // Deselecciona todo
    } else {
      setSelectedRemisiones(remisiones.map((rem) => rem.id)); // Selecciona todo
    }
    setSelectAll(!selectAll);
  };

  const showBulkActions = selectedRemisiones.length > 0;

  const exportarPDF = () => {
    console.log("Exportar como PDF");
  };

  const imprimir = () => {
    console.log("Imprimir remisión");
  };

  const eliminar = () => {
    console.log("Eliminar remisión");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".bulk-actions-right")) {
        setShowBulkActionsOptions(false);
      }
    };
    if (showBulkActionsOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showBulkActionsOptions]);

  return (
    <div className="cotizaciones-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input
            type="text"
            className="bandeja-search-input"
            placeholder="Buscar..."
            onChange={handleSearch}
          />
        </div>
      </div>

      {showBulkActions && (
        <div className="bulk-actions">
          <div className="bulk-actions-left">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ marginTop: "0", alignSelf: "center" }} // Ajuste para centrar el checkbox
            />
            <p>{selectedRemisiones.length} seleccionados</p>
          </div>

          <div className="bulk-actions-right-container">
            <button className="boton-accion">Marcar como enviado</button>
            <div
              className="bulk-actions-right"
              style={{ position: "relative" }}
            >
              <i
                className="bi bi-three-dots-vertical"
                onClick={toggleBulkActionsOptions}
                style={{ cursor: "pointer" }}
              ></i>
              {showBulkActionsOptions && (
                <div className="dropdown-options-bandeja">
                  <p onClick={exportarPDF}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-box-arrow-up-right"
                    ></i>
                    Exportar como PDF
                  </p>
                  <p onClick={imprimir}>
                    <i
                      style={{ fontSize: "14px" }}
                      className="bi bi-printer"
                    ></i>
                    Imprimir
                  </p>
                  <p onClick={eliminar}>
                    <i style={{ fontSize: "14px" }} className="bi bi-trash"></i>
                    Eliminar
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginLeft: "10px" }}>Todas las remisiones</p>
            <i
              className="bi bi-chevron-down"
              style={{ color: "#007bff", fontWeight: "bold" }}
            ></i>
          </div>
          {showDropdown && (
            <div className="dropdown-menu">
              <p>
                <i className="bi bi-envelope-check"></i>Remisiones enviadas
              </p>
              <p>
                <i className="bi bi-clock"></i>Remisiones pendientes
              </p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: "relative" }}>
          <button className="boton-accion" onClick={openFormulario}>
            + Nuevo
          </button>
          <div style={{ position: "relative", display: "inline-block" }}>
            <i
              className="bi bi-three-dots-vertical"
              onClick={toggleOptions}
              style={{ cursor: "pointer" }}
            ></i>
            {showOptions && (
              <div className="dropdown-options-bandeja">
                <p onClick={() => console.log("Ordenar")}>
                  <i className="bi bi-arrow-down-up"></i>Ordenar
                </p>
                <p onClick={() => console.log("Importar")}>
                  <i className="bi bi-box-arrow-in-down"></i>Importar
                </p>
                <p onClick={() => console.log("Exportar")}>
                  <i className="bi bi-box-arrow-up"></i>Exportar
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {paginatedRemisiones.length > 0 ? (
        paginatedRemisiones.map((remision) => (
          <div
            key={remision.id}
            className={`mensaje-cotizacion ${
              selectedRemisionId === remision.id ? "mensaje-seleccionado" : ""
            }`}
            onClick={() => onRowClick(remision.id)}
            tabIndex="0"
          >
            <div className="info-container">
              <input
                type="checkbox"
                onChange={() => handleSelectRemision(remision.id)}
                checked={selectedRemisiones.includes(remision.id)}
              />
              <div className="info-column">
                <div className="cliente-importe">
                  <span className="cliente-nombre">
                    {remision.nombreCliente}
                  </span>
                  <span className="importe">
                    {remision.divisa}{" "}
                    {parseFloat(remision?.total)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <div className="fecha-referencia">
                  <p>
                    {" "}
                    {remision.numeroCotizacion.toString().padStart(4, "0")}
                  </p>
                  <span className="punto">•</span>
                  <p>{formatDate(new Date(remision.fechaRemision))}</p>
                </div>
                <div
                  className={`status2 ${
                    remision.estado === "Pendiente"
                      ? "pendiente2"
                      : remision.estado === "En Proceso"
                      ? "en-proceso2"
                      : "pagada2"
                  }`}
                >
                  {remision.estado}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={{ position: "relative", right: "32%", marginTop: "80px" }}>
          <p style={{ fontSize: "14px" }}>
            No existe ninguna cotización que coincida con esta búsqueda.
          </p>

          <div className="sin-resultados-animation">
            <img
              src="/img/noSearch.svg"
              alt="Sin resultados"
              className="sin-resultados-imagen"
            />
          </div>
        </div>
      )}

      <div className="paginador">
        <div style={{ paddingLeft: "10px", width: "34%" }}>
          <Paginador
            totalItems={filteredRemisiones.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Cotización"
        style={{
          overlay: {
            zIndex: 2,
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingLeft: 0,
            padding: 0,
            fontFamily: "Roboto, sans-serif",
            "::WebkitScrollbar": {
              width: "0px",
              background: "transparent",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>

        <RemisionForm
          clientes={clientes}
          guardarRemision={guardarRemision}
          remision={remision}
          onFormDirtyChange={(dirty) => setIsFormDirty(dirty)}
        />
      </Modal>
    </div>
  );
};

export default BandejaRemisiones;